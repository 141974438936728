@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:400,600,700&display=swap");
:root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #ffffff;
    --gray: #4e5d78;
    --gray-dark: #343a40;
    --primary: #0684fe;
    --secondary: #f5f6f8;
    --success: #35ac80;
    --info: #5d3fff;
    --warning: #f7b731;
    --danger: #fe3f3e;
    --light: #f8f9fa;
    --dark: #131b24;
    --black: #000000;
    --white: #ffffff;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

body {
    margin: 0;
    font-family: "Nunito Sans";
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
    color: #000000;
    text-align: left;
    background-color: #ffffff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
}

dt {
    font-weight: 700;
}

dd {
    margin-bottom: .5rem;
    margin-left: 0;
}

blockquote {
    margin: 0 0 1rem;
}

b,
strong {
    font-weight: bolder;
}

small {
    font-size: 80%;
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -.25em;
}

sup {
    top: -.5em;
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

a:hover {
    color: #0056b3;
    text-decoration: underline;
}

a:not([href]) {
    color: inherit;
    text-decoration: none;
}

a:not([href]):hover {
    color: inherit;
    text-decoration: none;
}

pre,
code,
kbd,
samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 1em;
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
}

figure {
    margin: 0 0 1rem;
}

img {
    vertical-align: middle;
    border-style: none;
}

svg {
    overflow: hidden;
    vertical-align: middle;
}

table {
    border-collapse: collapse;
}

caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #6c757d;
    text-align: left;
    caption-side: bottom;
}

th {
    text-align: inherit;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

button {
    border-radius: 0;
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

select {
    word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
    cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
    -webkit-appearance: listbox;
}

textarea {
    overflow: auto;
    resize: vertical;
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}

progress {
    vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

[type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}

output {
    display: inline-block;
}

summary {
    display: list-item;
    cursor: pointer;
}

template {
    display: none;
}

[hidden] {
    display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: 0.5rem;
    font-weight: 600;
    line-height: 1.5;
}

h1,
.h1 {
    font-size: 2.5rem;
}

h2,
.h2 {
    font-size: 2rem;
}

h3,
.h3 {
    font-size: 1.75rem;
}

h4,
.h4 {
    font-size: 1.5rem;
}

h5,
.h5 {
    font-size: 1.25rem;
}

h6,
.h6 {
    font-size: 1rem;
}

.lead {
    font-size: 1.25rem;
    font-weight: 300;
}

.display-1 {
    font-size: 5.625rem;
    font-weight: 700;
    line-height: 1.2;
}

.display-2 {
    font-size: 5rem;
    font-weight: 700;
    line-height: 1.2;
}

.display-3 {
    font-size: 4rem;
    font-weight: 700;
    line-height: 1.2;
}

.display-4 {
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.2;
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
    font-size: 80%;
    font-weight: 400;
}

mark,
.mark {
    padding: 0.2em;
    background-color: #fcf8e3;
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.list-inline {
    padding-left: 0;
    list-style: none;
}

.list-inline-item {
    display: inline-block;
}

.list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
}

.initialism {
    font-size: 90%;
    text-transform: uppercase;
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem;
}

.blockquote-footer {
    display: block;
    font-size: 80%;
    color: #6c757d;
}

.blockquote-footer::before {
    content: "\2014\00A0";
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto;
}

.figure {
    display: inline-block;
}

.figure-img {
    margin-bottom: 0.5rem;
    line-height: 1;
}

.figure-caption {
    font-size: 90%;
    color: #6c757d;
}

code {
    font-size: 87.5%;
    color: #e83e8c;
    word-wrap: break-word;
}

a>code {
    color: inherit;
}

kbd {
    padding: 0.2rem 0.4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #212529;
    border-radius: 0.125rem;
}

kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
}

pre {
    display: block;
    font-size: 87.5%;
    color: #212529;
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
}

.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container,
    .container-sm {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container,
    .container-sm,
    .container-md {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container,
    .container-sm,
    .container-md,
    .container-lg {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
        max-width: 1140px;
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.no-gutters>.col,
.no-gutters>[class*="col-"] {
    padding-right: 0;
    padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}

.row-cols-1>* {
    flex: 0 0 100%;
    max-width: 100%;
}

.row-cols-2>* {
    flex: 0 0 50%;
    max-width: 50%;
}

.row-cols-3>* {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}

.row-cols-4>* {
    flex: 0 0 25%;
    max-width: 25%;
}

.row-cols-5>* {
    flex: 0 0 20%;
    max-width: 20%;
}

.row-cols-6>* {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}

.col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
}

.col-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
}

.col-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}

.col-3 {
    flex: 0 0 25%;
    max-width: 25%;
}

.col-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}

.col-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
}

.col-6 {
    flex: 0 0 50%;
    max-width: 50%;
}

.col-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
}

.col-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
}

.col-9 {
    flex: 0 0 75%;
    max-width: 75%;
}

.col-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
}

.col-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
}

.col-12 {
    flex: 0 0 100%;
    max-width: 100%;
}

.order-first {
    order: -1;
}

.order-last {
    order: 13;
}

.order-0 {
    order: 0;
}

.order-1 {
    order: 1;
}

.order-2 {
    order: 2;
}

.order-3 {
    order: 3;
}

.order-4 {
    order: 4;
}

.order-5 {
    order: 5;
}

.order-6 {
    order: 6;
}

.order-7 {
    order: 7;
}

.order-8 {
    order: 8;
}

.order-9 {
    order: 9;
}

.order-10 {
    order: 10;
}

.order-11 {
    order: 11;
}

.order-12 {
    order: 12;
}

.offset-1 {
    margin-left: 8.33333%;
}

.offset-2 {
    margin-left: 16.66667%;
}

.offset-3 {
    margin-left: 25%;
}

.offset-4 {
    margin-left: 33.33333%;
}

.offset-5 {
    margin-left: 41.66667%;
}

.offset-6 {
    margin-left: 50%;
}

.offset-7 {
    margin-left: 58.33333%;
}

.offset-8 {
    margin-left: 66.66667%;
}

.offset-9 {
    margin-left: 75%;
}

.offset-10 {
    margin-left: 83.33333%;
}

.offset-11 {
    margin-left: 91.66667%;
}

@media (min-width: 576px) {
    .col-sm {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
    .row-cols-sm-1>* {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .row-cols-sm-2>* {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .row-cols-sm-3>* {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .row-cols-sm-4>* {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .row-cols-sm-5>* {
        flex: 0 0 20%;
        max-width: 20%;
    }
    .row-cols-sm-6>* {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .col-sm-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
    .col-sm-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    .col-sm-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .col-sm-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-sm-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .col-sm-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-sm-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    .col-sm-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    .col-sm-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-sm-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    .col-sm-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .order-sm-first {
        order: -1;
    }
    .order-sm-last {
        order: 13;
    }
    .order-sm-0 {
        order: 0;
    }
    .order-sm-1 {
        order: 1;
    }
    .order-sm-2 {
        order: 2;
    }
    .order-sm-3 {
        order: 3;
    }
    .order-sm-4 {
        order: 4;
    }
    .order-sm-5 {
        order: 5;
    }
    .order-sm-6 {
        order: 6;
    }
    .order-sm-7 {
        order: 7;
    }
    .order-sm-8 {
        order: 8;
    }
    .order-sm-9 {
        order: 9;
    }
    .order-sm-10 {
        order: 10;
    }
    .order-sm-11 {
        order: 11;
    }
    .order-sm-12 {
        order: 12;
    }
    .offset-sm-0 {
        margin-left: 0;
    }
    .offset-sm-1 {
        margin-left: 8.33333%;
    }
    .offset-sm-2 {
        margin-left: 16.66667%;
    }
    .offset-sm-3 {
        margin-left: 25%;
    }
    .offset-sm-4 {
        margin-left: 33.33333%;
    }
    .offset-sm-5 {
        margin-left: 41.66667%;
    }
    .offset-sm-6 {
        margin-left: 50%;
    }
    .offset-sm-7 {
        margin-left: 58.33333%;
    }
    .offset-sm-8 {
        margin-left: 66.66667%;
    }
    .offset-sm-9 {
        margin-left: 75%;
    }
    .offset-sm-10 {
        margin-left: 83.33333%;
    }
    .offset-sm-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 768px) {
    .col-md {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
    .row-cols-md-1>* {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .row-cols-md-2>* {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .row-cols-md-3>* {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .row-cols-md-4>* {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .row-cols-md-5>* {
        flex: 0 0 20%;
        max-width: 20%;
    }
    .row-cols-md-6>* {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .col-md-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
    .col-md-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    .col-md-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-md-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .col-md-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-md-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    .col-md-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    .col-md-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-md-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    .col-md-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .order-md-first {
        order: -1;
    }
    .order-md-last {
        order: 13;
    }
    .order-md-0 {
        order: 0;
    }
    .order-md-1 {
        order: 1;
    }
    .order-md-2 {
        order: 2;
    }
    .order-md-3 {
        order: 3;
    }
    .order-md-4 {
        order: 4;
    }
    .order-md-5 {
        order: 5;
    }
    .order-md-6 {
        order: 6;
    }
    .order-md-7 {
        order: 7;
    }
    .order-md-8 {
        order: 8;
    }
    .order-md-9 {
        order: 9;
    }
    .order-md-10 {
        order: 10;
    }
    .order-md-11 {
        order: 11;
    }
    .order-md-12 {
        order: 12;
    }
    .offset-md-0 {
        margin-left: 0;
    }
    .offset-md-1 {
        margin-left: 8.33333%;
    }
    .offset-md-2 {
        margin-left: 16.66667%;
    }
    .offset-md-3 {
        margin-left: 25%;
    }
    .offset-md-4 {
        margin-left: 33.33333%;
    }
    .offset-md-5 {
        margin-left: 41.66667%;
    }
    .offset-md-6 {
        margin-left: 50%;
    }
    .offset-md-7 {
        margin-left: 58.33333%;
    }
    .offset-md-8 {
        margin-left: 66.66667%;
    }
    .offset-md-9 {
        margin-left: 75%;
    }
    .offset-md-10 {
        margin-left: 83.33333%;
    }
    .offset-md-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 992px) {
    .col-lg {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
    .row-cols-lg-1>* {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .row-cols-lg-2>* {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .row-cols-lg-3>* {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .row-cols-lg-4>* {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .row-cols-lg-5>* {
        flex: 0 0 20%;
        max-width: 20%;
    }
    .row-cols-lg-6>* {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
    .col-lg-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    .col-lg-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-lg-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .col-lg-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-lg-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    .col-lg-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-lg-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    .col-lg-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .order-lg-first {
        order: -1;
    }
    .order-lg-last {
        order: 13;
    }
    .order-lg-0 {
        order: 0;
    }
    .order-lg-1 {
        order: 1;
    }
    .order-lg-2 {
        order: 2;
    }
    .order-lg-3 {
        order: 3;
    }
    .order-lg-4 {
        order: 4;
    }
    .order-lg-5 {
        order: 5;
    }
    .order-lg-6 {
        order: 6;
    }
    .order-lg-7 {
        order: 7;
    }
    .order-lg-8 {
        order: 8;
    }
    .order-lg-9 {
        order: 9;
    }
    .order-lg-10 {
        order: 10;
    }
    .order-lg-11 {
        order: 11;
    }
    .order-lg-12 {
        order: 12;
    }
    .offset-lg-0 {
        margin-left: 0;
    }
    .offset-lg-1 {
        margin-left: 8.33333%;
    }
    .offset-lg-2 {
        margin-left: 16.66667%;
    }
    .offset-lg-3 {
        margin-left: 25%;
    }
    .offset-lg-4 {
        margin-left: 33.33333%;
    }
    .offset-lg-5 {
        margin-left: 41.66667%;
    }
    .offset-lg-6 {
        margin-left: 50%;
    }
    .offset-lg-7 {
        margin-left: 58.33333%;
    }
    .offset-lg-8 {
        margin-left: 66.66667%;
    }
    .offset-lg-9 {
        margin-left: 75%;
    }
    .offset-lg-10 {
        margin-left: 83.33333%;
    }
    .offset-lg-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 1200px) {
    .col-xl {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
    .row-cols-xl-1>* {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .row-cols-xl-2>* {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .row-cols-xl-3>* {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .row-cols-xl-4>* {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .row-cols-xl-5>* {
        flex: 0 0 20%;
        max-width: 20%;
    }
    .row-cols-xl-6>* {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .col-xl-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
    .col-xl-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    .col-xl-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .col-xl-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-xl-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .col-xl-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    .col-xl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-xl-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    .col-xl-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    .col-xl-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-xl-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    .col-xl-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    .col-xl-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .order-xl-first {
        order: -1;
    }
    .order-xl-last {
        order: 13;
    }
    .order-xl-0 {
        order: 0;
    }
    .order-xl-1 {
        order: 1;
    }
    .order-xl-2 {
        order: 2;
    }
    .order-xl-3 {
        order: 3;
    }
    .order-xl-4 {
        order: 4;
    }
    .order-xl-5 {
        order: 5;
    }
    .order-xl-6 {
        order: 6;
    }
    .order-xl-7 {
        order: 7;
    }
    .order-xl-8 {
        order: 8;
    }
    .order-xl-9 {
        order: 9;
    }
    .order-xl-10 {
        order: 10;
    }
    .order-xl-11 {
        order: 11;
    }
    .order-xl-12 {
        order: 12;
    }
    .offset-xl-0 {
        margin-left: 0;
    }
    .offset-xl-1 {
        margin-left: 8.33333%;
    }
    .offset-xl-2 {
        margin-left: 16.66667%;
    }
    .offset-xl-3 {
        margin-left: 25%;
    }
    .offset-xl-4 {
        margin-left: 33.33333%;
    }
    .offset-xl-5 {
        margin-left: 41.66667%;
    }
    .offset-xl-6 {
        margin-left: 50%;
    }
    .offset-xl-7 {
        margin-left: 58.33333%;
    }
    .offset-xl-8 {
        margin-left: 66.66667%;
    }
    .offset-xl-9 {
        margin-left: 75%;
    }
    .offset-xl-10 {
        margin-left: 83.33333%;
    }
    .offset-xl-11 {
        margin-left: 91.66667%;
    }
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
}

.table th,
.table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
}

.table tbody+tbody {
    border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
    padding: 0.3rem;
}

.table-bordered {
    border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
    border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody+tbody {
    border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
    color: #212529;
    background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary>th,
.table-primary>td {
    background-color: #b9ddff;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody+tbody {
    border-color: #7ebffe;
}

.table-hover .table-primary:hover {
    background-color: #a0d1ff;
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
    background-color: #a0d1ff;
}

.table-secondary,
.table-secondary>th,
.table-secondary>td {
    background-color: #fcfcfd;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody+tbody {
    border-color: #fafafb;
}

.table-hover .table-secondary:hover {
    background-color: #ededf3;
}

.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
    background-color: #ededf3;
}

.table-success,
.table-success>th,
.table-success>td {
    background-color: #c6e8db;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody+tbody {
    border-color: #96d4bd;
}

.table-hover .table-success:hover {
    background-color: #b4e1d0;
}

.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
    background-color: #b4e1d0;
}

.table-info,
.table-info>th,
.table-info>td {
    background-color: #d2c9ff;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody+tbody {
    border-color: #ab9bff;
}

.table-hover .table-info:hover {
    background-color: #bdb0ff;
}

.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
    background-color: #bdb0ff;
}

.table-warning,
.table-warning>th,
.table-warning>td {
    background-color: #fdebc5;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody+tbody {
    border-color: #fbda94;
}

.table-hover .table-warning:hover {
    background-color: #fce3ac;
}

.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
    background-color: #fce3ac;
}

.table-danger,
.table-danger>th,
.table-danger>td {
    background-color: #ffc9c9;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody+tbody {
    border-color: #fe9b9b;
}

.table-hover .table-danger:hover {
    background-color: #ffb0b0;
}

.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
    background-color: #ffb0b0;
}

.table-light,
.table-light>th,
.table-light>td {
    background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody+tbody {
    border-color: #fbfcfc;
}

.table-hover .table-light:hover {
    background-color: #ececf6;
}

.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
    background-color: #ececf6;
}

.table-dark,
.table-dark>th,
.table-dark>td {
    background-color: #bdbfc2;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody+tbody {
    border-color: #84888d;
}

.table-hover .table-dark:hover {
    background-color: #b0b2b6;
}

.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
    background-color: #b0b2b6;
}

.table-black,
.table-black>th,
.table-black>td {
    background-color: #b8b8b8;
}

.table-black th,
.table-black td,
.table-black thead th,
.table-black tbody+tbody {
    border-color: #7a7a7a;
}

.table-hover .table-black:hover {
    background-color: #ababab;
}

.table-hover .table-black:hover>td,
.table-hover .table-black:hover>th {
    background-color: #ababab;
}

.table-white,
.table-white>th,
.table-white>td {
    background-color: white;
}

.table-white th,
.table-white td,
.table-white thead th,
.table-white tbody+tbody {
    border-color: white;
}

.table-hover .table-white:hover {
    background-color: #f2f2f2;
}

.table-hover .table-white:hover>td,
.table-hover .table-white:hover>th {
    background-color: #f2f2f2;
}

.table-active,
.table-active>th,
.table-active>td {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
    background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
    color: #fff;
    background-color: #343a40;
    border-color: #454d55;
}

.table .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6;
}

.table-dark {
    color: #fff;
    background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
    border-color: #454d55;
}

.table-dark.table-bordered {
    border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-sm>.table-bordered {
        border: 0;
    }
}

@media (max-width: 767.98px) {
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-md>.table-bordered {
        border: 0;
    }
}

@media (max-width: 991.98px) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-lg>.table-bordered {
        border: 0;
    }
}

@media (max-width: 1199.98px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-xl>.table-bordered {
        border: 0;
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.table-responsive>.table-bordered {
    border: 0;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.33em + 2rem + 2px);
    padding: 1rem 1.062rem;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1.33;
    color: #4e5d78;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d9dfe7;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .form-control {
        transition: none;
    }
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0;
}

.form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #4e5d78;
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1;
}

.form-control::-moz-placeholder {
    color: #6c757d;
    opacity: 1;
}

.form-control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1;
}

.form-control::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1;
}

.form-control::placeholder {
    color: #6c757d;
    opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #f1f1f1;
    opacity: 1;
}

select.form-control:focus::-ms-value {
    color: #4e5d78;
    background-color: #fff;
}

.form-control-file,
.form-control-range {
    display: block;
    width: 100%;
}

.col-form-label {
    padding-top: calc(1rem + 1px);
    padding-bottom: calc(1rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.33;
}

.col-form-label-lg {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    font-size: 1.25rem;
    line-height: 1.5;
}

.col-form-label-sm {
    padding-top: calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem + 1px);
    font-size: 0.875rem;
    line-height: 1.5;
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding: 1rem 0;
    margin-bottom: 0;
    font-size: 0.75rem;
    line-height: 1.33;
    color: #212529;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0;
}

.form-control-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.form-control-lg {
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

select.form-control[size],
select.form-control[multiple] {
    height: auto;
}

textarea.form-control {
    height: auto;
}

.form-group {
    margin-bottom: 1rem;
}

.form-text {
    display: block;
    margin-top: 0.25rem;
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}

.form-row>.col,
.form-row>[class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
}

.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
}

.form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem;
}

.form-check-input[disabled]~.form-check-label,
.form-check-input:disabled~.form-check-label {
    color: #4e5d78;
}

.form-check-label {
    margin-bottom: 0;
}

.form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0;
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #35ac80;
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: .1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #ffffff;
    background-color: rgba(53, 172, 128, 0.9);
    border-radius: 0.25rem;
}

.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip,
.is-valid~.valid-feedback,
.is-valid~.valid-tooltip {
    display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
    border-color: #35ac80;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%2335ac80' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
    border-color: #35ac80;
    box-shadow: 0 0 0 0.2rem rgba(53, 172, 128, 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid,
.custom-select.is-valid {
    border-color: #35ac80;
    padding-right: calc(0.75em + 2.3125rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%2335ac80' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
    border-color: #35ac80;
    box-shadow: 0 0 0 0.2rem rgba(53, 172, 128, 0.25);
}

.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label {
    color: #35ac80;
}

.was-validated .form-check-input:valid~.valid-feedback,
.was-validated .form-check-input:valid~.valid-tooltip,
.form-check-input.is-valid~.valid-feedback,
.form-check-input.is-valid~.valid-tooltip {
    display: block;
}

.was-validated .custom-control-input:valid~.custom-control-label,
.custom-control-input.is-valid~.custom-control-label {
    color: #35ac80;
}

.was-validated .custom-control-input:valid~.custom-control-label::before,
.custom-control-input.is-valid~.custom-control-label::before {
    border-color: #35ac80;
}

.was-validated .custom-control-input:valid:checked~.custom-control-label::before,
.custom-control-input.is-valid:checked~.custom-control-label::before {
    border-color: #4cc89a;
    background-color: #4cc89a;
}

.was-validated .custom-control-input:valid:focus~.custom-control-label::before,
.custom-control-input.is-valid:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(53, 172, 128, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked)~.custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked)~.custom-control-label::before {
    border-color: #35ac80;
}

.was-validated .custom-file-input:valid~.custom-file-label,
.custom-file-input.is-valid~.custom-file-label {
    border-color: #35ac80;
}

.was-validated .custom-file-input:valid:focus~.custom-file-label,
.custom-file-input.is-valid:focus~.custom-file-label {
    border-color: #35ac80;
    box-shadow: 0 0 0 0.2rem rgba(53, 172, 128, 0.25);
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #fe3f3e;
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: .1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #ffffff;
    background-color: rgba(254, 63, 62, 0.9);
    border-radius: 0.25rem;
}

.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip,
.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip {
    display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    border-color: #fe3f3e;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fe3f3e' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-x'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
    border-color: #fe3f3e;
    box-shadow: 0 0 0 0.2rem rgba(254, 63, 62, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
    border-color: #fe3f3e;
    padding-right: calc(0.75em + 2.3125rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fe3f3e' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-x'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
    border-color: #fe3f3e;
    box-shadow: 0 0 0 0.2rem rgba(254, 63, 62, 0.25);
}

.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label {
    color: #fe3f3e;
}

.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip,
.form-check-input.is-invalid~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip {
    display: block;
}

.was-validated .custom-control-input:invalid~.custom-control-label,
.custom-control-input.is-invalid~.custom-control-label {
    color: #fe3f3e;
}

.was-validated .custom-control-input:invalid~.custom-control-label::before,
.custom-control-input.is-invalid~.custom-control-label::before {
    border-color: #fe3f3e;
}

.was-validated .custom-control-input:invalid:checked~.custom-control-label::before,
.custom-control-input.is-invalid:checked~.custom-control-label::before {
    border-color: #fe7171;
    background-color: #fe7171;
}

.was-validated .custom-control-input:invalid:focus~.custom-control-label::before,
.custom-control-input.is-invalid:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(254, 63, 62, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked)~.custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked)~.custom-control-label::before {
    border-color: #fe3f3e;
}

.was-validated .custom-file-input:invalid~.custom-file-label,
.custom-file-input.is-invalid~.custom-file-label {
    border-color: #fe3f3e;
}

.was-validated .custom-file-input:invalid:focus~.custom-file-label,
.custom-file-input.is-invalid:focus~.custom-file-label {
    border-color: #fe3f3e;
    box-shadow: 0 0 0 0.2rem rgba(254, 63, 62, 0.25);
}

.warning-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #f7b731;
}

.warning-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: .1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #000000;
    background-color: rgba(247, 183, 49, 0.9);
    border-radius: 0.25rem;
}

.is-warning~.warning-feedback,
.is-warning~.warning-tooltip {
    display: block;
}

.form-control.is-warning {
    border-color: #f7b731;
    padding-right: calc(1.5em + 0.75rem);
    background-image: none;
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-control.is-warning:focus {
    border-color: #f7b731;
    box-shadow: 0 0 0 0.2rem rgba(247, 183, 49, 0.25);
}

textarea.form-control.is-warning {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.custom-select.is-warning {
    border-color: #f7b731;
    padding-right: calc(0.75em + 2.3125rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, none #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.custom-select.is-warning:focus {
    border-color: #f7b731;
    box-shadow: 0 0 0 0.2rem rgba(247, 183, 49, 0.25);
}

.form-check-input.is-warning~.form-check-label {
    color: #f7b731;
}

.form-check-input.is-warning~.warning-feedback,
.form-check-input.is-warning~.warning-tooltip {
    display: block;
}

.custom-control-input.is-warning~.custom-control-label {
    color: #f7b731;
}

.custom-control-input.is-warning~.custom-control-label::before {
    border-color: #f7b731;
}

.custom-control-input.is-warning:checked~.custom-control-label::before {
    border-color: #f9c862;
    background-color: #f9c862;
}

.custom-control-input.is-warning:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(247, 183, 49, 0.25);
}

.custom-control-input.is-warning:focus:not(:checked)~.custom-control-label::before {
    border-color: #f7b731;
}

.custom-file-input.is-warning~.custom-file-label {
    border-color: #f7b731;
}

.custom-file-input.is-warning:focus~.custom-file-label {
    border-color: #f7b731;
    box-shadow: 0 0 0 0.2rem rgba(247, 183, 49, 0.25);
}

.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.form-inline .form-check {
    width: 100%;
}

@media (min-width: 576px) {
    .form-inline label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
    }
    .form-inline .form-group {
        display: flex;
        flex: 0 0 auto;
        flex-flow: row wrap;
        align-items: center;
        margin-bottom: 0;
    }
    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }
    .form-inline .form-control-plaintext {
        display: inline-block;
    }
    .form-inline .input-group,
    .form-inline .custom-select {
        width: auto;
    }
    .form-inline .form-check {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        padding-left: 0;
    }
    .form-inline .form-check-input {
        position: relative;
        flex-shrink: 0;
        margin-top: 0;
        margin-right: 0.25rem;
        margin-left: 0;
    }
    .form-inline .custom-control {
        align-items: center;
        justify-content: center;
    }
    .form-inline .custom-control-label {
        margin-bottom: 0;
    }
}

.btn {
    display: inline-block;
    font-weight: 400;
    color: #000000;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .btn {
        transition: none;
    }
}

.btn:hover {
    color: #000000;
    text-decoration: none;
}

.btn:focus,
.btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled,
.btn:disabled {
    opacity: 0.65;
}

a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none;
}

.btn-primary {
    color: #ffffff;
    background-color: #0684fe;
    border-color: #0684fe;
}

.btn-primary:hover {
    color: #ffffff;
    background-color: #0171dd;
    border-color: #016ad0;
}

.btn-primary:focus,
.btn-primary.focus {
    color: #ffffff;
    background-color: #0171dd;
    border-color: #016ad0;
    box-shadow: 0 0 0 0.2rem rgba(43, 150, 254, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #ffffff;
    background-color: #0684fe;
    border-color: #0684fe;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #016ad0;
    border-color: #0164c3;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(43, 150, 254, 0.5);
}

.btn-secondary {
    color: #000000;
    background-color: #f5f6f8;
    border-color: #f5f6f8;
}

.btn-secondary:hover {
    color: #000000;
    background-color: #dfe2e8;
    border-color: #d7dbe3;
}

.btn-secondary:focus,
.btn-secondary.focus {
    color: #000000;
    background-color: #dfe2e8;
    border-color: #d7dbe3;
    box-shadow: 0 0 0 0.2rem rgba(208, 209, 211, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    color: #000000;
    background-color: #f5f6f8;
    border-color: #f5f6f8;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
    color: #000000;
    background-color: #d7dbe3;
    border-color: #d0d4de;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(208, 209, 211, 0.5);
}

.btn-success {
    color: #ffffff;
    background-color: #35ac80;
    border-color: #35ac80;
}

.btn-success:hover {
    color: #ffffff;
    background-color: #2c8f6a;
    border-color: #298563;
}

.btn-success:focus,
.btn-success.focus {
    color: #ffffff;
    background-color: #2c8f6a;
    border-color: #298563;
    box-shadow: 0 0 0 0.2rem rgba(83, 184, 147, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
    color: #ffffff;
    background-color: #35ac80;
    border-color: #35ac80;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show>.btn-success.dropdown-toggle {
    color: #ffffff;
    background-color: #298563;
    border-color: #267b5c;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(83, 184, 147, 0.5);
}

.btn-info {
    color: #ffffff;
    background-color: #5d3fff;
    border-color: #5d3fff;
}

.btn-info:hover {
    color: #ffffff;
    background-color: #3d19ff;
    border-color: #320cff;
}

.btn-info:focus,
.btn-info.focus {
    color: #ffffff;
    background-color: #3d19ff;
    border-color: #320cff;
    box-shadow: 0 0 0 0.2rem rgba(117, 92, 255, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
    color: #ffffff;
    background-color: #5d3fff;
    border-color: #5d3fff;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show>.btn-info.dropdown-toggle {
    color: #ffffff;
    background-color: #320cff;
    border-color: #2800fe;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(117, 92, 255, 0.5);
}

.btn-warning {
    color: #000000;
    background-color: #f7b731;
    border-color: #f7b731;
}

.btn-warning:hover {
    color: #000000;
    background-color: #f6aa0c;
    border-color: #eca309;
}

.btn-warning:focus,
.btn-warning.focus {
    color: #000000;
    background-color: #f6aa0c;
    border-color: #eca309;
    box-shadow: 0 0 0 0.2rem rgba(210, 156, 42, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
    color: #000000;
    background-color: #f7b731;
    border-color: #f7b731;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show>.btn-warning.dropdown-toggle {
    color: #000000;
    background-color: #eca309;
    border-color: #e09a09;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(210, 156, 42, 0.5);
}

.btn-danger {
    color: #ffffff;
    background-color: #fe3f3e;
    border-color: #fe3f3e;
}

.btn-danger:hover {
    color: #ffffff;
    background-color: #fe1918;
    border-color: #fe0d0b;
}

.btn-danger:focus,
.btn-danger.focus {
    color: #ffffff;
    background-color: #fe1918;
    border-color: #fe0d0b;
    box-shadow: 0 0 0 0.2rem rgba(254, 92, 91, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
    color: #ffffff;
    background-color: #fe3f3e;
    border-color: #fe3f3e;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show>.btn-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #fe0d0b;
    border-color: #fb0301;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(254, 92, 91, 0.5);
}

.btn-light {
    color: #000000;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-light:hover {
    color: #000000;
    background-color: #e2e6ea;
    border-color: #dae0e5;
}

.btn-light:focus,
.btn-light.focus {
    color: #000000;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(211, 212, 213, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
    color: #000000;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show>.btn-light.dropdown-toggle {
    color: #000000;
    background-color: #dae0e5;
    border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(211, 212, 213, 0.5);
}

.btn-dark {
    color: #ffffff;
    background-color: #131b24;
    border-color: #131b24;
}

.btn-dark:hover {
    color: #ffffff;
    background-color: #06080b;
    border-color: #010203;
}

.btn-dark:focus,
.btn-dark.focus {
    color: #ffffff;
    background-color: #06080b;
    border-color: #010203;
    box-shadow: 0 0 0 0.2rem rgba(54, 61, 69, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
    color: #ffffff;
    background-color: #131b24;
    border-color: #131b24;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show>.btn-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #010203;
    border-color: black;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(54, 61, 69, 0.5);
}

.btn-black {
    color: #ffffff;
    background-color: #000000;
    border-color: #000000;
}

.btn-black:hover {
    color: #ffffff;
    background-color: black;
    border-color: black;
}

.btn-black:focus,
.btn-black.focus {
    color: #ffffff;
    background-color: black;
    border-color: black;
    box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}

.btn-black.disabled,
.btn-black:disabled {
    color: #ffffff;
    background-color: #000000;
    border-color: #000000;
}

.btn-black:not(:disabled):not(.disabled):active,
.btn-black:not(:disabled):not(.disabled).active,
.show>.btn-black.dropdown-toggle {
    color: #ffffff;
    background-color: black;
    border-color: black;
}

.btn-black:not(:disabled):not(.disabled):active:focus,
.btn-black:not(:disabled):not(.disabled).active:focus,
.show>.btn-black.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}

.btn-white {
    color: #000000;
    background-color: #ffffff;
    border-color: #ffffff;
}

.btn-white:hover {
    color: #000000;
    background-color: #ececec;
    border-color: #e6e6e6;
}

.btn-white:focus,
.btn-white.focus {
    color: #000000;
    background-color: #ececec;
    border-color: #e6e6e6;
    box-shadow: 0 0 0 0.2rem rgba(217, 217, 217, 0.5);
}

.btn-white.disabled,
.btn-white:disabled {
    color: #000000;
    background-color: #ffffff;
    border-color: #ffffff;
}

.btn-white:not(:disabled):not(.disabled):active,
.btn-white:not(:disabled):not(.disabled).active,
.show>.btn-white.dropdown-toggle {
    color: #000000;
    background-color: #e6e6e6;
    border-color: #dfdfdf;
}

.btn-white:not(:disabled):not(.disabled):active:focus,
.btn-white:not(:disabled):not(.disabled).active:focus,
.show>.btn-white.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(217, 217, 217, 0.5);
}

.btn-outline-primary {
    color: #0684fe;
    border-color: #0684fe;
}

.btn-outline-primary:hover {
    color: #ffffff;
    background-color: #0684fe;
    border-color: #0684fe;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(6, 132, 254, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: #0684fe;
    background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #0684fe;
    border-color: #0684fe;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(6, 132, 254, 0.5);
}

.btn-outline-secondary {
    color: #f5f6f8;
    border-color: #f5f6f8;
}

.btn-outline-secondary:hover {
    color: #000000;
    background-color: #f5f6f8;
    border-color: #f5f6f8;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(245, 246, 248, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
    color: #f5f6f8;
    background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show>.btn-outline-secondary.dropdown-toggle {
    color: #000000;
    background-color: #f5f6f8;
    border-color: #f5f6f8;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(245, 246, 248, 0.5);
}

.btn-outline-success {
    color: #35ac80;
    border-color: #35ac80;
}

.btn-outline-success:hover {
    color: #ffffff;
    background-color: #35ac80;
    border-color: #35ac80;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(53, 172, 128, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
    color: #35ac80;
    background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show>.btn-outline-success.dropdown-toggle {
    color: #ffffff;
    background-color: #35ac80;
    border-color: #35ac80;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(53, 172, 128, 0.5);
}

.btn-outline-info {
    color: #5d3fff;
    border-color: #5d3fff;
}

.btn-outline-info:hover {
    color: #ffffff;
    background-color: #5d3fff;
    border-color: #5d3fff;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(93, 63, 255, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
    color: #5d3fff;
    background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show>.btn-outline-info.dropdown-toggle {
    color: #ffffff;
    background-color: #5d3fff;
    border-color: #5d3fff;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(93, 63, 255, 0.5);
}

.btn-outline-warning {
    color: #f7b731;
    border-color: #f7b731;
}

.btn-outline-warning:hover {
    color: #000000;
    background-color: #f7b731;
    border-color: #f7b731;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(247, 183, 49, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    color: #f7b731;
    background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show>.btn-outline-warning.dropdown-toggle {
    color: #000000;
    background-color: #f7b731;
    border-color: #f7b731;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(247, 183, 49, 0.5);
}

.btn-outline-danger {
    color: #fe3f3e;
    border-color: #fe3f3e;
}

.btn-outline-danger:hover {
    color: #ffffff;
    background-color: #fe3f3e;
    border-color: #fe3f3e;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(254, 63, 62, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    color: #fe3f3e;
    background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show>.btn-outline-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #fe3f3e;
    border-color: #fe3f3e;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(254, 63, 62, 0.5);
}

.btn-outline-light {
    color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-outline-light:hover {
    color: #000000;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show>.btn-outline-light.dropdown-toggle {
    color: #000000;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
    color: #131b24;
    border-color: #131b24;
}

.btn-outline-dark:hover {
    color: #ffffff;
    background-color: #131b24;
    border-color: #131b24;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(19, 27, 36, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
    color: #131b24;
    background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show>.btn-outline-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #131b24;
    border-color: #131b24;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(19, 27, 36, 0.5);
}

.btn-outline-black {
    color: #000000;
    border-color: #000000;
}

.btn-outline-black:hover {
    color: #ffffff;
    background-color: #000000;
    border-color: #000000;
}

.btn-outline-black:focus,
.btn-outline-black.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-black.disabled,
.btn-outline-black:disabled {
    color: #000000;
    background-color: transparent;
}

.btn-outline-black:not(:disabled):not(.disabled):active,
.btn-outline-black:not(:disabled):not(.disabled).active,
.show>.btn-outline-black.dropdown-toggle {
    color: #ffffff;
    background-color: #000000;
    border-color: #000000;
}

.btn-outline-black:not(:disabled):not(.disabled):active:focus,
.btn-outline-black:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-black.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-white {
    color: #ffffff;
    border-color: #ffffff;
}

.btn-outline-white:hover {
    color: #000000;
    background-color: #ffffff;
    border-color: #ffffff;
}

.btn-outline-white:focus,
.btn-outline-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-white.disabled,
.btn-outline-white:disabled {
    color: #ffffff;
    background-color: transparent;
}

.btn-outline-white:not(:disabled):not(.disabled):active,
.btn-outline-white:not(:disabled):not(.disabled).active,
.show>.btn-outline-white.dropdown-toggle {
    color: #000000;
    background-color: #ffffff;
    border-color: #ffffff;
}

.btn-outline-white:not(:disabled):not(.disabled):active:focus,
.btn-outline-white:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-white.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-link {
    font-weight: 400;
    color: #007bff;
    text-decoration: none;
}

.btn-link:hover {
    color: #0056b3;
    text-decoration: underline;
}

.btn-link:focus,
.btn-link.focus {
    text-decoration: underline;
    box-shadow: none;
}

.btn-link:disabled,
.btn-link.disabled {
    color: #6c757d;
    pointer-events: none;
}

.btn-lg,
.btn-group-lg>.btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

.btn-sm,
.btn-group-sm>.btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-block+.btn-block {
    margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
    width: 100%;
}

.fade {
    transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
    .fade {
        transition: none;
    }
}

.fade:not(.show) {
    opacity: 0;
}

.collapse:not(.show) {
    display: none;
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
    .collapsing {
        transition: none;
    }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
    position: relative;
}

.dropdown-toggle {
    white-space: nowrap;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
}

.dropdown-menu-left {
    right: auto;
    left: 0;
}

.dropdown-menu-right {
    right: 0;
    left: auto;
}

@media (min-width: 576px) {
    .dropdown-menu-sm-left {
        right: auto;
        left: 0;
    }
    .dropdown-menu-sm-right {
        right: 0;
        left: auto;
    }
}

@media (min-width: 768px) {
    .dropdown-menu-md-left {
        right: auto;
        left: 0;
    }
    .dropdown-menu-md-right {
        right: 0;
        left: auto;
    }
}

@media (min-width: 992px) {
    .dropdown-menu-lg-left {
        right: auto;
        left: 0;
    }
    .dropdown-menu-lg-right {
        right: 0;
        left: auto;
    }
}

@media (min-width: 1200px) {
    .dropdown-menu-xl-left {
        right: auto;
        left: 0;
    }
    .dropdown-menu-xl-right {
        right: 0;
        left: auto;
    }
}

.dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.125rem;
}

.dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
    vertical-align: 0;
}

.dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::before {
    vertical-align: 0;
}

.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
    right: auto;
    bottom: auto;
}

.dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef;
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa;
}

.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent;
}

.dropdown-menu.show {
    display: block;
}

.dropdown-header {
    display: block;
    padding: 0.5rem 1.5rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: #6c757d;
    white-space: nowrap;
}

.dropdown-item-text {
    display: block;
    padding: 0.25rem 1.5rem;
    color: #212529;
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
}

.btn-group>.btn,
.btn-group-vertical>.btn {
    position: relative;
    flex: 1 1 auto;
}

.btn-group>.btn:hover,
.btn-group-vertical>.btn:hover {
    z-index: 1;
}

.btn-group>.btn:focus,
.btn-group>.btn:active,
.btn-group>.btn.active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn.active {
    z-index: 1;
}

.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.btn-toolbar .input-group {
    width: auto;
}

.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child) {
    margin-left: -1px;
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn-group:not(:last-child)>.btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child)>.btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
    padding-right: 0.5625rem;
    padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
    margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
    margin-right: 0;
}

.btn-sm+.dropdown-toggle-split,
.btn-group-sm>.btn+.dropdown-toggle-split {
    padding-right: 0.375rem;
    padding-left: 0.375rem;
}

.btn-lg+.dropdown-toggle-split,
.btn-group-lg>.btn+.dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
}

.btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
    width: 100%;
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child) {
    margin-top: -1px;
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.btn-group:not(:last-child)>.btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child)>.btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.btn-group-toggle>.btn,
.btn-group-toggle>.btn-group>.btn {
    margin-bottom: 0;
}

.btn-group-toggle>.btn input[type="radio"],
.btn-group-toggle>.btn input[type="checkbox"],
.btn-group-toggle>.btn-group>.btn input[type="radio"],
.btn-group-toggle>.btn-group>.btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

.input-group>.form-control,
.input-group>.form-control-plaintext,
.input-group>.custom-select,
.input-group>.custom-file {
    position: relative;
    flex: 1 1 0%;
    min-width: 0;
    margin-bottom: 0;
}

.input-group>.form-control+.form-control,
.input-group>.form-control+.custom-select,
.input-group>.form-control+.custom-file,
.input-group>.form-control-plaintext+.form-control,
.input-group>.form-control-plaintext+.custom-select,
.input-group>.form-control-plaintext+.custom-file,
.input-group>.custom-select+.form-control,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.custom-file,
.input-group>.custom-file+.form-control,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.custom-file {
    margin-left: -1px;
}

.input-group>.form-control:focus,
.input-group>.custom-select:focus,
.input-group>.custom-file .custom-file-input:focus~.custom-file-label {
    z-index: 3;
}

.input-group>.custom-file .custom-file-input:focus {
    z-index: 4;
}

.input-group>.form-control:not(:last-child),
.input-group>.custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.form-control:not(:first-child),
.input-group>.custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group>.custom-file {
    display: flex;
    align-items: center;
}

.input-group>.custom-file:not(:last-child) .custom-file-label,
.input-group>.custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.custom-file:not(:first-child) .custom-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
    display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
    position: relative;
    z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
    z-index: 3;
}

.input-group-prepend .btn+.btn,
.input-group-prepend .btn+.input-group-text,
.input-group-prepend .input-group-text+.input-group-text,
.input-group-prepend .input-group-text+.btn,
.input-group-append .btn+.btn,
.input-group-append .btn+.input-group-text,
.input-group-append .input-group-text+.input-group-text,
.input-group-append .input-group-text+.btn {
    margin-left: -1px;
}

.input-group-prepend {
    margin-right: -1px;
}

.input-group-append {
    margin-left: -1px;
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: 1rem 1.062rem;
    margin-bottom: 0;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.33;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
    margin-top: 0;
}

.input-group-lg>.form-control:not(textarea),
.input-group-lg>.custom-select {
    height: calc(1.5em + 1rem + 2px);
}

.input-group-lg>.form-control,
.input-group-lg>.custom-select,
.input-group-lg>.input-group-prepend>.input-group-text,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-append>.btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

.input-group-sm>.form-control:not(textarea),
.input-group-sm>.custom-select {
    height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm>.form-control,
.input-group-sm>.custom-select,
.input-group-sm>.input-group-prepend>.input-group-text,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-append>.btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.input-group-lg>.custom-select,
.input-group-sm>.custom-select {
    padding-right: 1.75rem;
}

.input-group>.input-group-prepend>.btn,
.input-group>.input-group-prepend>.input-group-text,
.input-group>.input-group-append:not(:last-child)>.btn,
.input-group>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group>.input-group-append:last-child>.input-group-text:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
}

.custom-control-inline {
    display: inline-flex;
    margin-right: 1rem;
}

.custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff;
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: #80bdff;
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff;
}

.custom-control-input[disabled]~.custom-control-label,
.custom-control-input:disabled~.custom-control-label {
    color: #6c757d;
}

.custom-control-input[disabled]~.custom-control-label::before,
.custom-control-input:disabled~.custom-control-label::before {
    background-color: #e9ecef;
}

.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
}

.custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px;
}

.custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
    border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
    border-color: #007bff;
    background-color: #007bff;
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
    border-radius: 50%;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
    padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .custom-switch .custom-control-label::after {
        transition: none;
    }
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-select:focus::-ms-value {
    color: #4e5d78;
    background-color: #fff;
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none;
}

.custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef;
}

.custom-select::-ms-expand {
    display: none;
}

.custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    font-size: 0.875rem;
}

.custom-select-lg {
    height: calc(1.5em + 1rem + 2px);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    font-size: 1.25rem;
}

.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    margin-bottom: 0;
}

.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    margin: 0;
    opacity: 0;
}

.custom-file-input:focus~.custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-file-input[disabled]~.custom-file-label,
.custom-file-input:disabled~.custom-file-label {
    background-color: #e9ecef;
}

.custom-file-input:lang(en)~.custom-file-label::after {
    content: "Browse";
}

.custom-file-input~.custom-file-label[data-browse]::after {
    content: attr(data-browse);
}

.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
    width: 100%;
    height: 1.4rem;
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.custom-range:focus {
    outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-ms-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range::-moz-focus-outer {
    border: 0;
}

.custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
}

@media (prefers-reduced-motion: reduce) {
    .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none;
    }
}

.custom-range::-webkit-slider-thumb:active {
    background-color: #b3d7ff;
}

.custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
    appearance: none;
}

@media (prefers-reduced-motion: reduce) {
    .custom-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none;
    }
}

.custom-range::-moz-range-thumb:active {
    background-color: #b3d7ff;
}

.custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
}

.custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
}

@media (prefers-reduced-motion: reduce) {
    .custom-range::-ms-thumb {
        -ms-transition: none;
        transition: none;
    }
}

.custom-range::-ms-thumb:active {
    background-color: #b3d7ff;
}

.custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
    cursor: default;
}

.custom-range:disabled::-ms-thumb {
    background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
        transition: none;
    }
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
    text-decoration: none;
}

.nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default;
}

.nav-tabs {
    border-bottom: 1px solid #e9ecf1;
}

.nav-tabs .nav-item {
    margin-bottom: -1px;
}

.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.nav-pills .nav-link {
    border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #007bff;
}

.nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center;
}

.nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
}

.tab-content>.tab-pane {
    display: none;
}

.tab-content>.active {
    display: block;
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
}

.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.navbar-brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
    text-decoration: none;
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}

.navbar-nav .dropdown-menu {
    position: static;
    float: none;
}

.navbar-text {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
}

.navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
    text-decoration: none;
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
}

@media (max-width: 575.98px) {
    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid,
    .navbar-expand-sm>.container-sm,
    .navbar-expand-sm>.container-md,
    .navbar-expand-sm>.container-lg,
    .navbar-expand-sm>.container-xl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 576px) {
    .navbar-expand-sm {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-sm .navbar-nav {
        flex-direction: row;
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.937rem;
        padding-left: 0.937rem;
    }
    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid,
    .navbar-expand-sm>.container-sm,
    .navbar-expand-sm>.container-md,
    .navbar-expand-sm>.container-lg,
    .navbar-expand-sm>.container-xl {
        flex-wrap: nowrap;
    }
    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-expand-sm .navbar-toggler {
        display: none;
    }
}

@media (max-width: 767.98px) {
    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid,
    .navbar-expand-md>.container-sm,
    .navbar-expand-md>.container-md,
    .navbar-expand-md>.container-lg,
    .navbar-expand-md>.container-xl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 768px) {
    .navbar-expand-md {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-md .navbar-nav {
        flex-direction: row;
    }
    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.937rem;
        padding-left: 0.937rem;
    }
    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid,
    .navbar-expand-md>.container-sm,
    .navbar-expand-md>.container-md,
    .navbar-expand-md>.container-lg,
    .navbar-expand-md>.container-xl {
        flex-wrap: nowrap;
    }
    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-expand-md .navbar-toggler {
        display: none;
    }
}

@media (max-width: 991.98px) {
    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid,
    .navbar-expand-lg>.container-sm,
    .navbar-expand-lg>.container-md,
    .navbar-expand-lg>.container-lg,
    .navbar-expand-lg>.container-xl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.937rem;
        padding-left: 0.937rem;
    }
    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid,
    .navbar-expand-lg>.container-sm,
    .navbar-expand-lg>.container-md,
    .navbar-expand-lg>.container-lg,
    .navbar-expand-lg>.container-xl {
        flex-wrap: nowrap;
    }
    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-expand-lg .navbar-toggler {
        display: none;
    }
}

@media (max-width: 1199.98px) {
    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid,
    .navbar-expand-xl>.container-sm,
    .navbar-expand-xl>.container-md,
    .navbar-expand-xl>.container-lg,
    .navbar-expand-xl>.container-xl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 1200px) {
    .navbar-expand-xl {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-xl .navbar-nav {
        flex-direction: row;
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.937rem;
        padding-left: 0.937rem;
    }
    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid,
    .navbar-expand-xl>.container-sm,
    .navbar-expand-xl>.container-md,
    .navbar-expand-xl>.container-lg,
    .navbar-expand-xl>.container-xl {
        flex-wrap: nowrap;
    }
    .navbar-expand-xl .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-expand-xl .navbar-toggler {
        display: none;
    }
}

.navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-start;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-sm,
.navbar-expand>.container-md,
.navbar-expand>.container-lg,
.navbar-expand>.container-xl {
    padding-right: 0;
    padding-left: 0;
}

.navbar-expand .navbar-nav {
    flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: 0.937rem;
    padding-left: 0.937rem;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-sm,
.navbar-expand>.container-md,
.navbar-expand>.container-lg,
.navbar-expand>.container-xl {
    flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
}

.navbar-expand .navbar-toggler {
    display: none;
}

.navbar-light .navbar-brand {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
    color: #000000;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
    color: #000000;
    border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
    color: #000000;
}

.navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
    color: #fff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
    color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
    color: #fff;
}

.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
    color: #fff;
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
    color: #fff;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
}

.card>hr {
    margin-right: 0;
    margin-left: 0;
}

.card>.list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.card>.list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
}

.card-title {
    margin-bottom: 0.75rem;
}

.card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0;
}

.card-text:last-child {
    margin-bottom: 0;
}

.card-link:hover {
    text-decoration: none;
}

.card-link+.card-link {
    margin-left: 1.25rem;
}

.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header+.list-group .list-group-item:first-child {
    border-top: 0;
}

.card-footer {
    padding: 0.75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -0.75rem;
    margin-left: -0.625rem;
    border-bottom: 0;
}

.card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
    flex-shrink: 0;
    width: 100%;
}

.card-img,
.card-img-top {
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
    margin-bottom: 15px;
}

@media (min-width: 576px) {
    .card-deck {
        display: flex;
        flex-flow: row wrap;
        margin-right: -15px;
        margin-left: -15px;
    }
    .card-deck .card {
        flex: 1 0 0%;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px;
    }
}

.card-group>.card {
    margin-bottom: 15px;
}

@media (min-width: 576px) {
    .card-group {
        display: flex;
        flex-flow: row wrap;
    }
    .card-group>.card {
        flex: 1 0 0%;
        margin-bottom: 0;
    }
    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0;
    }
    .card-group>.card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .card-group>.card:not(:last-child) .card-img-top,
    .card-group>.card:not(:last-child) .card-header {
        border-top-right-radius: 0;
    }
    .card-group>.card:not(:last-child) .card-img-bottom,
    .card-group>.card:not(:last-child) .card-footer {
        border-bottom-right-radius: 0;
    }
    .card-group>.card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .card-group>.card:not(:first-child) .card-img-top,
    .card-group>.card:not(:first-child) .card-header {
        border-top-left-radius: 0;
    }
    .card-group>.card:not(:first-child) .card-img-bottom,
    .card-group>.card:not(:first-child) .card-footer {
        border-bottom-left-radius: 0;
    }
}

.card-columns .card {
    margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
    .card-columns {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 1.25rem;
        -moz-column-gap: 1.25rem;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1;
    }
    .card-columns .card {
        display: inline-block;
        width: 100%;
    }
}

.accordion>.card {
    overflow: hidden;
}

.accordion>.card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.accordion>.card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.accordion>.card>.card-header {
    border-radius: 0;
    margin-bottom: -1px;
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #e9ecef;
    border-radius: 0.25rem;
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: 0.5rem;
}

.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/";
}

.breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: underline;
}

.breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: none;
}

.breadcrumb-item.active {
    color: #6c757d;
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff;
    background-color: #fff;
    border: 1px solid #dee2e6;
}

.page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6;
}

.page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
}

.pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
}

.pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
}

.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .badge {
        transition: none;
    }
}

a.badge:hover,
a.badge:focus {
    text-decoration: none;
}

.badge:empty {
    display: none;
}

.btn .badge {
    position: relative;
    top: -1px;
}

.badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
}

.badge-primary {
    color: #ffffff;
    background-color: #0684fe;
}

a.badge-primary:hover,
a.badge-primary:focus {
    color: #ffffff;
    background-color: #016ad0;
}

a.badge-primary:focus,
a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(6, 132, 254, 0.5);
}

.badge-secondary {
    color: #000000;
    background-color: #f5f6f8;
}

a.badge-secondary:hover,
a.badge-secondary:focus {
    color: #000000;
    background-color: #d7dbe3;
}

a.badge-secondary:focus,
a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(245, 246, 248, 0.5);
}

.badge-success {
    color: #ffffff;
    background-color: #35ac80;
}

a.badge-success:hover,
a.badge-success:focus {
    color: #ffffff;
    background-color: #298563;
}

a.badge-success:focus,
a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(53, 172, 128, 0.5);
}

.badge-info {
    color: #ffffff;
    background-color: #5d3fff;
}

a.badge-info:hover,
a.badge-info:focus {
    color: #ffffff;
    background-color: #320cff;
}

a.badge-info:focus,
a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(93, 63, 255, 0.5);
}

.badge-warning {
    color: #000000;
    background-color: #f7b731;
}

a.badge-warning:hover,
a.badge-warning:focus {
    color: #000000;
    background-color: #eca309;
}

a.badge-warning:focus,
a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(247, 183, 49, 0.5);
}

.badge-danger {
    color: #ffffff;
    background-color: #fe3f3e;
}

a.badge-danger:hover,
a.badge-danger:focus {
    color: #ffffff;
    background-color: #fe0d0b;
}

a.badge-danger:focus,
a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(254, 63, 62, 0.5);
}

.badge-light {
    color: #000000;
    background-color: #f8f9fa;
}

a.badge-light:hover,
a.badge-light:focus {
    color: #000000;
    background-color: #dae0e5;
}

a.badge-light:focus,
a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
    color: #ffffff;
    background-color: #131b24;
}

a.badge-dark:hover,
a.badge-dark:focus {
    color: #ffffff;
    background-color: #010203;
}

a.badge-dark:focus,
a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(19, 27, 36, 0.5);
}

.badge-black {
    color: #ffffff;
    background-color: #000000;
}

a.badge-black:hover,
a.badge-black:focus {
    color: #ffffff;
    background-color: black;
}

a.badge-black:focus,
a.badge-black.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.badge-white {
    color: #000000;
    background-color: #ffffff;
}

a.badge-white:hover,
a.badge-white:focus {
    color: #000000;
    background-color: #e6e6e6;
}

a.badge-white:focus,
a.badge-white.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #e9ecef;
    border-radius: 0.375rem;
}

@media (min-width: 576px) {
    .jumbotron {
        padding: 4rem 2rem;
    }
}

.jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0;
}

.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.alert-heading {
    color: inherit;
}

.alert-link {
    font-weight: 700;
}

.alert-dismissible {
    padding-right: 4rem;
}

.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit;
}

.alert-primary {
    color: #034584;
    background-color: #cde6ff;
    border-color: #b9ddff;
}

.alert-primary hr {
    border-top-color: #a0d1ff;
}

.alert-primary .alert-link {
    color: #022b52;
}

.alert-secondary {
    color: #7f8081;
    background-color: #fdfdfe;
    border-color: #fcfcfd;
}

.alert-secondary hr {
    border-top-color: #ededf3;
}

.alert-secondary .alert-link {
    color: #666767;
}

.alert-success {
    color: #1c5943;
    background-color: #d7eee6;
    border-color: #c6e8db;
}

.alert-success hr {
    border-top-color: #b4e1d0;
}

.alert-success .alert-link {
    color: #103226;
}

.alert-info {
    color: #302185;
    background-color: #dfd9ff;
    border-color: #d2c9ff;
}

.alert-info hr {
    border-top-color: #bdb0ff;
}

.alert-info .alert-link {
    color: #21175c;
}

.alert-warning {
    color: #805f19;
    background-color: #fdf1d6;
    border-color: #fdebc5;
}

.alert-warning hr {
    border-top-color: #fce3ac;
}

.alert-warning .alert-link {
    color: #553f11;
}

.alert-danger {
    color: #842120;
    background-color: #ffd9d8;
    border-color: #ffc9c9;
}

.alert-danger hr {
    border-top-color: #ffb0b0;
}

.alert-danger .alert-link {
    color: #5b1716;
}

.alert-light {
    color: #818182;
    background-color: #fefefe;
    border-color: #fdfdfe;
}

.alert-light hr {
    border-top-color: #ececf6;
}

.alert-light .alert-link {
    color: #686868;
}

.alert-dark {
    color: #0a0e13;
    background-color: #d0d1d3;
    border-color: #bdbfc2;
}

.alert-dark hr {
    border-top-color: #b0b2b6;
}

.alert-dark .alert-link {
    color: black;
}

.alert-black {
    color: black;
    background-color: #cccccc;
    border-color: #b8b8b8;
}

.alert-black hr {
    border-top-color: #ababab;
}

.alert-black .alert-link {
    color: black;
}

.alert-white {
    color: #858585;
    background-color: white;
    border-color: white;
}

.alert-white hr {
    border-top-color: #f2f2f2;
}

.alert-white .alert-link {
    color: #6c6c6c;
}

@-webkit-keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0;
    }
    to {
        background-position: 0 0;
    }
}

@keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0;
    }
    to {
        background-position: 0 0;
    }
}

.progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 0.25rem;
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #007bff;
    transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
    .progress-bar {
        transition: none;
    }
}

.progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem;
}

.progress-bar-animated {
    -webkit-animation: progress-bar-stripes 1s linear infinite;
    animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
        -webkit-animation: none;
        animation: none;
    }
}

.media {
    display: flex;
    align-items: flex-start;
}

.media-body {
    flex: 1;
}

.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
}

.list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa;
}

.list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef;
}

.list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.list-group-item.disabled,
.list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff;
}

.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.list-group-item+.list-group-item {
    border-top-width: 0;
}

.list-group-item+.list-group-item.active {
    margin-top: -1px;
    border-top-width: 1px;
}

.list-group-horizontal {
    flex-direction: row;
}

.list-group-horizontal .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
}

.list-group-horizontal .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
}

.list-group-horizontal .list-group-item.active {
    margin-top: 0;
}

.list-group-horizontal .list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
}

.list-group-horizontal .list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
}

@media (min-width: 576px) {
    .list-group-horizontal-sm {
        flex-direction: row;
    }
    .list-group-horizontal-sm .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
    }
    .list-group-horizontal-sm .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
    }
    .list-group-horizontal-sm .list-group-item.active {
        margin-top: 0;
    }
    .list-group-horizontal-sm .list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }
    .list-group-horizontal-sm .list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 768px) {
    .list-group-horizontal-md {
        flex-direction: row;
    }
    .list-group-horizontal-md .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
    }
    .list-group-horizontal-md .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
    }
    .list-group-horizontal-md .list-group-item.active {
        margin-top: 0;
    }
    .list-group-horizontal-md .list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }
    .list-group-horizontal-md .list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 992px) {
    .list-group-horizontal-lg {
        flex-direction: row;
    }
    .list-group-horizontal-lg .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
    }
    .list-group-horizontal-lg .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
    }
    .list-group-horizontal-lg .list-group-item.active {
        margin-top: 0;
    }
    .list-group-horizontal-lg .list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }
    .list-group-horizontal-lg .list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 1200px) {
    .list-group-horizontal-xl {
        flex-direction: row;
    }
    .list-group-horizontal-xl .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
    }
    .list-group-horizontal-xl .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
    }
    .list-group-horizontal-xl .list-group-item.active {
        margin-top: 0;
    }
    .list-group-horizontal-xl .list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }
    .list-group-horizontal-xl .list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

.list-group-flush .list-group-item {
    border-right-width: 0;
    border-left-width: 0;
    border-radius: 0;
}

.list-group-flush .list-group-item:first-child {
    border-top-width: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
    border-bottom-width: 0;
}

.list-group-item-primary {
    color: #034584;
    background-color: #b9ddff;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
    color: #034584;
    background-color: #a0d1ff;
}

.list-group-item-primary.list-group-item-action.active {
    color: #ffffff;
    background-color: #034584;
    border-color: #034584;
}

.list-group-item-secondary {
    color: #7f8081;
    background-color: #fcfcfd;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
    color: #7f8081;
    background-color: #ededf3;
}

.list-group-item-secondary.list-group-item-action.active {
    color: #ffffff;
    background-color: #7f8081;
    border-color: #7f8081;
}

.list-group-item-success {
    color: #1c5943;
    background-color: #c6e8db;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
    color: #1c5943;
    background-color: #b4e1d0;
}

.list-group-item-success.list-group-item-action.active {
    color: #ffffff;
    background-color: #1c5943;
    border-color: #1c5943;
}

.list-group-item-info {
    color: #302185;
    background-color: #d2c9ff;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
    color: #302185;
    background-color: #bdb0ff;
}

.list-group-item-info.list-group-item-action.active {
    color: #ffffff;
    background-color: #302185;
    border-color: #302185;
}

.list-group-item-warning {
    color: #805f19;
    background-color: #fdebc5;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
    color: #805f19;
    background-color: #fce3ac;
}

.list-group-item-warning.list-group-item-action.active {
    color: #ffffff;
    background-color: #805f19;
    border-color: #805f19;
}

.list-group-item-danger {
    color: #842120;
    background-color: #ffc9c9;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
    color: #842120;
    background-color: #ffb0b0;
}

.list-group-item-danger.list-group-item-action.active {
    color: #ffffff;
    background-color: #842120;
    border-color: #842120;
}

.list-group-item-light {
    color: #818182;
    background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
    color: #ffffff;
    background-color: #818182;
    border-color: #818182;
}

.list-group-item-dark {
    color: #0a0e13;
    background-color: #bdbfc2;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
    color: #0a0e13;
    background-color: #b0b2b6;
}

.list-group-item-dark.list-group-item-action.active {
    color: #ffffff;
    background-color: #0a0e13;
    border-color: #0a0e13;
}

.list-group-item-black {
    color: black;
    background-color: #b8b8b8;
}

.list-group-item-black.list-group-item-action:hover,
.list-group-item-black.list-group-item-action:focus {
    color: black;
    background-color: #ababab;
}

.list-group-item-black.list-group-item-action.active {
    color: #ffffff;
    background-color: black;
    border-color: black;
}

.list-group-item-white {
    color: #858585;
    background-color: white;
}

.list-group-item-white.list-group-item-action:hover,
.list-group-item-white.list-group-item-action:focus {
    color: #858585;
    background-color: #f2f2f2;
}

.list-group-item-white.list-group-item-action.active {
    color: #ffffff;
    background-color: #858585;
    border-color: #858585;
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

.close:hover {
    color: #000;
    text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
    opacity: .75;
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

a.close.disabled {
    pointer-events: none;
}

.toast {
    max-width: 350px;
    overflow: hidden;
    font-size: 0.875rem;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    opacity: 0;
    border-radius: 0.25rem;
}

.toast:not(:last-child) {
    margin-bottom: 0.75rem;
}

.toast.showing {
    opacity: 1;
}

.toast.show {
    display: block;
    opacity: 1;
}

.toast.hide {
    display: none;
}

.toast-header {
    display: flex;
    align-items: center;
    padding: 0.25rem 0.75rem;
    color: #6c757d;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
    padding: 0.75rem;
}

.modal-open {
    overflow: hidden;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
}

.modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
        transition: none;
    }
}

.modal.show .modal-dialog {
    transform: none;
}

.modal.modal-static .modal-dialog {
    transform: scale(1.02);
}

.modal-dialog-scrollable {
    display: flex;
    max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
    flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto;
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
    max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
    content: none;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}

.modal-backdrop.fade {
    opacity: 0;
}

.modal-backdrop.show {
    opacity: 0.5;
}

.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
}

.modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer>* {
    margin: 0.25rem;
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }
    .modal-dialog-scrollable {
        max-height: calc(100% - 3.5rem);
    }
    .modal-dialog-scrollable .modal-content {
        max-height: calc(100vh - 3.5rem);
    }
    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem);
    }
    .modal-dialog-centered::before {
        height: calc(100vh - 3.5rem);
    }
    .modal-sm {
        max-width: 300px;
    }
}

@media (min-width: 992px) {
    .modal-lg,
    .modal-xl {
        max-width: 800px;
    }
}

@media (min-width: 1200px) {
    .modal-xl {
        max-width: 1140px;
    }
}

.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    opacity: 0;
}

.tooltip.show {
    opacity: 0.9;
}

.tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;
}

.tooltip .arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
    padding: 0.4rem 0;
}

.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    top: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #000;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
    padding: 0 0.4rem;
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
    padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    bottom: 0;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #000;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
    padding: 0 0.4rem;
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    left: 0;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #000;
}

.tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 0.25rem;
}

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
}

.popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;
}

.popover .arrow::before,
.popover .arrow::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
    margin-bottom: 0.5rem;
}

.bs-popover-top>.arrow,
.bs-popover-auto[x-placement^="top"]>.arrow {
    bottom: calc(-0.5rem - 1px);
}

.bs-popover-top>.arrow::before,
.bs-popover-auto[x-placement^="top"]>.arrow::before {
    bottom: 0;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top>.arrow::after,
.bs-popover-auto[x-placement^="top"]>.arrow::after {
    bottom: 1px;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #fff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
    margin-left: 0.5rem;
}

.bs-popover-right>.arrow,
.bs-popover-auto[x-placement^="right"]>.arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
}

.bs-popover-right>.arrow::before,
.bs-popover-auto[x-placement^="right"]>.arrow::before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right>.arrow::after,
.bs-popover-auto[x-placement^="right"]>.arrow::after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
    margin-top: 0.5rem;
}

.bs-popover-bottom>.arrow,
.bs-popover-auto[x-placement^="bottom"]>.arrow {
    top: calc(-0.5rem - 1px);
}

.bs-popover-bottom>.arrow::before,
.bs-popover-auto[x-placement^="bottom"]>.arrow::before {
    top: 0;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom>.arrow::after,
.bs-popover-auto[x-placement^="bottom"]>.arrow::after {
    top: 1px;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
    margin-right: 0.5rem;
}

.bs-popover-left>.arrow,
.bs-popover-auto[x-placement^="left"]>.arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
}

.bs-popover-left>.arrow::before,
.bs-popover-auto[x-placement^="left"]>.arrow::before {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left>.arrow::after,
.bs-popover-auto[x-placement^="left"]>.arrow::after {
    right: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #fff;
}

.popover-header {
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
    display: none;
}

.popover-body {
    padding: 0.5rem 0.75rem;
    color: #212529;
}

.carousel {
    position: relative;
}

.carousel.pointer-event {
    touch-action: pan-y;
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.carousel-inner::after {
    display: block;
    clear: both;
    content: "";
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-item {
        transition: none;
    }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
    display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
    transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
    transform: translateX(-100%);
}

.carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
    z-index: 1;
    opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
        transition: none;
    }
}

.carousel-control-prev,
.carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: 0.5;
    transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
        transition: none;
    }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
}

.carousel-control-prev {
    left: 0;
}

.carousel-control-next {
    right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
}

.carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-indicators li {
        transition: none;
    }
}

.carousel-indicators .active {
    opacity: 1;
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
}

@-webkit-keyframes spinner-border {
    to {
        transform: rotate(360deg);
    }
}

@keyframes spinner-border {
    to {
        transform: rotate(360deg);
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
    0% {
        transform: scale(0);
    }
    50% {
        opacity: 1;
    }
}

@keyframes spinner-grow {
    0% {
        transform: scale(0);
    }
    50% {
        opacity: 1;
    }
}

.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: spinner-grow .75s linear infinite;
    animation: spinner-grow .75s linear infinite;
}

.spinner-grow-sm {
    width: 1rem;
    height: 1rem;
}

.align-baseline {
    vertical-align: baseline !important;
}

.align-top {
    vertical-align: top !important;
}

.align-middle {
    vertical-align: middle !important;
}

.align-bottom {
    vertical-align: bottom !important;
}

.align-text-bottom {
    vertical-align: text-bottom !important;
}

.align-text-top {
    vertical-align: text-top !important;
}

.bg-primary {
    background-color: #0684fe !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
    background-color: #016ad0 !important;
}

.bg-secondary {
    background-color: #f5f6f8 !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
    background-color: #d7dbe3 !important;
}

.bg-success {
    background-color: #35ac80 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
    background-color: #298563 !important;
}

.bg-info {
    background-color: #5d3fff !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
    background-color: #320cff !important;
}

.bg-warning {
    background-color: #f7b731 !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
    background-color: #eca309 !important;
}

.bg-danger {
    background-color: #fe3f3e !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
    background-color: #fe0d0b !important;
}

.bg-light {
    background-color: #f8f9fa !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
    background-color: #dae0e5 !important;
}

.bg-dark {
    background-color: #131b24 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
    background-color: #010203 !important;
}

.bg-black {
    background-color: #000000 !important;
}

a.bg-black:hover,
a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
    background-color: black !important;
}

.bg-white {
    background-color: #ffffff !important;
}

a.bg-white:hover,
a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
    background-color: #e6e6e6 !important;
}

.bg-white {
    background-color: #ffffff !important;
}

.bg-transparent {
    background-color: transparent !important;
}

.border {
    border: 1px solid #e9ecf1 !important;
}

.border-top {
    border-top: 1px solid #e9ecf1 !important;
}

.border-right {
    border-right: 1px solid #e9ecf1 !important;
}

.border-bottom {
    border-bottom: 1px solid #e9ecf1 !important;
}

.border-left {
    border-left: 1px solid #e9ecf1 !important;
}

.border-0 {
    border: 0 !important;
}

.border-top-0 {
    border-top: 0 !important;
}

.border-right-0 {
    border-right: 0 !important;
}

.border-bottom-0 {
    border-bottom: 0 !important;
}

.border-left-0 {
    border-left: 0 !important;
}

.border-primary {
    border-color: #0684fe !important;
}

.border-secondary {
    border-color: #f5f6f8 !important;
}

.border-success {
    border-color: #35ac80 !important;
}

.border-info {
    border-color: #5d3fff !important;
}

.border-warning {
    border-color: #f7b731 !important;
}

.border-danger {
    border-color: #fe3f3e !important;
}

.border-light {
    border-color: #f8f9fa !important;
}

.border-dark {
    border-color: #131b24 !important;
}

.border-black {
    border-color: #000000 !important;
}

.border-white {
    border-color: #ffffff !important;
}

.border-white {
    border-color: #ffffff !important;
}

.rounded-sm {
    border-radius: 0.125rem !important;
}

.rounded {
    border-radius: 0.25rem !important;
}

.rounded-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
}

.rounded-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
    border-radius: 0.375rem !important;
}

.rounded-circle {
    border-radius: 50% !important;
}

.rounded-pill {
    border-radius: 50rem !important;
}

.rounded-0 {
    border-radius: 0 !important;
}

.clearfix::after {
    display: block;
    clear: both;
    content: "";
}

.d-none {
    display: none !important;
}

.d-inline {
    display: inline !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-block {
    display: block !important;
}

.d-table {
    display: table !important;
}

.d-table-row {
    display: table-row !important;
}

.d-table-cell {
    display: table-cell !important;
}

.d-flex {
    display: flex !important;
}

.d-inline-flex {
    display: inline-flex !important;
}

@media (min-width: 576px) {
    .d-sm-none {
        display: none !important;
    }
    .d-sm-inline {
        display: inline !important;
    }
    .d-sm-inline-block {
        display: inline-block !important;
    }
    .d-sm-block {
        display: block !important;
    }
    .d-sm-table {
        display: table !important;
    }
    .d-sm-table-row {
        display: table-row !important;
    }
    .d-sm-table-cell {
        display: table-cell !important;
    }
    .d-sm-flex {
        display: flex !important;
    }
    .d-sm-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 768px) {
    .d-md-none {
        display: none !important;
    }
    .d-md-inline {
        display: inline !important;
    }
    .d-md-inline-block {
        display: inline-block !important;
    }
    .d-md-block {
        display: block !important;
    }
    .d-md-table {
        display: table !important;
    }
    .d-md-table-row {
        display: table-row !important;
    }
    .d-md-table-cell {
        display: table-cell !important;
    }
    .d-md-flex {
        display: flex !important;
    }
    .d-md-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 992px) {
    .d-lg-none {
        display: none !important;
    }
    .d-lg-inline {
        display: inline !important;
    }
    .d-lg-inline-block {
        display: inline-block !important;
    }
    .d-lg-block {
        display: block !important;
    }
    .d-lg-table {
        display: table !important;
    }
    .d-lg-table-row {
        display: table-row !important;
    }
    .d-lg-table-cell {
        display: table-cell !important;
    }
    .d-lg-flex {
        display: flex !important;
    }
    .d-lg-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 1200px) {
    .d-xl-none {
        display: none !important;
    }
    .d-xl-inline {
        display: inline !important;
    }
    .d-xl-inline-block {
        display: inline-block !important;
    }
    .d-xl-block {
        display: block !important;
    }
    .d-xl-table {
        display: table !important;
    }
    .d-xl-table-row {
        display: table-row !important;
    }
    .d-xl-table-cell {
        display: table-cell !important;
    }
    .d-xl-flex {
        display: flex !important;
    }
    .d-xl-inline-flex {
        display: inline-flex !important;
    }
}

@media print {
    .d-print-none {
        display: none !important;
    }
    .d-print-inline {
        display: inline !important;
    }
    .d-print-inline-block {
        display: inline-block !important;
    }
    .d-print-block {
        display: block !important;
    }
    .d-print-table {
        display: table !important;
    }
    .d-print-table-row {
        display: table-row !important;
    }
    .d-print-table-cell {
        display: table-cell !important;
    }
    .d-print-flex {
        display: flex !important;
    }
    .d-print-inline-flex {
        display: inline-flex !important;
    }
}

.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
}

.embed-responsive::before {
    display: block;
    content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.embed-responsive-21by9::before {
    padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
    padding-top: 56.25%;
}

.embed-responsive-4by3::before {
    padding-top: 75%;
}

.embed-responsive-1by1::before {
    padding-top: 100%;
}

.embed-responsive-21by9::before {
    padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
    padding-top: 56.25%;
}

.embed-responsive-4by3::before {
    padding-top: 75%;
}

.embed-responsive-1by1::before {
    padding-top: 100%;
}

.flex-row {
    flex-direction: row !important;
}

.flex-column {
    flex-direction: column !important;
}

.flex-row-reverse {
    flex-direction: row-reverse !important;
}

.flex-column-reverse {
    flex-direction: column-reverse !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.flex-nowrap {
    flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
}

.flex-fill {
    flex: 1 1 auto !important;
}

.flex-grow-0 {
    flex-grow: 0 !important;
}

.flex-grow-1 {
    flex-grow: 1 !important;
}

.flex-shrink-0 {
    flex-shrink: 0 !important;
}

.flex-shrink-1 {
    flex-shrink: 1 !important;
}

.justify-content-start {
    justify-content: flex-start !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}

.justify-content-center {
    justify-content: center !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.justify-content-around {
    justify-content: space-around !important;
}

.align-items-start {
    align-items: flex-start !important;
}

.align-items-end {
    align-items: flex-end !important;
}

.align-items-center {
    align-items: center !important;
}

.align-items-baseline {
    align-items: baseline !important;
}

.align-items-stretch {
    align-items: stretch !important;
}

.align-content-start {
    align-content: flex-start !important;
}

.align-content-end {
    align-content: flex-end !important;
}

.align-content-center {
    align-content: center !important;
}

.align-content-between {
    align-content: space-between !important;
}

.align-content-around {
    align-content: space-around !important;
}

.align-content-stretch {
    align-content: stretch !important;
}

.align-self-auto {
    align-self: auto !important;
}

.align-self-start {
    align-self: flex-start !important;
}

.align-self-end {
    align-self: flex-end !important;
}

.align-self-center {
    align-self: center !important;
}

.align-self-baseline {
    align-self: baseline !important;
}

.align-self-stretch {
    align-self: stretch !important;
}

@media (min-width: 576px) {
    .flex-sm-row {
        flex-direction: row !important;
    }
    .flex-sm-column {
        flex-direction: column !important;
    }
    .flex-sm-row-reverse {
        flex-direction: row-reverse !important;
    }
    .flex-sm-column-reverse {
        flex-direction: column-reverse !important;
    }
    .flex-sm-wrap {
        flex-wrap: wrap !important;
    }
    .flex-sm-nowrap {
        flex-wrap: nowrap !important;
    }
    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .flex-sm-fill {
        flex: 1 1 auto !important;
    }
    .flex-sm-grow-0 {
        flex-grow: 0 !important;
    }
    .flex-sm-grow-1 {
        flex-grow: 1 !important;
    }
    .flex-sm-shrink-0 {
        flex-shrink: 0 !important;
    }
    .flex-sm-shrink-1 {
        flex-shrink: 1 !important;
    }
    .justify-content-sm-start {
        justify-content: flex-start !important;
    }
    .justify-content-sm-end {
        justify-content: flex-end !important;
    }
    .justify-content-sm-center {
        justify-content: center !important;
    }
    .justify-content-sm-between {
        justify-content: space-between !important;
    }
    .justify-content-sm-around {
        justify-content: space-around !important;
    }
    .align-items-sm-start {
        align-items: flex-start !important;
    }
    .align-items-sm-end {
        align-items: flex-end !important;
    }
    .align-items-sm-center {
        align-items: center !important;
    }
    .align-items-sm-baseline {
        align-items: baseline !important;
    }
    .align-items-sm-stretch {
        align-items: stretch !important;
    }
    .align-content-sm-start {
        align-content: flex-start !important;
    }
    .align-content-sm-end {
        align-content: flex-end !important;
    }
    .align-content-sm-center {
        align-content: center !important;
    }
    .align-content-sm-between {
        align-content: space-between !important;
    }
    .align-content-sm-around {
        align-content: space-around !important;
    }
    .align-content-sm-stretch {
        align-content: stretch !important;
    }
    .align-self-sm-auto {
        align-self: auto !important;
    }
    .align-self-sm-start {
        align-self: flex-start !important;
    }
    .align-self-sm-end {
        align-self: flex-end !important;
    }
    .align-self-sm-center {
        align-self: center !important;
    }
    .align-self-sm-baseline {
        align-self: baseline !important;
    }
    .align-self-sm-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 768px) {
    .flex-md-row {
        flex-direction: row !important;
    }
    .flex-md-column {
        flex-direction: column !important;
    }
    .flex-md-row-reverse {
        flex-direction: row-reverse !important;
    }
    .flex-md-column-reverse {
        flex-direction: column-reverse !important;
    }
    .flex-md-wrap {
        flex-wrap: wrap !important;
    }
    .flex-md-nowrap {
        flex-wrap: nowrap !important;
    }
    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .flex-md-fill {
        flex: 1 1 auto !important;
    }
    .flex-md-grow-0 {
        flex-grow: 0 !important;
    }
    .flex-md-grow-1 {
        flex-grow: 1 !important;
    }
    .flex-md-shrink-0 {
        flex-shrink: 0 !important;
    }
    .flex-md-shrink-1 {
        flex-shrink: 1 !important;
    }
    .justify-content-md-start {
        justify-content: flex-start !important;
    }
    .justify-content-md-end {
        justify-content: flex-end !important;
    }
    .justify-content-md-center {
        justify-content: center !important;
    }
    .justify-content-md-between {
        justify-content: space-between !important;
    }
    .justify-content-md-around {
        justify-content: space-around !important;
    }
    .align-items-md-start {
        align-items: flex-start !important;
    }
    .align-items-md-end {
        align-items: flex-end !important;
    }
    .align-items-md-center {
        align-items: center !important;
    }
    .align-items-md-baseline {
        align-items: baseline !important;
    }
    .align-items-md-stretch {
        align-items: stretch !important;
    }
    .align-content-md-start {
        align-content: flex-start !important;
    }
    .align-content-md-end {
        align-content: flex-end !important;
    }
    .align-content-md-center {
        align-content: center !important;
    }
    .align-content-md-between {
        align-content: space-between !important;
    }
    .align-content-md-around {
        align-content: space-around !important;
    }
    .align-content-md-stretch {
        align-content: stretch !important;
    }
    .align-self-md-auto {
        align-self: auto !important;
    }
    .align-self-md-start {
        align-self: flex-start !important;
    }
    .align-self-md-end {
        align-self: flex-end !important;
    }
    .align-self-md-center {
        align-self: center !important;
    }
    .align-self-md-baseline {
        align-self: baseline !important;
    }
    .align-self-md-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 992px) {
    .flex-lg-row {
        flex-direction: row !important;
    }
    .flex-lg-column {
        flex-direction: column !important;
    }
    .flex-lg-row-reverse {
        flex-direction: row-reverse !important;
    }
    .flex-lg-column-reverse {
        flex-direction: column-reverse !important;
    }
    .flex-lg-wrap {
        flex-wrap: wrap !important;
    }
    .flex-lg-nowrap {
        flex-wrap: nowrap !important;
    }
    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .flex-lg-fill {
        flex: 1 1 auto !important;
    }
    .flex-lg-grow-0 {
        flex-grow: 0 !important;
    }
    .flex-lg-grow-1 {
        flex-grow: 1 !important;
    }
    .flex-lg-shrink-0 {
        flex-shrink: 0 !important;
    }
    .flex-lg-shrink-1 {
        flex-shrink: 1 !important;
    }
    .justify-content-lg-start {
        justify-content: flex-start !important;
    }
    .justify-content-lg-end {
        justify-content: flex-end !important;
    }
    .justify-content-lg-center {
        justify-content: center !important;
    }
    .justify-content-lg-between {
        justify-content: space-between !important;
    }
    .justify-content-lg-around {
        justify-content: space-around !important;
    }
    .align-items-lg-start {
        align-items: flex-start !important;
    }
    .align-items-lg-end {
        align-items: flex-end !important;
    }
    .align-items-lg-center {
        align-items: center !important;
    }
    .align-items-lg-baseline {
        align-items: baseline !important;
    }
    .align-items-lg-stretch {
        align-items: stretch !important;
    }
    .align-content-lg-start {
        align-content: flex-start !important;
    }
    .align-content-lg-end {
        align-content: flex-end !important;
    }
    .align-content-lg-center {
        align-content: center !important;
    }
    .align-content-lg-between {
        align-content: space-between !important;
    }
    .align-content-lg-around {
        align-content: space-around !important;
    }
    .align-content-lg-stretch {
        align-content: stretch !important;
    }
    .align-self-lg-auto {
        align-self: auto !important;
    }
    .align-self-lg-start {
        align-self: flex-start !important;
    }
    .align-self-lg-end {
        align-self: flex-end !important;
    }
    .align-self-lg-center {
        align-self: center !important;
    }
    .align-self-lg-baseline {
        align-self: baseline !important;
    }
    .align-self-lg-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 1200px) {
    .flex-xl-row {
        flex-direction: row !important;
    }
    .flex-xl-column {
        flex-direction: column !important;
    }
    .flex-xl-row-reverse {
        flex-direction: row-reverse !important;
    }
    .flex-xl-column-reverse {
        flex-direction: column-reverse !important;
    }
    .flex-xl-wrap {
        flex-wrap: wrap !important;
    }
    .flex-xl-nowrap {
        flex-wrap: nowrap !important;
    }
    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .flex-xl-fill {
        flex: 1 1 auto !important;
    }
    .flex-xl-grow-0 {
        flex-grow: 0 !important;
    }
    .flex-xl-grow-1 {
        flex-grow: 1 !important;
    }
    .flex-xl-shrink-0 {
        flex-shrink: 0 !important;
    }
    .flex-xl-shrink-1 {
        flex-shrink: 1 !important;
    }
    .justify-content-xl-start {
        justify-content: flex-start !important;
    }
    .justify-content-xl-end {
        justify-content: flex-end !important;
    }
    .justify-content-xl-center {
        justify-content: center !important;
    }
    .justify-content-xl-between {
        justify-content: space-between !important;
    }
    .justify-content-xl-around {
        justify-content: space-around !important;
    }
    .align-items-xl-start {
        align-items: flex-start !important;
    }
    .align-items-xl-end {
        align-items: flex-end !important;
    }
    .align-items-xl-center {
        align-items: center !important;
    }
    .align-items-xl-baseline {
        align-items: baseline !important;
    }
    .align-items-xl-stretch {
        align-items: stretch !important;
    }
    .align-content-xl-start {
        align-content: flex-start !important;
    }
    .align-content-xl-end {
        align-content: flex-end !important;
    }
    .align-content-xl-center {
        align-content: center !important;
    }
    .align-content-xl-between {
        align-content: space-between !important;
    }
    .align-content-xl-around {
        align-content: space-around !important;
    }
    .align-content-xl-stretch {
        align-content: stretch !important;
    }
    .align-self-xl-auto {
        align-self: auto !important;
    }
    .align-self-xl-start {
        align-self: flex-start !important;
    }
    .align-self-xl-end {
        align-self: flex-end !important;
    }
    .align-self-xl-center {
        align-self: center !important;
    }
    .align-self-xl-baseline {
        align-self: baseline !important;
    }
    .align-self-xl-stretch {
        align-self: stretch !important;
    }
}

.float-left {
    float: left !important;
}

.float-right {
    float: right !important;
}

.float-none {
    float: none !important;
}

@media (min-width: 576px) {
    .float-sm-left {
        float: left !important;
    }
    .float-sm-right {
        float: right !important;
    }
    .float-sm-none {
        float: none !important;
    }
}

@media (min-width: 768px) {
    .float-md-left {
        float: left !important;
    }
    .float-md-right {
        float: right !important;
    }
    .float-md-none {
        float: none !important;
    }
}

@media (min-width: 992px) {
    .float-lg-left {
        float: left !important;
    }
    .float-lg-right {
        float: right !important;
    }
    .float-lg-none {
        float: none !important;
    }
}

@media (min-width: 1200px) {
    .float-xl-left {
        float: left !important;
    }
    .float-xl-right {
        float: right !important;
    }
    .float-xl-none {
        float: none !important;
    }
}

.overflow-auto {
    overflow: auto !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.position-static {
    position: static !important;
}

.position-relative {
    position: relative !important;
}

.position-absolute {
    position: absolute !important;
}

.position-fixed {
    position: fixed !important;
}

.position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
    .sticky-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020;
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
}

.shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow,
.form-card,
.dropdown-menu,
.nav-pills-card .nav-link {
    box-shadow: 0 2px 30px 0 rgba(207, 207, 207, 0.5) !important;
}

.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
    box-shadow: none !important;
}

.w-25 {
    width: 25% !important;
}

.w-50 {
    width: 50% !important;
}

.w-75 {
    width: 75% !important;
}

.w-100 {
    width: 100% !important;
}

.w-auto {
    width: auto !important;
}

.h-25 {
    height: 25% !important;
}

.h-50 {
    height: 50% !important;
}

.h-75 {
    height: 75% !important;
}

.h-100 {
    height: 100% !important;
}

.h-auto {
    height: auto !important;
}

.mw-100 {
    max-width: 100% !important;
}

.mh-100 {
    max-height: 100% !important;
}

.min-vw-100 {
    min-width: 100vw !important;
}

.min-vh-100 {
    min-height: 100vh !important;
}

.vw-100 {
    width: 100vw !important;
}

.vh-100 {
    height: 100vh !important;
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0, 0, 0, 0);
}

.m-0 {
    margin: 0 !important;
}

.mt-0,
.my-0 {
    margin-top: 0 !important;
}

.mr-0,
.mx-0 {
    margin-right: 0 !important;
}

.mb-0,
.my-0 {
    margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
    margin-left: 0 !important;
}

.m-1 {
    margin: 0.25rem !important;
}

.mt-1,
.my-1 {
    margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
    margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
    margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
    margin-left: 0.25rem !important;
}

.m-2 {
    margin: 0.5rem !important;
}

.mt-2,
.my-2 {
    margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
    margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
    margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
    margin-left: 0.5rem !important;
}

.m-3 {
    margin: 1rem !important;
}

.mt-3,
.my-3 {
    margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
    margin-right: 1rem !important;
}

.mb-3,
.my-3 {
    margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
    margin-left: 1rem !important;
}

.m-4 {
    margin: 1.5rem !important;
}

.mt-4,
.my-4 {
    margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
    margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
    margin-left: 1.5rem !important;
}

.m-5 {
    margin: 3rem !important;
}

.mt-5,
.my-5 {
    margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
    margin-right: 3rem !important;
}

.mb-5,
.my-5 {
    margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
    margin-left: 3rem !important;
}

.m-20px {
    margin: 20px !important;
}

.mt-20px,
.my-20px {
    margin-top: 20px !important;
}

.mr-20px,
.mx-20px {
    margin-right: 20px !important;
}

.mb-20px,
.my-20px {
    margin-bottom: 20px !important;
}

.ml-20px,
.mx-20px {
    margin-left: 20px !important;
}

.m-30px {
    margin: 30px !important;
}

.mt-30px,
.my-30px {
    margin-top: 30px !important;
}

.mr-30px,
.mx-30px {
    margin-right: 30px !important;
}

.mb-30px,
.my-30px {
    margin-bottom: 30px !important;
}

.ml-30px,
.mx-30px {
    margin-left: 30px !important;
}

.m-35px {
    margin: 35px !important;
}

.mt-35px,
.my-35px {
    margin-top: 35px !important;
}

.mr-35px,
.mx-35px {
    margin-right: 35px !important;
}

.mb-35px,
.my-35px {
    margin-bottom: 35px !important;
}

.ml-35px,
.mx-35px {
    margin-left: 35px !important;
}

.m-40px {
    margin: 40px !important;
}

.mt-40px,
.my-40px {
    margin-top: 40px !important;
}

.mr-40px,
.mx-40px {
    margin-right: 40px !important;
}

.mb-40px,
.my-40px {
    margin-bottom: 40px !important;
}

.ml-40px,
.mx-40px {
    margin-left: 40px !important;
}

.p-0 {
    padding: 0 !important;
}

.pt-0,
.py-0 {
    padding-top: 0 !important;
}

.pr-0,
.px-0 {
    padding-right: 0 !important;
}

.pb-0,
.py-0 {
    padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
    padding-left: 0 !important;
}

.p-1 {
    padding: 0.25rem !important;
}

.pt-1,
.py-1 {
    padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
    padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
    padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
    padding-left: 0.25rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.pt-2,
.py-2 {
    padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
    padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
    padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
    padding-left: 0.5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.pt-3,
.py-3 {
    padding-top: 1rem !important;
}

.pr-3,
.px-3 {
    padding-right: 1rem !important;
}

.pb-3,
.py-3 {
    padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
    padding-left: 1rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}

.pt-4,
.py-4 {
    padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
    padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
    padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
    padding-left: 1.5rem !important;
}

.p-5 {
    padding: 3rem !important;
}

.pt-5,
.py-5 {
    padding-top: 3rem !important;
}

.pr-5,
.px-5 {
    padding-right: 3rem !important;
}

.pb-5,
.py-5 {
    padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
    padding-left: 3rem !important;
}

.p-20px {
    padding: 20px !important;
}

.pt-20px,
.py-20px {
    padding-top: 20px !important;
}

.pr-20px,
.px-20px {
    padding-right: 20px !important;
}

.pb-20px,
.py-20px {
    padding-bottom: 20px !important;
}

.pl-20px,
.px-20px {
    padding-left: 20px !important;
}

.p-30px {
    padding: 30px !important;
}

.pt-30px,
.py-30px {
    padding-top: 30px !important;
}

.pr-30px,
.px-30px {
    padding-right: 30px !important;
}

.pb-30px,
.py-30px {
    padding-bottom: 30px !important;
}

.pl-30px,
.px-30px {
    padding-left: 30px !important;
}

.p-35px {
    padding: 35px !important;
}

.pt-35px,
.py-35px {
    padding-top: 35px !important;
}

.pr-35px,
.px-35px {
    padding-right: 35px !important;
}

.pb-35px,
.py-35px {
    padding-bottom: 35px !important;
}

.pl-35px,
.px-35px {
    padding-left: 35px !important;
}

.p-40px {
    padding: 40px !important;
}

.pt-40px,
.py-40px {
    padding-top: 40px !important;
}

.pr-40px,
.px-40px {
    padding-right: 40px !important;
}

.pb-40px,
.py-40px {
    padding-bottom: 40px !important;
}

.pl-40px,
.px-40px {
    padding-left: 40px !important;
}

.m-n1 {
    margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
    margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
    margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
    margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
    margin-left: -0.25rem !important;
}

.m-n2 {
    margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
    margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
    margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
    margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
    margin-left: -0.5rem !important;
}

.m-n3 {
    margin: -1rem !important;
}

.mt-n3,
.my-n3 {
    margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
    margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
    margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
    margin-left: -1rem !important;
}

.m-n4 {
    margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
    margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
    margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
    margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
    margin-left: -1.5rem !important;
}

.m-n5 {
    margin: -3rem !important;
}

.mt-n5,
.my-n5 {
    margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
    margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
    margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
    margin-left: -3rem !important;
}

.m-n20px {
    margin: -20px !important;
}

.mt-n20px,
.my-n20px {
    margin-top: -20px !important;
}

.mr-n20px,
.mx-n20px {
    margin-right: -20px !important;
}

.mb-n20px,
.my-n20px {
    margin-bottom: -20px !important;
}

.ml-n20px,
.mx-n20px {
    margin-left: -20px !important;
}

.m-n30px {
    margin: -30px !important;
}

.mt-n30px,
.my-n30px {
    margin-top: -30px !important;
}

.mr-n30px,
.mx-n30px {
    margin-right: -30px !important;
}

.mb-n30px,
.my-n30px {
    margin-bottom: -30px !important;
}

.ml-n30px,
.mx-n30px {
    margin-left: -30px !important;
}

.m-n35px {
    margin: -35px !important;
}

.mt-n35px,
.my-n35px {
    margin-top: -35px !important;
}

.mr-n35px,
.mx-n35px {
    margin-right: -35px !important;
}

.mb-n35px,
.my-n35px {
    margin-bottom: -35px !important;
}

.ml-n35px,
.mx-n35px {
    margin-left: -35px !important;
}

.m-n40px {
    margin: -40px !important;
}

.mt-n40px,
.my-n40px {
    margin-top: -40px !important;
}

.mr-n40px,
.mx-n40px {
    margin-right: -40px !important;
}

.mb-n40px,
.my-n40px {
    margin-bottom: -40px !important;
}

.ml-n40px,
.mx-n40px {
    margin-left: -40px !important;
}

.m-auto {
    margin: auto !important;
}

.mt-auto,
.my-auto {
    margin-top: auto !important;
}

.mr-auto,
.mx-auto {
    margin-right: auto !important;
}

.mb-auto,
.my-auto {
    margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
    margin-left: auto !important;
}

@media (min-width: 576px) {
    .m-sm-0 {
        margin: 0 !important;
    }
    .mt-sm-0,
    .my-sm-0 {
        margin-top: 0 !important;
    }
    .mr-sm-0,
    .mx-sm-0 {
        margin-right: 0 !important;
    }
    .mb-sm-0,
    .my-sm-0 {
        margin-bottom: 0 !important;
    }
    .ml-sm-0,
    .mx-sm-0 {
        margin-left: 0 !important;
    }
    .m-sm-1 {
        margin: 0.25rem !important;
    }
    .mt-sm-1,
    .my-sm-1 {
        margin-top: 0.25rem !important;
    }
    .mr-sm-1,
    .mx-sm-1 {
        margin-right: 0.25rem !important;
    }
    .mb-sm-1,
    .my-sm-1 {
        margin-bottom: 0.25rem !important;
    }
    .ml-sm-1,
    .mx-sm-1 {
        margin-left: 0.25rem !important;
    }
    .m-sm-2 {
        margin: 0.5rem !important;
    }
    .mt-sm-2,
    .my-sm-2 {
        margin-top: 0.5rem !important;
    }
    .mr-sm-2,
    .mx-sm-2 {
        margin-right: 0.5rem !important;
    }
    .mb-sm-2,
    .my-sm-2 {
        margin-bottom: 0.5rem !important;
    }
    .ml-sm-2,
    .mx-sm-2 {
        margin-left: 0.5rem !important;
    }
    .m-sm-3 {
        margin: 1rem !important;
    }
    .mt-sm-3,
    .my-sm-3 {
        margin-top: 1rem !important;
    }
    .mr-sm-3,
    .mx-sm-3 {
        margin-right: 1rem !important;
    }
    .mb-sm-3,
    .my-sm-3 {
        margin-bottom: 1rem !important;
    }
    .ml-sm-3,
    .mx-sm-3 {
        margin-left: 1rem !important;
    }
    .m-sm-4 {
        margin: 1.5rem !important;
    }
    .mt-sm-4,
    .my-sm-4 {
        margin-top: 1.5rem !important;
    }
    .mr-sm-4,
    .mx-sm-4 {
        margin-right: 1.5rem !important;
    }
    .mb-sm-4,
    .my-sm-4 {
        margin-bottom: 1.5rem !important;
    }
    .ml-sm-4,
    .mx-sm-4 {
        margin-left: 1.5rem !important;
    }
    .m-sm-5 {
        margin: 3rem !important;
    }
    .mt-sm-5,
    .my-sm-5 {
        margin-top: 3rem !important;
    }
    .mr-sm-5,
    .mx-sm-5 {
        margin-right: 3rem !important;
    }
    .mb-sm-5,
    .my-sm-5 {
        margin-bottom: 3rem !important;
    }
    .ml-sm-5,
    .mx-sm-5 {
        margin-left: 3rem !important;
    }
    .m-sm-20px {
        margin: 20px !important;
    }
    .mt-sm-20px,
    .my-sm-20px {
        margin-top: 20px !important;
    }
    .mr-sm-20px,
    .mx-sm-20px {
        margin-right: 20px !important;
    }
    .mb-sm-20px,
    .my-sm-20px {
        margin-bottom: 20px !important;
    }
    .ml-sm-20px,
    .mx-sm-20px {
        margin-left: 20px !important;
    }
    .m-sm-30px {
        margin: 30px !important;
    }
    .mt-sm-30px,
    .my-sm-30px {
        margin-top: 30px !important;
    }
    .mr-sm-30px,
    .mx-sm-30px {
        margin-right: 30px !important;
    }
    .mb-sm-30px,
    .my-sm-30px {
        margin-bottom: 30px !important;
    }
    .ml-sm-30px,
    .mx-sm-30px {
        margin-left: 30px !important;
    }
    .m-sm-35px {
        margin: 35px !important;
    }
    .mt-sm-35px,
    .my-sm-35px {
        margin-top: 35px !important;
    }
    .mr-sm-35px,
    .mx-sm-35px {
        margin-right: 35px !important;
    }
    .mb-sm-35px,
    .my-sm-35px {
        margin-bottom: 35px !important;
    }
    .ml-sm-35px,
    .mx-sm-35px {
        margin-left: 35px !important;
    }
    .m-sm-40px {
        margin: 40px !important;
    }
    .mt-sm-40px,
    .my-sm-40px {
        margin-top: 40px !important;
    }
    .mr-sm-40px,
    .mx-sm-40px {
        margin-right: 40px !important;
    }
    .mb-sm-40px,
    .my-sm-40px {
        margin-bottom: 40px !important;
    }
    .ml-sm-40px,
    .mx-sm-40px {
        margin-left: 40px !important;
    }
    .p-sm-0 {
        padding: 0 !important;
    }
    .pt-sm-0,
    .py-sm-0 {
        padding-top: 0 !important;
    }
    .pr-sm-0,
    .px-sm-0 {
        padding-right: 0 !important;
    }
    .pb-sm-0,
    .py-sm-0 {
        padding-bottom: 0 !important;
    }
    .pl-sm-0,
    .px-sm-0 {
        padding-left: 0 !important;
    }
    .p-sm-1 {
        padding: 0.25rem !important;
    }
    .pt-sm-1,
    .py-sm-1 {
        padding-top: 0.25rem !important;
    }
    .pr-sm-1,
    .px-sm-1 {
        padding-right: 0.25rem !important;
    }
    .pb-sm-1,
    .py-sm-1 {
        padding-bottom: 0.25rem !important;
    }
    .pl-sm-1,
    .px-sm-1 {
        padding-left: 0.25rem !important;
    }
    .p-sm-2 {
        padding: 0.5rem !important;
    }
    .pt-sm-2,
    .py-sm-2 {
        padding-top: 0.5rem !important;
    }
    .pr-sm-2,
    .px-sm-2 {
        padding-right: 0.5rem !important;
    }
    .pb-sm-2,
    .py-sm-2 {
        padding-bottom: 0.5rem !important;
    }
    .pl-sm-2,
    .px-sm-2 {
        padding-left: 0.5rem !important;
    }
    .p-sm-3 {
        padding: 1rem !important;
    }
    .pt-sm-3,
    .py-sm-3 {
        padding-top: 1rem !important;
    }
    .pr-sm-3,
    .px-sm-3 {
        padding-right: 1rem !important;
    }
    .pb-sm-3,
    .py-sm-3 {
        padding-bottom: 1rem !important;
    }
    .pl-sm-3,
    .px-sm-3 {
        padding-left: 1rem !important;
    }
    .p-sm-4 {
        padding: 1.5rem !important;
    }
    .pt-sm-4,
    .py-sm-4 {
        padding-top: 1.5rem !important;
    }
    .pr-sm-4,
    .px-sm-4 {
        padding-right: 1.5rem !important;
    }
    .pb-sm-4,
    .py-sm-4 {
        padding-bottom: 1.5rem !important;
    }
    .pl-sm-4,
    .px-sm-4 {
        padding-left: 1.5rem !important;
    }
    .p-sm-5 {
        padding: 3rem !important;
    }
    .pt-sm-5,
    .py-sm-5 {
        padding-top: 3rem !important;
    }
    .pr-sm-5,
    .px-sm-5 {
        padding-right: 3rem !important;
    }
    .pb-sm-5,
    .py-sm-5 {
        padding-bottom: 3rem !important;
    }
    .pl-sm-5,
    .px-sm-5 {
        padding-left: 3rem !important;
    }
    .p-sm-20px {
        padding: 20px !important;
    }
    .pt-sm-20px,
    .py-sm-20px {
        padding-top: 20px !important;
    }
    .pr-sm-20px,
    .px-sm-20px {
        padding-right: 20px !important;
    }
    .pb-sm-20px,
    .py-sm-20px {
        padding-bottom: 20px !important;
    }
    .pl-sm-20px,
    .px-sm-20px {
        padding-left: 20px !important;
    }
    .p-sm-30px {
        padding: 30px !important;
    }
    .pt-sm-30px,
    .py-sm-30px {
        padding-top: 30px !important;
    }
    .pr-sm-30px,
    .px-sm-30px {
        padding-right: 30px !important;
    }
    .pb-sm-30px,
    .py-sm-30px {
        padding-bottom: 30px !important;
    }
    .pl-sm-30px,
    .px-sm-30px {
        padding-left: 30px !important;
    }
    .p-sm-35px {
        padding: 35px !important;
    }
    .pt-sm-35px,
    .py-sm-35px {
        padding-top: 35px !important;
    }
    .pr-sm-35px,
    .px-sm-35px {
        padding-right: 35px !important;
    }
    .pb-sm-35px,
    .py-sm-35px {
        padding-bottom: 35px !important;
    }
    .pl-sm-35px,
    .px-sm-35px {
        padding-left: 35px !important;
    }
    .p-sm-40px {
        padding: 40px !important;
    }
    .pt-sm-40px,
    .py-sm-40px {
        padding-top: 40px !important;
    }
    .pr-sm-40px,
    .px-sm-40px {
        padding-right: 40px !important;
    }
    .pb-sm-40px,
    .py-sm-40px {
        padding-bottom: 40px !important;
    }
    .pl-sm-40px,
    .px-sm-40px {
        padding-left: 40px !important;
    }
    .m-sm-n1 {
        margin: -0.25rem !important;
    }
    .mt-sm-n1,
    .my-sm-n1 {
        margin-top: -0.25rem !important;
    }
    .mr-sm-n1,
    .mx-sm-n1 {
        margin-right: -0.25rem !important;
    }
    .mb-sm-n1,
    .my-sm-n1 {
        margin-bottom: -0.25rem !important;
    }
    .ml-sm-n1,
    .mx-sm-n1 {
        margin-left: -0.25rem !important;
    }
    .m-sm-n2 {
        margin: -0.5rem !important;
    }
    .mt-sm-n2,
    .my-sm-n2 {
        margin-top: -0.5rem !important;
    }
    .mr-sm-n2,
    .mx-sm-n2 {
        margin-right: -0.5rem !important;
    }
    .mb-sm-n2,
    .my-sm-n2 {
        margin-bottom: -0.5rem !important;
    }
    .ml-sm-n2,
    .mx-sm-n2 {
        margin-left: -0.5rem !important;
    }
    .m-sm-n3 {
        margin: -1rem !important;
    }
    .mt-sm-n3,
    .my-sm-n3 {
        margin-top: -1rem !important;
    }
    .mr-sm-n3,
    .mx-sm-n3 {
        margin-right: -1rem !important;
    }
    .mb-sm-n3,
    .my-sm-n3 {
        margin-bottom: -1rem !important;
    }
    .ml-sm-n3,
    .mx-sm-n3 {
        margin-left: -1rem !important;
    }
    .m-sm-n4 {
        margin: -1.5rem !important;
    }
    .mt-sm-n4,
    .my-sm-n4 {
        margin-top: -1.5rem !important;
    }
    .mr-sm-n4,
    .mx-sm-n4 {
        margin-right: -1.5rem !important;
    }
    .mb-sm-n4,
    .my-sm-n4 {
        margin-bottom: -1.5rem !important;
    }
    .ml-sm-n4,
    .mx-sm-n4 {
        margin-left: -1.5rem !important;
    }
    .m-sm-n5 {
        margin: -3rem !important;
    }
    .mt-sm-n5,
    .my-sm-n5 {
        margin-top: -3rem !important;
    }
    .mr-sm-n5,
    .mx-sm-n5 {
        margin-right: -3rem !important;
    }
    .mb-sm-n5,
    .my-sm-n5 {
        margin-bottom: -3rem !important;
    }
    .ml-sm-n5,
    .mx-sm-n5 {
        margin-left: -3rem !important;
    }
    .m-sm-n20px {
        margin: -20px !important;
    }
    .mt-sm-n20px,
    .my-sm-n20px {
        margin-top: -20px !important;
    }
    .mr-sm-n20px,
    .mx-sm-n20px {
        margin-right: -20px !important;
    }
    .mb-sm-n20px,
    .my-sm-n20px {
        margin-bottom: -20px !important;
    }
    .ml-sm-n20px,
    .mx-sm-n20px {
        margin-left: -20px !important;
    }
    .m-sm-n30px {
        margin: -30px !important;
    }
    .mt-sm-n30px,
    .my-sm-n30px {
        margin-top: -30px !important;
    }
    .mr-sm-n30px,
    .mx-sm-n30px {
        margin-right: -30px !important;
    }
    .mb-sm-n30px,
    .my-sm-n30px {
        margin-bottom: -30px !important;
    }
    .ml-sm-n30px,
    .mx-sm-n30px {
        margin-left: -30px !important;
    }
    .m-sm-n35px {
        margin: -35px !important;
    }
    .mt-sm-n35px,
    .my-sm-n35px {
        margin-top: -35px !important;
    }
    .mr-sm-n35px,
    .mx-sm-n35px {
        margin-right: -35px !important;
    }
    .mb-sm-n35px,
    .my-sm-n35px {
        margin-bottom: -35px !important;
    }
    .ml-sm-n35px,
    .mx-sm-n35px {
        margin-left: -35px !important;
    }
    .m-sm-n40px {
        margin: -40px !important;
    }
    .mt-sm-n40px,
    .my-sm-n40px {
        margin-top: -40px !important;
    }
    .mr-sm-n40px,
    .mx-sm-n40px {
        margin-right: -40px !important;
    }
    .mb-sm-n40px,
    .my-sm-n40px {
        margin-bottom: -40px !important;
    }
    .ml-sm-n40px,
    .mx-sm-n40px {
        margin-left: -40px !important;
    }
    .m-sm-auto {
        margin: auto !important;
    }
    .mt-sm-auto,
    .my-sm-auto {
        margin-top: auto !important;
    }
    .mr-sm-auto,
    .mx-sm-auto {
        margin-right: auto !important;
    }
    .mb-sm-auto,
    .my-sm-auto {
        margin-bottom: auto !important;
    }
    .ml-sm-auto,
    .mx-sm-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 768px) {
    .m-md-0 {
        margin: 0 !important;
    }
    .mt-md-0,
    .my-md-0 {
        margin-top: 0 !important;
    }
    .mr-md-0,
    .mx-md-0 {
        margin-right: 0 !important;
    }
    .mb-md-0,
    .my-md-0 {
        margin-bottom: 0 !important;
    }
    .ml-md-0,
    .mx-md-0 {
        margin-left: 0 !important;
    }
    .m-md-1 {
        margin: 0.25rem !important;
    }
    .mt-md-1,
    .my-md-1 {
        margin-top: 0.25rem !important;
    }
    .mr-md-1,
    .mx-md-1 {
        margin-right: 0.25rem !important;
    }
    .mb-md-1,
    .my-md-1 {
        margin-bottom: 0.25rem !important;
    }
    .ml-md-1,
    .mx-md-1 {
        margin-left: 0.25rem !important;
    }
    .m-md-2 {
        margin: 0.5rem !important;
    }
    .mt-md-2,
    .my-md-2 {
        margin-top: 0.5rem !important;
    }
    .mr-md-2,
    .mx-md-2 {
        margin-right: 0.5rem !important;
    }
    .mb-md-2,
    .my-md-2 {
        margin-bottom: 0.5rem !important;
    }
    .ml-md-2,
    .mx-md-2 {
        margin-left: 0.5rem !important;
    }
    .m-md-3 {
        margin: 1rem !important;
    }
    .mt-md-3,
    .my-md-3 {
        margin-top: 1rem !important;
    }
    .mr-md-3,
    .mx-md-3 {
        margin-right: 1rem !important;
    }
    .mb-md-3,
    .my-md-3 {
        margin-bottom: 1rem !important;
    }
    .ml-md-3,
    .mx-md-3 {
        margin-left: 1rem !important;
    }
    .m-md-4 {
        margin: 1.5rem !important;
    }
    .mt-md-4,
    .my-md-4 {
        margin-top: 1.5rem !important;
    }
    .mr-md-4,
    .mx-md-4 {
        margin-right: 1.5rem !important;
    }
    .mb-md-4,
    .my-md-4 {
        margin-bottom: 1.5rem !important;
    }
    .ml-md-4,
    .mx-md-4 {
        margin-left: 1.5rem !important;
    }
    .m-md-5 {
        margin: 3rem !important;
    }
    .mt-md-5,
    .my-md-5 {
        margin-top: 3rem !important;
    }
    .mr-md-5,
    .mx-md-5 {
        margin-right: 3rem !important;
    }
    .mb-md-5,
    .my-md-5 {
        margin-bottom: 3rem !important;
    }
    .ml-md-5,
    .mx-md-5 {
        margin-left: 3rem !important;
    }
    .m-md-20px {
        margin: 20px !important;
    }
    .mt-md-20px,
    .my-md-20px {
        margin-top: 20px !important;
    }
    .mr-md-20px,
    .mx-md-20px {
        margin-right: 20px !important;
    }
    .mb-md-20px,
    .my-md-20px {
        margin-bottom: 20px !important;
    }
    .ml-md-20px,
    .mx-md-20px {
        margin-left: 20px !important;
    }
    .m-md-30px {
        margin: 30px !important;
    }
    .mt-md-30px,
    .my-md-30px {
        margin-top: 30px !important;
    }
    .mr-md-30px,
    .mx-md-30px {
        margin-right: 30px !important;
    }
    .mb-md-30px,
    .my-md-30px {
        margin-bottom: 30px !important;
    }
    .ml-md-30px,
    .mx-md-30px {
        margin-left: 30px !important;
    }
    .m-md-35px {
        margin: 35px !important;
    }
    .mt-md-35px,
    .my-md-35px {
        margin-top: 35px !important;
    }
    .mr-md-35px,
    .mx-md-35px {
        margin-right: 35px !important;
    }
    .mb-md-35px,
    .my-md-35px {
        margin-bottom: 35px !important;
    }
    .ml-md-35px,
    .mx-md-35px {
        margin-left: 35px !important;
    }
    .m-md-40px {
        margin: 40px !important;
    }
    .mt-md-40px,
    .my-md-40px {
        margin-top: 40px !important;
    }
    .mr-md-40px,
    .mx-md-40px {
        margin-right: 40px !important;
    }
    .mb-md-40px,
    .my-md-40px {
        margin-bottom: 40px !important;
    }
    .ml-md-40px,
    .mx-md-40px {
        margin-left: 40px !important;
    }
    .p-md-0 {
        padding: 0 !important;
    }
    .pt-md-0,
    .py-md-0 {
        padding-top: 0 !important;
    }
    .pr-md-0,
    .px-md-0 {
        padding-right: 0 !important;
    }
    .pb-md-0,
    .py-md-0 {
        padding-bottom: 0 !important;
    }
    .pl-md-0,
    .px-md-0 {
        padding-left: 0 !important;
    }
    .p-md-1 {
        padding: 0.25rem !important;
    }
    .pt-md-1,
    .py-md-1 {
        padding-top: 0.25rem !important;
    }
    .pr-md-1,
    .px-md-1 {
        padding-right: 0.25rem !important;
    }
    .pb-md-1,
    .py-md-1 {
        padding-bottom: 0.25rem !important;
    }
    .pl-md-1,
    .px-md-1 {
        padding-left: 0.25rem !important;
    }
    .p-md-2 {
        padding: 0.5rem !important;
    }
    .pt-md-2,
    .py-md-2 {
        padding-top: 0.5rem !important;
    }
    .pr-md-2,
    .px-md-2 {
        padding-right: 0.5rem !important;
    }
    .pb-md-2,
    .py-md-2 {
        padding-bottom: 0.5rem !important;
    }
    .pl-md-2,
    .px-md-2 {
        padding-left: 0.5rem !important;
    }
    .p-md-3 {
        padding: 1rem !important;
    }
    .pt-md-3,
    .py-md-3 {
        padding-top: 1rem !important;
    }
    .pr-md-3,
    .px-md-3 {
        padding-right: 1rem !important;
    }
    .pb-md-3,
    .py-md-3 {
        padding-bottom: 1rem !important;
    }
    .pl-md-3,
    .px-md-3 {
        padding-left: 1rem !important;
    }
    .p-md-4 {
        padding: 1.5rem !important;
    }
    .pt-md-4,
    .py-md-4 {
        padding-top: 1.5rem !important;
    }
    .pr-md-4,
    .px-md-4 {
        padding-right: 1.5rem !important;
    }
    .pb-md-4,
    .py-md-4 {
        padding-bottom: 1.5rem !important;
    }
    .pl-md-4,
    .px-md-4 {
        padding-left: 1.5rem !important;
    }
    .p-md-5 {
        padding: 3rem !important;
    }
    .pt-md-5,
    .py-md-5 {
        padding-top: 3rem !important;
    }
    .pr-md-5,
    .px-md-5 {
        padding-right: 3rem !important;
    }
    .pb-md-5,
    .py-md-5 {
        padding-bottom: 3rem !important;
    }
    .pl-md-5,
    .px-md-5 {
        padding-left: 3rem !important;
    }
    .p-md-20px {
        padding: 20px !important;
    }
    .pt-md-20px,
    .py-md-20px {
        padding-top: 20px !important;
    }
    .pr-md-20px,
    .px-md-20px {
        padding-right: 20px !important;
    }
    .pb-md-20px,
    .py-md-20px {
        padding-bottom: 20px !important;
    }
    .pl-md-20px,
    .px-md-20px {
        padding-left: 20px !important;
    }
    .p-md-30px {
        padding: 30px !important;
    }
    .pt-md-30px,
    .py-md-30px {
        padding-top: 30px !important;
    }
    .pr-md-30px,
    .px-md-30px {
        padding-right: 30px !important;
    }
    .pb-md-30px,
    .py-md-30px {
        padding-bottom: 30px !important;
    }
    .pl-md-30px,
    .px-md-30px {
        padding-left: 30px !important;
    }
    .p-md-35px {
        padding: 35px !important;
    }
    .pt-md-35px,
    .py-md-35px {
        padding-top: 35px !important;
    }
    .pr-md-35px,
    .px-md-35px {
        padding-right: 35px !important;
    }
    .pb-md-35px,
    .py-md-35px {
        padding-bottom: 35px !important;
    }
    .pl-md-35px,
    .px-md-35px {
        padding-left: 35px !important;
    }
    .p-md-40px {
        padding: 40px !important;
    }
    .pt-md-40px,
    .py-md-40px {
        padding-top: 40px !important;
    }
    .pr-md-40px,
    .px-md-40px {
        padding-right: 40px !important;
    }
    .pb-md-40px,
    .py-md-40px {
        padding-bottom: 40px !important;
    }
    .pl-md-40px,
    .px-md-40px {
        padding-left: 40px !important;
    }
    .m-md-n1 {
        margin: -0.25rem !important;
    }
    .mt-md-n1,
    .my-md-n1 {
        margin-top: -0.25rem !important;
    }
    .mr-md-n1,
    .mx-md-n1 {
        margin-right: -0.25rem !important;
    }
    .mb-md-n1,
    .my-md-n1 {
        margin-bottom: -0.25rem !important;
    }
    .ml-md-n1,
    .mx-md-n1 {
        margin-left: -0.25rem !important;
    }
    .m-md-n2 {
        margin: -0.5rem !important;
    }
    .mt-md-n2,
    .my-md-n2 {
        margin-top: -0.5rem !important;
    }
    .mr-md-n2,
    .mx-md-n2 {
        margin-right: -0.5rem !important;
    }
    .mb-md-n2,
    .my-md-n2 {
        margin-bottom: -0.5rem !important;
    }
    .ml-md-n2,
    .mx-md-n2 {
        margin-left: -0.5rem !important;
    }
    .m-md-n3 {
        margin: -1rem !important;
    }
    .mt-md-n3,
    .my-md-n3 {
        margin-top: -1rem !important;
    }
    .mr-md-n3,
    .mx-md-n3 {
        margin-right: -1rem !important;
    }
    .mb-md-n3,
    .my-md-n3 {
        margin-bottom: -1rem !important;
    }
    .ml-md-n3,
    .mx-md-n3 {
        margin-left: -1rem !important;
    }
    .m-md-n4 {
        margin: -1.5rem !important;
    }
    .mt-md-n4,
    .my-md-n4 {
        margin-top: -1.5rem !important;
    }
    .mr-md-n4,
    .mx-md-n4 {
        margin-right: -1.5rem !important;
    }
    .mb-md-n4,
    .my-md-n4 {
        margin-bottom: -1.5rem !important;
    }
    .ml-md-n4,
    .mx-md-n4 {
        margin-left: -1.5rem !important;
    }
    .m-md-n5 {
        margin: -3rem !important;
    }
    .mt-md-n5,
    .my-md-n5 {
        margin-top: -3rem !important;
    }
    .mr-md-n5,
    .mx-md-n5 {
        margin-right: -3rem !important;
    }
    .mb-md-n5,
    .my-md-n5 {
        margin-bottom: -3rem !important;
    }
    .ml-md-n5,
    .mx-md-n5 {
        margin-left: -3rem !important;
    }
    .m-md-n20px {
        margin: -20px !important;
    }
    .mt-md-n20px,
    .my-md-n20px {
        margin-top: -20px !important;
    }
    .mr-md-n20px,
    .mx-md-n20px {
        margin-right: -20px !important;
    }
    .mb-md-n20px,
    .my-md-n20px {
        margin-bottom: -20px !important;
    }
    .ml-md-n20px,
    .mx-md-n20px {
        margin-left: -20px !important;
    }
    .m-md-n30px {
        margin: -30px !important;
    }
    .mt-md-n30px,
    .my-md-n30px {
        margin-top: -30px !important;
    }
    .mr-md-n30px,
    .mx-md-n30px {
        margin-right: -30px !important;
    }
    .mb-md-n30px,
    .my-md-n30px {
        margin-bottom: -30px !important;
    }
    .ml-md-n30px,
    .mx-md-n30px {
        margin-left: -30px !important;
    }
    .m-md-n35px {
        margin: -35px !important;
    }
    .mt-md-n35px,
    .my-md-n35px {
        margin-top: -35px !important;
    }
    .mr-md-n35px,
    .mx-md-n35px {
        margin-right: -35px !important;
    }
    .mb-md-n35px,
    .my-md-n35px {
        margin-bottom: -35px !important;
    }
    .ml-md-n35px,
    .mx-md-n35px {
        margin-left: -35px !important;
    }
    .m-md-n40px {
        margin: -40px !important;
    }
    .mt-md-n40px,
    .my-md-n40px {
        margin-top: -40px !important;
    }
    .mr-md-n40px,
    .mx-md-n40px {
        margin-right: -40px !important;
    }
    .mb-md-n40px,
    .my-md-n40px {
        margin-bottom: -40px !important;
    }
    .ml-md-n40px,
    .mx-md-n40px {
        margin-left: -40px !important;
    }
    .m-md-auto {
        margin: auto !important;
    }
    .mt-md-auto,
    .my-md-auto {
        margin-top: auto !important;
    }
    .mr-md-auto,
    .mx-md-auto {
        margin-right: auto !important;
    }
    .mb-md-auto,
    .my-md-auto {
        margin-bottom: auto !important;
    }
    .ml-md-auto,
    .mx-md-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 992px) {
    .m-lg-0 {
        margin: 0 !important;
    }
    .mt-lg-0,
    .my-lg-0 {
        margin-top: 0 !important;
    }
    .mr-lg-0,
    .mx-lg-0 {
        margin-right: 0 !important;
    }
    .mb-lg-0,
    .my-lg-0 {
        margin-bottom: 0 !important;
    }
    .ml-lg-0,
    .mx-lg-0 {
        margin-left: 0 !important;
    }
    .m-lg-1 {
        margin: 0.25rem !important;
    }
    .mt-lg-1,
    .my-lg-1 {
        margin-top: 0.25rem !important;
    }
    .mr-lg-1,
    .mx-lg-1 {
        margin-right: 0.25rem !important;
    }
    .mb-lg-1,
    .my-lg-1 {
        margin-bottom: 0.25rem !important;
    }
    .ml-lg-1,
    .mx-lg-1 {
        margin-left: 0.25rem !important;
    }
    .m-lg-2 {
        margin: 0.5rem !important;
    }
    .mt-lg-2,
    .my-lg-2 {
        margin-top: 0.5rem !important;
    }
    .mr-lg-2,
    .mx-lg-2 {
        margin-right: 0.5rem !important;
    }
    .mb-lg-2,
    .my-lg-2 {
        margin-bottom: 0.5rem !important;
    }
    .ml-lg-2,
    .mx-lg-2 {
        margin-left: 0.5rem !important;
    }
    .m-lg-3 {
        margin: 1rem !important;
    }
    .mt-lg-3,
    .my-lg-3 {
        margin-top: 1rem !important;
    }
    .mr-lg-3,
    .mx-lg-3 {
        margin-right: 1rem !important;
    }
    .mb-lg-3,
    .my-lg-3 {
        margin-bottom: 1rem !important;
    }
    .ml-lg-3,
    .mx-lg-3 {
        margin-left: 1rem !important;
    }
    .m-lg-4 {
        margin: 1.5rem !important;
    }
    .mt-lg-4,
    .my-lg-4 {
        margin-top: 1.5rem !important;
    }
    .mr-lg-4,
    .mx-lg-4 {
        margin-right: 1.5rem !important;
    }
    .mb-lg-4,
    .my-lg-4 {
        margin-bottom: 1.5rem !important;
    }
    .ml-lg-4,
    .mx-lg-4 {
        margin-left: 1.5rem !important;
    }
    .m-lg-5 {
        margin: 3rem !important;
    }
    .mt-lg-5,
    .my-lg-5 {
        margin-top: 3rem !important;
    }
    .mr-lg-5,
    .mx-lg-5 {
        margin-right: 3rem !important;
    }
    .mb-lg-5,
    .my-lg-5 {
        margin-bottom: 3rem !important;
    }
    .ml-lg-5,
    .mx-lg-5 {
        margin-left: 3rem !important;
    }
    .m-lg-20px {
        margin: 20px !important;
    }
    .mt-lg-20px,
    .my-lg-20px {
        margin-top: 20px !important;
    }
    .mr-lg-20px,
    .mx-lg-20px {
        margin-right: 20px !important;
    }
    .mb-lg-20px,
    .my-lg-20px {
        margin-bottom: 20px !important;
    }
    .ml-lg-20px,
    .mx-lg-20px {
        margin-left: 20px !important;
    }
    .m-lg-30px {
        margin: 30px !important;
    }
    .mt-lg-30px,
    .my-lg-30px {
        margin-top: 30px !important;
    }
    .mr-lg-30px,
    .mx-lg-30px {
        margin-right: 30px !important;
    }
    .mb-lg-30px,
    .my-lg-30px {
        margin-bottom: 30px !important;
    }
    .ml-lg-30px,
    .mx-lg-30px {
        margin-left: 30px !important;
    }
    .m-lg-35px {
        margin: 35px !important;
    }
    .mt-lg-35px,
    .my-lg-35px {
        margin-top: 35px !important;
    }
    .mr-lg-35px,
    .mx-lg-35px {
        margin-right: 35px !important;
    }
    .mb-lg-35px,
    .my-lg-35px {
        margin-bottom: 35px !important;
    }
    .ml-lg-35px,
    .mx-lg-35px {
        margin-left: 35px !important;
    }
    .m-lg-40px {
        margin: 40px !important;
    }
    .mt-lg-40px,
    .my-lg-40px {
        margin-top: 40px !important;
    }
    .mr-lg-40px,
    .mx-lg-40px {
        margin-right: 40px !important;
    }
    .mb-lg-40px,
    .my-lg-40px {
        margin-bottom: 40px !important;
    }
    .ml-lg-40px,
    .mx-lg-40px {
        margin-left: 40px !important;
    }
    .p-lg-0 {
        padding: 0 !important;
    }
    .pt-lg-0,
    .py-lg-0 {
        padding-top: 0 !important;
    }
    .pr-lg-0,
    .px-lg-0 {
        padding-right: 0 !important;
    }
    .pb-lg-0,
    .py-lg-0 {
        padding-bottom: 0 !important;
    }
    .pl-lg-0,
    .px-lg-0 {
        padding-left: 0 !important;
    }
    .p-lg-1 {
        padding: 0.25rem !important;
    }
    .pt-lg-1,
    .py-lg-1 {
        padding-top: 0.25rem !important;
    }
    .pr-lg-1,
    .px-lg-1 {
        padding-right: 0.25rem !important;
    }
    .pb-lg-1,
    .py-lg-1 {
        padding-bottom: 0.25rem !important;
    }
    .pl-lg-1,
    .px-lg-1 {
        padding-left: 0.25rem !important;
    }
    .p-lg-2 {
        padding: 0.5rem !important;
    }
    .pt-lg-2,
    .py-lg-2 {
        padding-top: 0.5rem !important;
    }
    .pr-lg-2,
    .px-lg-2 {
        padding-right: 0.5rem !important;
    }
    .pb-lg-2,
    .py-lg-2 {
        padding-bottom: 0.5rem !important;
    }
    .pl-lg-2,
    .px-lg-2 {
        padding-left: 0.5rem !important;
    }
    .p-lg-3 {
        padding: 1rem !important;
    }
    .pt-lg-3,
    .py-lg-3 {
        padding-top: 1rem !important;
    }
    .pr-lg-3,
    .px-lg-3 {
        padding-right: 1rem !important;
    }
    .pb-lg-3,
    .py-lg-3 {
        padding-bottom: 1rem !important;
    }
    .pl-lg-3,
    .px-lg-3 {
        padding-left: 1rem !important;
    }
    .p-lg-4 {
        padding: 1.5rem !important;
    }
    .pt-lg-4,
    .py-lg-4 {
        padding-top: 1.5rem !important;
    }
    .pr-lg-4,
    .px-lg-4 {
        padding-right: 1.5rem !important;
    }
    .pb-lg-4,
    .py-lg-4 {
        padding-bottom: 1.5rem !important;
    }
    .pl-lg-4,
    .px-lg-4 {
        padding-left: 1.5rem !important;
    }
    .p-lg-5 {
        padding: 3rem !important;
    }
    .pt-lg-5,
    .py-lg-5 {
        padding-top: 3rem !important;
    }
    .pr-lg-5,
    .px-lg-5 {
        padding-right: 3rem !important;
    }
    .pb-lg-5,
    .py-lg-5 {
        padding-bottom: 3rem !important;
    }
    .pl-lg-5,
    .px-lg-5 {
        padding-left: 3rem !important;
    }
    .p-lg-20px {
        padding: 20px !important;
    }
    .pt-lg-20px,
    .py-lg-20px {
        padding-top: 20px !important;
    }
    .pr-lg-20px,
    .px-lg-20px {
        padding-right: 20px !important;
    }
    .pb-lg-20px,
    .py-lg-20px {
        padding-bottom: 20px !important;
    }
    .pl-lg-20px,
    .px-lg-20px {
        padding-left: 20px !important;
    }
    .p-lg-30px {
        padding: 30px !important;
    }
    .pt-lg-30px,
    .py-lg-30px {
        padding-top: 30px !important;
    }
    .pr-lg-30px,
    .px-lg-30px {
        padding-right: 30px !important;
    }
    .pb-lg-30px,
    .py-lg-30px {
        padding-bottom: 30px !important;
    }
    .pl-lg-30px,
    .px-lg-30px {
        padding-left: 30px !important;
    }
    .p-lg-35px {
        padding: 35px !important;
    }
    .pt-lg-35px,
    .py-lg-35px {
        padding-top: 35px !important;
    }
    .pr-lg-35px,
    .px-lg-35px {
        padding-right: 35px !important;
    }
    .pb-lg-35px,
    .py-lg-35px {
        padding-bottom: 35px !important;
    }
    .pl-lg-35px,
    .px-lg-35px {
        padding-left: 35px !important;
    }
    .p-lg-40px {
        padding: 40px !important;
    }
    .pt-lg-40px,
    .py-lg-40px {
        padding-top: 40px !important;
    }
    .pr-lg-40px,
    .px-lg-40px {
        padding-right: 40px !important;
    }
    .pb-lg-40px,
    .py-lg-40px {
        padding-bottom: 40px !important;
    }
    .pl-lg-40px,
    .px-lg-40px {
        padding-left: 40px !important;
    }
    .m-lg-n1 {
        margin: -0.25rem !important;
    }
    .mt-lg-n1,
    .my-lg-n1 {
        margin-top: -0.25rem !important;
    }
    .mr-lg-n1,
    .mx-lg-n1 {
        margin-right: -0.25rem !important;
    }
    .mb-lg-n1,
    .my-lg-n1 {
        margin-bottom: -0.25rem !important;
    }
    .ml-lg-n1,
    .mx-lg-n1 {
        margin-left: -0.25rem !important;
    }
    .m-lg-n2 {
        margin: -0.5rem !important;
    }
    .mt-lg-n2,
    .my-lg-n2 {
        margin-top: -0.5rem !important;
    }
    .mr-lg-n2,
    .mx-lg-n2 {
        margin-right: -0.5rem !important;
    }
    .mb-lg-n2,
    .my-lg-n2 {
        margin-bottom: -0.5rem !important;
    }
    .ml-lg-n2,
    .mx-lg-n2 {
        margin-left: -0.5rem !important;
    }
    .m-lg-n3 {
        margin: -1rem !important;
    }
    .mt-lg-n3,
    .my-lg-n3 {
        margin-top: -1rem !important;
    }
    .mr-lg-n3,
    .mx-lg-n3 {
        margin-right: -1rem !important;
    }
    .mb-lg-n3,
    .my-lg-n3 {
        margin-bottom: -1rem !important;
    }
    .ml-lg-n3,
    .mx-lg-n3 {
        margin-left: -1rem !important;
    }
    .m-lg-n4 {
        margin: -1.5rem !important;
    }
    .mt-lg-n4,
    .my-lg-n4 {
        margin-top: -1.5rem !important;
    }
    .mr-lg-n4,
    .mx-lg-n4 {
        margin-right: -1.5rem !important;
    }
    .mb-lg-n4,
    .my-lg-n4 {
        margin-bottom: -1.5rem !important;
    }
    .ml-lg-n4,
    .mx-lg-n4 {
        margin-left: -1.5rem !important;
    }
    .m-lg-n5 {
        margin: -3rem !important;
    }
    .mt-lg-n5,
    .my-lg-n5 {
        margin-top: -3rem !important;
    }
    .mr-lg-n5,
    .mx-lg-n5 {
        margin-right: -3rem !important;
    }
    .mb-lg-n5,
    .my-lg-n5 {
        margin-bottom: -3rem !important;
    }
    .ml-lg-n5,
    .mx-lg-n5 {
        margin-left: -3rem !important;
    }
    .m-lg-n20px {
        margin: -20px !important;
    }
    .mt-lg-n20px,
    .my-lg-n20px {
        margin-top: -20px !important;
    }
    .mr-lg-n20px,
    .mx-lg-n20px {
        margin-right: -20px !important;
    }
    .mb-lg-n20px,
    .my-lg-n20px {
        margin-bottom: -20px !important;
    }
    .ml-lg-n20px,
    .mx-lg-n20px {
        margin-left: -20px !important;
    }
    .m-lg-n30px {
        margin: -30px !important;
    }
    .mt-lg-n30px,
    .my-lg-n30px {
        margin-top: -30px !important;
    }
    .mr-lg-n30px,
    .mx-lg-n30px {
        margin-right: -30px !important;
    }
    .mb-lg-n30px,
    .my-lg-n30px {
        margin-bottom: -30px !important;
    }
    .ml-lg-n30px,
    .mx-lg-n30px {
        margin-left: -30px !important;
    }
    .m-lg-n35px {
        margin: -35px !important;
    }
    .mt-lg-n35px,
    .my-lg-n35px {
        margin-top: -35px !important;
    }
    .mr-lg-n35px,
    .mx-lg-n35px {
        margin-right: -35px !important;
    }
    .mb-lg-n35px,
    .my-lg-n35px {
        margin-bottom: -35px !important;
    }
    .ml-lg-n35px,
    .mx-lg-n35px {
        margin-left: -35px !important;
    }
    .m-lg-n40px {
        margin: -40px !important;
    }
    .mt-lg-n40px,
    .my-lg-n40px {
        margin-top: -40px !important;
    }
    .mr-lg-n40px,
    .mx-lg-n40px {
        margin-right: -40px !important;
    }
    .mb-lg-n40px,
    .my-lg-n40px {
        margin-bottom: -40px !important;
    }
    .ml-lg-n40px,
    .mx-lg-n40px {
        margin-left: -40px !important;
    }
    .m-lg-auto {
        margin: auto !important;
    }
    .mt-lg-auto,
    .my-lg-auto {
        margin-top: auto !important;
    }
    .mr-lg-auto,
    .mx-lg-auto {
        margin-right: auto !important;
    }
    .mb-lg-auto,
    .my-lg-auto {
        margin-bottom: auto !important;
    }
    .ml-lg-auto,
    .mx-lg-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 1200px) {
    .m-xl-0 {
        margin: 0 !important;
    }
    .mt-xl-0,
    .my-xl-0 {
        margin-top: 0 !important;
    }
    .mr-xl-0,
    .mx-xl-0 {
        margin-right: 0 !important;
    }
    .mb-xl-0,
    .my-xl-0 {
        margin-bottom: 0 !important;
    }
    .ml-xl-0,
    .mx-xl-0 {
        margin-left: 0 !important;
    }
    .m-xl-1 {
        margin: 0.25rem !important;
    }
    .mt-xl-1,
    .my-xl-1 {
        margin-top: 0.25rem !important;
    }
    .mr-xl-1,
    .mx-xl-1 {
        margin-right: 0.25rem !important;
    }
    .mb-xl-1,
    .my-xl-1 {
        margin-bottom: 0.25rem !important;
    }
    .ml-xl-1,
    .mx-xl-1 {
        margin-left: 0.25rem !important;
    }
    .m-xl-2 {
        margin: 0.5rem !important;
    }
    .mt-xl-2,
    .my-xl-2 {
        margin-top: 0.5rem !important;
    }
    .mr-xl-2,
    .mx-xl-2 {
        margin-right: 0.5rem !important;
    }
    .mb-xl-2,
    .my-xl-2 {
        margin-bottom: 0.5rem !important;
    }
    .ml-xl-2,
    .mx-xl-2 {
        margin-left: 0.5rem !important;
    }
    .m-xl-3 {
        margin: 1rem !important;
    }
    .mt-xl-3,
    .my-xl-3 {
        margin-top: 1rem !important;
    }
    .mr-xl-3,
    .mx-xl-3 {
        margin-right: 1rem !important;
    }
    .mb-xl-3,
    .my-xl-3 {
        margin-bottom: 1rem !important;
    }
    .ml-xl-3,
    .mx-xl-3 {
        margin-left: 1rem !important;
    }
    .m-xl-4 {
        margin: 1.5rem !important;
    }
    .mt-xl-4,
    .my-xl-4 {
        margin-top: 1.5rem !important;
    }
    .mr-xl-4,
    .mx-xl-4 {
        margin-right: 1.5rem !important;
    }
    .mb-xl-4,
    .my-xl-4 {
        margin-bottom: 1.5rem !important;
    }
    .ml-xl-4,
    .mx-xl-4 {
        margin-left: 1.5rem !important;
    }
    .m-xl-5 {
        margin: 3rem !important;
    }
    .mt-xl-5,
    .my-xl-5 {
        margin-top: 3rem !important;
    }
    .mr-xl-5,
    .mx-xl-5 {
        margin-right: 3rem !important;
    }
    .mb-xl-5,
    .my-xl-5 {
        margin-bottom: 3rem !important;
    }
    .ml-xl-5,
    .mx-xl-5 {
        margin-left: 3rem !important;
    }
    .m-xl-20px {
        margin: 20px !important;
    }
    .mt-xl-20px,
    .my-xl-20px {
        margin-top: 20px !important;
    }
    .mr-xl-20px,
    .mx-xl-20px {
        margin-right: 20px !important;
    }
    .mb-xl-20px,
    .my-xl-20px {
        margin-bottom: 20px !important;
    }
    .ml-xl-20px,
    .mx-xl-20px {
        margin-left: 20px !important;
    }
    .m-xl-30px {
        margin: 30px !important;
    }
    .mt-xl-30px,
    .my-xl-30px {
        margin-top: 30px !important;
    }
    .mr-xl-30px,
    .mx-xl-30px {
        margin-right: 30px !important;
    }
    .mb-xl-30px,
    .my-xl-30px {
        margin-bottom: 30px !important;
    }
    .ml-xl-30px,
    .mx-xl-30px {
        margin-left: 30px !important;
    }
    .m-xl-35px {
        margin: 35px !important;
    }
    .mt-xl-35px,
    .my-xl-35px {
        margin-top: 35px !important;
    }
    .mr-xl-35px,
    .mx-xl-35px {
        margin-right: 35px !important;
    }
    .mb-xl-35px,
    .my-xl-35px {
        margin-bottom: 35px !important;
    }
    .ml-xl-35px,
    .mx-xl-35px {
        margin-left: 35px !important;
    }
    .m-xl-40px {
        margin: 40px !important;
    }
    .mt-xl-40px,
    .my-xl-40px {
        margin-top: 40px !important;
    }
    .mr-xl-40px,
    .mx-xl-40px {
        margin-right: 40px !important;
    }
    .mb-xl-40px,
    .my-xl-40px {
        margin-bottom: 40px !important;
    }
    .ml-xl-40px,
    .mx-xl-40px {
        margin-left: 40px !important;
    }
    .p-xl-0 {
        padding: 0 !important;
    }
    .pt-xl-0,
    .py-xl-0 {
        padding-top: 0 !important;
    }
    .pr-xl-0,
    .px-xl-0 {
        padding-right: 0 !important;
    }
    .pb-xl-0,
    .py-xl-0 {
        padding-bottom: 0 !important;
    }
    .pl-xl-0,
    .px-xl-0 {
        padding-left: 0 !important;
    }
    .p-xl-1 {
        padding: 0.25rem !important;
    }
    .pt-xl-1,
    .py-xl-1 {
        padding-top: 0.25rem !important;
    }
    .pr-xl-1,
    .px-xl-1 {
        padding-right: 0.25rem !important;
    }
    .pb-xl-1,
    .py-xl-1 {
        padding-bottom: 0.25rem !important;
    }
    .pl-xl-1,
    .px-xl-1 {
        padding-left: 0.25rem !important;
    }
    .p-xl-2 {
        padding: 0.5rem !important;
    }
    .pt-xl-2,
    .py-xl-2 {
        padding-top: 0.5rem !important;
    }
    .pr-xl-2,
    .px-xl-2 {
        padding-right: 0.5rem !important;
    }
    .pb-xl-2,
    .py-xl-2 {
        padding-bottom: 0.5rem !important;
    }
    .pl-xl-2,
    .px-xl-2 {
        padding-left: 0.5rem !important;
    }
    .p-xl-3 {
        padding: 1rem !important;
    }
    .pt-xl-3,
    .py-xl-3 {
        padding-top: 1rem !important;
    }
    .pr-xl-3,
    .px-xl-3 {
        padding-right: 1rem !important;
    }
    .pb-xl-3,
    .py-xl-3 {
        padding-bottom: 1rem !important;
    }
    .pl-xl-3,
    .px-xl-3 {
        padding-left: 1rem !important;
    }
    .p-xl-4 {
        padding: 1.5rem !important;
    }
    .pt-xl-4,
    .py-xl-4 {
        padding-top: 1.5rem !important;
    }
    .pr-xl-4,
    .px-xl-4 {
        padding-right: 1.5rem !important;
    }
    .pb-xl-4,
    .py-xl-4 {
        padding-bottom: 1.5rem !important;
    }
    .pl-xl-4,
    .px-xl-4 {
        padding-left: 1.5rem !important;
    }
    .p-xl-5 {
        padding: 3rem !important;
    }
    .pt-xl-5,
    .py-xl-5 {
        padding-top: 3rem !important;
    }
    .pr-xl-5,
    .px-xl-5 {
        padding-right: 3rem !important;
    }
    .pb-xl-5,
    .py-xl-5 {
        padding-bottom: 3rem !important;
    }
    .pl-xl-5,
    .px-xl-5 {
        padding-left: 3rem !important;
    }
    .p-xl-20px {
        padding: 20px !important;
    }
    .pt-xl-20px,
    .py-xl-20px {
        padding-top: 20px !important;
    }
    .pr-xl-20px,
    .px-xl-20px {
        padding-right: 20px !important;
    }
    .pb-xl-20px,
    .py-xl-20px {
        padding-bottom: 20px !important;
    }
    .pl-xl-20px,
    .px-xl-20px {
        padding-left: 20px !important;
    }
    .p-xl-30px {
        padding: 30px !important;
    }
    .pt-xl-30px,
    .py-xl-30px {
        padding-top: 30px !important;
    }
    .pr-xl-30px,
    .px-xl-30px {
        padding-right: 30px !important;
    }
    .pb-xl-30px,
    .py-xl-30px {
        padding-bottom: 30px !important;
    }
    .pl-xl-30px,
    .px-xl-30px {
        padding-left: 30px !important;
    }
    .p-xl-35px {
        padding: 35px !important;
    }
    .pt-xl-35px,
    .py-xl-35px {
        padding-top: 35px !important;
    }
    .pr-xl-35px,
    .px-xl-35px {
        padding-right: 35px !important;
    }
    .pb-xl-35px,
    .py-xl-35px {
        padding-bottom: 35px !important;
    }
    .pl-xl-35px,
    .px-xl-35px {
        padding-left: 35px !important;
    }
    .p-xl-40px {
        padding: 40px !important;
    }
    .pt-xl-40px,
    .py-xl-40px {
        padding-top: 40px !important;
    }
    .pr-xl-40px,
    .px-xl-40px {
        padding-right: 40px !important;
    }
    .pb-xl-40px,
    .py-xl-40px {
        padding-bottom: 40px !important;
    }
    .pl-xl-40px,
    .px-xl-40px {
        padding-left: 40px !important;
    }
    .m-xl-n1 {
        margin: -0.25rem !important;
    }
    .mt-xl-n1,
    .my-xl-n1 {
        margin-top: -0.25rem !important;
    }
    .mr-xl-n1,
    .mx-xl-n1 {
        margin-right: -0.25rem !important;
    }
    .mb-xl-n1,
    .my-xl-n1 {
        margin-bottom: -0.25rem !important;
    }
    .ml-xl-n1,
    .mx-xl-n1 {
        margin-left: -0.25rem !important;
    }
    .m-xl-n2 {
        margin: -0.5rem !important;
    }
    .mt-xl-n2,
    .my-xl-n2 {
        margin-top: -0.5rem !important;
    }
    .mr-xl-n2,
    .mx-xl-n2 {
        margin-right: -0.5rem !important;
    }
    .mb-xl-n2,
    .my-xl-n2 {
        margin-bottom: -0.5rem !important;
    }
    .ml-xl-n2,
    .mx-xl-n2 {
        margin-left: -0.5rem !important;
    }
    .m-xl-n3 {
        margin: -1rem !important;
    }
    .mt-xl-n3,
    .my-xl-n3 {
        margin-top: -1rem !important;
    }
    .mr-xl-n3,
    .mx-xl-n3 {
        margin-right: -1rem !important;
    }
    .mb-xl-n3,
    .my-xl-n3 {
        margin-bottom: -1rem !important;
    }
    .ml-xl-n3,
    .mx-xl-n3 {
        margin-left: -1rem !important;
    }
    .m-xl-n4 {
        margin: -1.5rem !important;
    }
    .mt-xl-n4,
    .my-xl-n4 {
        margin-top: -1.5rem !important;
    }
    .mr-xl-n4,
    .mx-xl-n4 {
        margin-right: -1.5rem !important;
    }
    .mb-xl-n4,
    .my-xl-n4 {
        margin-bottom: -1.5rem !important;
    }
    .ml-xl-n4,
    .mx-xl-n4 {
        margin-left: -1.5rem !important;
    }
    .m-xl-n5 {
        margin: -3rem !important;
    }
    .mt-xl-n5,
    .my-xl-n5 {
        margin-top: -3rem !important;
    }
    .mr-xl-n5,
    .mx-xl-n5 {
        margin-right: -3rem !important;
    }
    .mb-xl-n5,
    .my-xl-n5 {
        margin-bottom: -3rem !important;
    }
    .ml-xl-n5,
    .mx-xl-n5 {
        margin-left: -3rem !important;
    }
    .m-xl-n20px {
        margin: -20px !important;
    }
    .mt-xl-n20px,
    .my-xl-n20px {
        margin-top: -20px !important;
    }
    .mr-xl-n20px,
    .mx-xl-n20px {
        margin-right: -20px !important;
    }
    .mb-xl-n20px,
    .my-xl-n20px {
        margin-bottom: -20px !important;
    }
    .ml-xl-n20px,
    .mx-xl-n20px {
        margin-left: -20px !important;
    }
    .m-xl-n30px {
        margin: -30px !important;
    }
    .mt-xl-n30px,
    .my-xl-n30px {
        margin-top: -30px !important;
    }
    .mr-xl-n30px,
    .mx-xl-n30px {
        margin-right: -30px !important;
    }
    .mb-xl-n30px,
    .my-xl-n30px {
        margin-bottom: -30px !important;
    }
    .ml-xl-n30px,
    .mx-xl-n30px {
        margin-left: -30px !important;
    }
    .m-xl-n35px {
        margin: -35px !important;
    }
    .mt-xl-n35px,
    .my-xl-n35px {
        margin-top: -35px !important;
    }
    .mr-xl-n35px,
    .mx-xl-n35px {
        margin-right: -35px !important;
    }
    .mb-xl-n35px,
    .my-xl-n35px {
        margin-bottom: -35px !important;
    }
    .ml-xl-n35px,
    .mx-xl-n35px {
        margin-left: -35px !important;
    }
    .m-xl-n40px {
        margin: -40px !important;
    }
    .mt-xl-n40px,
    .my-xl-n40px {
        margin-top: -40px !important;
    }
    .mr-xl-n40px,
    .mx-xl-n40px {
        margin-right: -40px !important;
    }
    .mb-xl-n40px,
    .my-xl-n40px {
        margin-bottom: -40px !important;
    }
    .ml-xl-n40px,
    .mx-xl-n40px {
        margin-left: -40px !important;
    }
    .m-xl-auto {
        margin: auto !important;
    }
    .mt-xl-auto,
    .my-xl-auto {
        margin-top: auto !important;
    }
    .mr-xl-auto,
    .mx-xl-auto {
        margin-right: auto !important;
    }
    .mb-xl-auto,
    .my-xl-auto {
        margin-bottom: auto !important;
    }
    .ml-xl-auto,
    .mx-xl-auto {
        margin-left: auto !important;
    }
}

.text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
    text-align: justify !important;
}

.text-wrap {
    white-space: normal !important;
}

.text-nowrap {
    white-space: nowrap !important;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

@media (min-width: 576px) {
    .text-sm-left {
        text-align: left !important;
    }
    .text-sm-right {
        text-align: right !important;
    }
    .text-sm-center {
        text-align: center !important;
    }
}

@media (min-width: 768px) {
    .text-md-left {
        text-align: left !important;
    }
    .text-md-right {
        text-align: right !important;
    }
    .text-md-center {
        text-align: center !important;
    }
}

@media (min-width: 992px) {
    .text-lg-left {
        text-align: left !important;
    }
    .text-lg-right {
        text-align: right !important;
    }
    .text-lg-center {
        text-align: center !important;
    }
}

@media (min-width: 1200px) {
    .text-xl-left {
        text-align: left !important;
    }
    .text-xl-right {
        text-align: right !important;
    }
    .text-xl-center {
        text-align: center !important;
    }
}

.text-lowercase {
    text-transform: lowercase !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.font-weight-light {
    font-weight: 300 !important;
}

.font-weight-lighter {
    font-weight: lighter !important;
}

.font-weight-normal {
    font-weight: 400 !important;
}

.font-weight-bold {
    font-weight: 700 !important;
}

.font-weight-bolder {
    font-weight: bolder !important;
}

.font-italic {
    font-style: italic !important;
}

.text-white {
    color: #ffffff !important;
}

.text-primary {
    color: #0684fe !important;
}

a.text-primary:hover,
a.text-primary:focus {
    color: #015db7 !important;
}

.text-secondary {
    color: #f5f6f8 !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
    color: #c8ced9 !important;
}

.text-success {
    color: #35ac80 !important;
}

a.text-success:hover,
a.text-success:focus {
    color: #237254 !important;
}

.text-info {
    color: #5d3fff !important;
}

a.text-info:hover,
a.text-info:focus {
    color: #2600f2 !important;
}

.text-warning {
    color: #f7b731 !important;
}

a.text-warning:hover,
a.text-warning:focus {
    color: #d39208 !important;
}

.text-danger {
    color: #fe3f3e !important;
}

a.text-danger:hover,
a.text-danger:focus {
    color: #ee0201 !important;
}

.text-light {
    color: #f8f9fa !important;
}

a.text-light:hover,
a.text-light:focus {
    color: #cbd3da !important;
}

.text-dark {
    color: #131b24 !important;
}

a.text-dark:hover,
a.text-dark:focus {
    color: black !important;
}

.text-black {
    color: #000000 !important;
}

a.text-black:hover,
a.text-black:focus {
    color: black !important;
}

.text-white {
    color: #ffffff !important;
}

a.text-white:hover,
a.text-white:focus {
    color: #d9d9d9 !important;
}

.text-body {
    color: #000000 !important;
}

.text-muted {
    color: #4e5d78 !important;
}

.text-black-50 {
    color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.text-decoration-none {
    text-decoration: none !important;
}

.text-break {
    word-break: break-word !important;
    overflow-wrap: break-word !important;
}

.text-reset {
    color: inherit !important;
}

.visible {
    visibility: visible !important;
}

.invisible {
    visibility: hidden !important;
}

@media print {
    *,
    *::before,
    *::after {
        text-shadow: none !important;
        box-shadow: none !important;
    }
    a:not(.btn) {
        text-decoration: underline;
    }
    abbr[title]::after {
        content: " (" attr(title) ")";
    }
    pre {
        white-space: pre-wrap !important;
    }
    pre,
    blockquote {
        border: 1px solid #adb5bd;
        page-break-inside: avoid;
    }
    thead {
        display: table-header-group;
    }
    tr,
    img {
        page-break-inside: avoid;
    }
    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }
    h2,
    h3 {
        page-break-after: avoid;
    }
    @page {
        size: a3;
    }
    body {
        min-width: 992px !important;
    }
    .container {
        min-width: 992px !important;
    }
    .navbar {
        display: none;
    }
    .badge {
        border: 1px solid #000000;
    }
    .table {
        border-collapse: collapse !important;
    }
    .table td,
    .table th {
        background-color: #ffffff !important;
    }
    .table-bordered th,
    .table-bordered td {
        border: 1px solid #dee2e6 !important;
    }
    .table-dark {
        color: inherit;
    }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody+tbody {
        border-color: #dee2e6;
    }
    .table .thead-dark th {
        color: inherit;
        border-color: #dee2e6;
    }
}

.font-weight-semibold {
    font-weight: 600;
}

.display-1 {
    line-height: 1.39;
}

.display-2 {
    line-height: 1.25;
}

.display-3 {
    line-height: 1.16;
}

.display-4 {
    line-height: 1.21;
}

.text-gray {
    color: #4e5d78;
}

p {
    line-height: 1.69;
}

.blockquote {
    font-size: 20px;
    line-height: 1.5;
}

.blockquote p {
    line-height: inherit;
    padding-left: 26px;
    padding-right: 26px;
    border-left: 3px solid transparent;
}

.blockquote-footer {
    color: inherit;
    font-size: inherit;
}

.blockquote-footer::before {
    content: "";
}

.small {
    font-size: 75%;
}

.blockquote-primary p {
    border-left-color: #0684fe;
}

.blockquote-secondary p {
    border-left-color: #f5f6f8;
}

.blockquote-success p {
    border-left-color: #35ac80;
}

.blockquote-info p {
    border-left-color: #5d3fff;
}

.blockquote-warning p {
    border-left-color: #f7b731;
}

.blockquote-danger p {
    border-left-color: #fe3f3e;
}

.blockquote-light p {
    border-left-color: #f8f9fa;
}

.blockquote-dark p {
    border-left-color: #131b24;
}

.blockquote-black p {
    border-left-color: #000000;
}

.blockquote-white p {
    border-left-color: #ffffff;
}

ul.list-custom {
    list-style: none;
}

ul.list-custom li {
    position: relative;
}

ul.list-custom li::before {
    content: "";
    display: inline-block;
    height: 20px;
    width: 20px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%2335ac80' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-arrow-right-circle'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cpolyline points='12 16 16 12 12 8'%3E%3C/polyline%3E%3Cline x1='8' y1='12' x2='16' y2='12'%3E%3C/line%3E%3C/svg%3E");
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    left: -35px;
    top: 2px;
}

ul.list-checked {
    list-style: none;
}

ul.list-checked li {
    position: relative;
    margin-bottom: 14px;
}

ul.list-checked li::before {
    content: "";
    display: inline-block;
    height: 20px;
    width: 20px;
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fe3f3e' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check-circle'%3E%3Cpath d='M22 11.08V12a10 10 0 1 1-5.93-9.14'%3E%3C/path%3E%3Cpolyline points='22 4 12 14.01 9 11.01'%3E%3C/polyline%3E%3C/svg%3E");
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    left: -35px;
    top: 2px;
}

ul.list-arrow {
    list-style: none;
}

ul.list-arrow li {
    position: relative;
    margin-bottom: 14px;
}

ul.list-arrow li::before {
    content: "";
    display: inline-block;
    height: 20px;
    width: 20px;
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%2335ac80' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-arrow-right-circle'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cpolyline points='12 16 16 12 12 8'%3E%3C/polyline%3E%3Cline x1='8' y1='12' x2='16' y2='12'%3E%3C/line%3E%3C/svg%3E");
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    left: -35px;
    top: 2px;
}

ul.list-ckeck-soft {
    list-style: none;
}

ul.list-ckeck-soft li {
    position: relative;
    margin-bottom: 14px;
}

ul.list-ckeck-soft li::before {
    content: "";
    display: inline-block;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-color: #9ceacd;
    position: absolute;
    left: -35px;
    top: 2px;
}

ul.list-ckeck-soft li::after {
    content: "";
    display: inline-block;
    width: 12px;
    height: 6px;
    border: 2px solid transparent;
    border-color: transparent transparent #35ac80 #35ac80;
    position: absolute;
    left: -29px;
    top: 9px;
    transform: rotate(-45deg);
}

.alert {
    font-size: 14px;
    line-height: 1.64;
    padding: 11px 16px;
}

.alert .close {
    opacity: 1;
    text-shadow: none;
    font-size: 20px;
    padding: 0;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
}

.alert .close:hover {
    color: inherit;
}

.alert-dismissible {
    padding-right: 32px;
}

.alert-primary {
    color: #ffffff;
    background-color: #0684fe;
    border-color: #0684fe;
}

.alert-primary hr {
    border-top-color: #0177ea;
}

.alert-primary .alert-link {
    color: #e6e6e6;
}

.alert-primary .feather {
    stroke: #0684fe;
    fill: currentColor;
}

.alert-secondary {
    color: #000000;
    background-color: #f5f6f8;
    border-color: #f5f6f8;
}

.alert-secondary hr {
    border-top-color: #e6e9ee;
}

.alert-secondary .alert-link {
    color: black;
}

.alert-secondary .feather {
    stroke: #f5f6f8;
    fill: currentColor;
}

.alert-success {
    color: #ffffff;
    background-color: #35ac80;
    border-color: #35ac80;
}

.alert-success hr {
    border-top-color: #2f9971;
}

.alert-success .alert-link {
    color: #e6e6e6;
}

.alert-success .feather {
    stroke: #35ac80;
    fill: currentColor;
}

.alert-info {
    color: #ffffff;
    background-color: #5d3fff;
    border-color: #5d3fff;
}

.alert-info hr {
    border-top-color: #4726ff;
}

.alert-info .alert-link {
    color: #e6e6e6;
}

.alert-info .feather {
    stroke: #5d3fff;
    fill: currentColor;
}

.alert-warning {
    color: #000000;
    background-color: #f7b731;
    border-color: #f7b731;
}

.alert-warning hr {
    border-top-color: #f6ae18;
}

.alert-warning .alert-link {
    color: black;
}

.alert-warning .feather {
    stroke: #f7b731;
    fill: currentColor;
}

.alert-danger {
    color: #ffffff;
    background-color: #fe3f3e;
    border-color: #fe3f3e;
}

.alert-danger hr {
    border-top-color: #fe2625;
}

.alert-danger .alert-link {
    color: #e6e6e6;
}

.alert-danger .feather {
    stroke: #fe3f3e;
    fill: currentColor;
}

.alert-light {
    color: #000000;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.alert-light hr {
    border-top-color: #e9ecef;
}

.alert-light .alert-link {
    color: black;
}

.alert-light .feather {
    stroke: #f8f9fa;
    fill: currentColor;
}

.alert-dark {
    color: #ffffff;
    background-color: #131b24;
    border-color: #131b24;
}

.alert-dark hr {
    border-top-color: #0a0e13;
}

.alert-dark .alert-link {
    color: #e6e6e6;
}

.alert-dark .feather {
    stroke: #131b24;
    fill: currentColor;
}

.alert-black {
    color: #ffffff;
    background-color: #000000;
    border-color: #000000;
}

.alert-black hr {
    border-top-color: black;
}

.alert-black .alert-link {
    color: #e6e6e6;
}

.alert-black .feather {
    stroke: #000000;
    fill: currentColor;
}

.alert-white {
    color: #000000;
    background-color: #ffffff;
    border-color: #ffffff;
}

.alert-white hr {
    border-top-color: #f2f2f2;
}

.alert-white .alert-link {
    color: black;
}

.alert-white .feather {
    stroke: #ffffff;
    fill: currentColor;
}

.alert-white {
    background-color: #ffffff;
    box-shadow: 0 0 4px 0 rgba(213, 205, 205, 0.5);
    color: #000000;
}

.alert-white .feather {
    stroke: #ffffff;
    fill: #000000;
}

.alert-white .close {
    opacity: 1;
    text-shadow: none;
}

.avatar {
    display: inline-block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    font-size: calc(25px * 0.75);
    line-height: 1;
    border-radius: 0.25rem;
    overflow: hidden;
}

.avatar img {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
}

.avatar-text {
    background-color: #0684fe;
    color: #ffffff;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.avatar-rounded {
    border-radius: 50%;
}

.avatar-square {
    border-radius: 0;
}

.avatar-xs {
    width: 10px;
    height: 10px;
    font-size: calc(10px * 0.35);
}

.avatar-sm {
    width: 18px;
    height: 18px;
    font-size: calc(18px * 0.35);
}

.avatar-md {
    width: 25px;
    height: 25px;
    font-size: calc(25px * 0.35);
}

.avatar-lg {
    width: 50px;
    height: 50px;
    font-size: calc(50px * 0.35);
}

.avatar-xl {
    width: 100px;
    height: 100px;
    font-size: calc(100px * 0.35);
}

.avatar-primary {
    background-color: #0684fe;
    color: #ffffff;
}

.avatar-secondary {
    background-color: #f5f6f8;
    color: #000000;
}

.avatar-success {
    background-color: #35ac80;
    color: #ffffff;
}

.avatar-info {
    background-color: #5d3fff;
    color: #ffffff;
}

.avatar-warning {
    background-color: #f7b731;
    color: #000000;
}

.avatar-danger {
    background-color: #fe3f3e;
    color: #ffffff;
}

.avatar-light {
    background-color: #f8f9fa;
    color: #000000;
}

.avatar-dark {
    background-color: #131b24;
    color: #ffffff;
}

.avatar-black {
    background-color: #000000;
    color: #ffffff;
}

.avatar-white {
    background-color: #ffffff;
    color: #000000;
}

.badge {
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.14;
}

.badge-soft-primary {
    color: #0684fe;
    background-color: rgba(6, 132, 254, 0.2);
}

a.badge-soft-primary:hover,
a.badge-soft-primary:focus {
    color: #0684fe;
    background-color: rgba(1, 106, 208, 0.2);
}

a.badge-soft-primary:focus,
a.badge-soft-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(6, 132, 254, 0.05);
}

.badge-outline-primary {
    color: #0684fe;
    border: 1px solid #0684fe;
}

a.badge-outline-primary:hover,
a.badge-outline-primary:focus {
    color: #ffffff;
    background-color: #0684fe;
}

a.badge-outline-primary:focus,
a.badge-outline-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(6, 132, 254, 0.2);
}

.badge-outline-soft-primary {
    color: #0684fe;
    border: 1px solid rgba(6, 132, 254, 0.2);
}

a.badge-outline-soft-primary:hover,
a.badge-outline-soft-primary:focus {
    color: #ffffff;
    background-color: rgba(6, 132, 254, 0.2);
}

a.badge-outline-soft-primary:focus,
a.badge-outline-soft-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(6, 132, 254, 0.05);
}

.badge-soft-secondary {
    color: #f5f6f8;
    background-color: rgba(245, 246, 248, 0.2);
    color: #000000;
}

a.badge-soft-secondary:hover,
a.badge-soft-secondary:focus {
    color: #f5f6f8;
    background-color: rgba(215, 219, 227, 0.2);
}

a.badge-soft-secondary:focus,
a.badge-soft-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(245, 246, 248, 0.05);
}

.badge-outline-secondary {
    color: #f5f6f8;
    border: 1px solid #f5f6f8;
    color: #000000;
}

a.badge-outline-secondary:hover,
a.badge-outline-secondary:focus {
    color: #000000;
    background-color: #f5f6f8;
}

a.badge-outline-secondary:focus,
a.badge-outline-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(245, 246, 248, 0.2);
}

.badge-outline-soft-secondary {
    color: #f5f6f8;
    border: 1px solid rgba(245, 246, 248, 0.2);
    color: #000000;
}

a.badge-outline-soft-secondary:hover,
a.badge-outline-soft-secondary:focus {
    color: #000000;
    background-color: rgba(245, 246, 248, 0.2);
}

a.badge-outline-soft-secondary:focus,
a.badge-outline-soft-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(245, 246, 248, 0.05);
}

.badge-soft-success {
    color: #35ac80;
    background-color: rgba(53, 172, 128, 0.2);
}

a.badge-soft-success:hover,
a.badge-soft-success:focus {
    color: #35ac80;
    background-color: rgba(41, 133, 99, 0.2);
}

a.badge-soft-success:focus,
a.badge-soft-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(53, 172, 128, 0.05);
}

.badge-outline-success {
    color: #35ac80;
    border: 1px solid #35ac80;
}

a.badge-outline-success:hover,
a.badge-outline-success:focus {
    color: #ffffff;
    background-color: #35ac80;
}

a.badge-outline-success:focus,
a.badge-outline-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(53, 172, 128, 0.2);
}

.badge-outline-soft-success {
    color: #35ac80;
    border: 1px solid rgba(53, 172, 128, 0.2);
}

a.badge-outline-soft-success:hover,
a.badge-outline-soft-success:focus {
    color: #ffffff;
    background-color: rgba(53, 172, 128, 0.2);
}

a.badge-outline-soft-success:focus,
a.badge-outline-soft-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(53, 172, 128, 0.05);
}

.badge-soft-info {
    color: #5d3fff;
    background-color: rgba(93, 63, 255, 0.2);
}

a.badge-soft-info:hover,
a.badge-soft-info:focus {
    color: #5d3fff;
    background-color: rgba(50, 12, 255, 0.2);
}

a.badge-soft-info:focus,
a.badge-soft-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(93, 63, 255, 0.05);
}

.badge-outline-info {
    color: #5d3fff;
    border: 1px solid #5d3fff;
}

a.badge-outline-info:hover,
a.badge-outline-info:focus {
    color: #ffffff;
    background-color: #5d3fff;
}

a.badge-outline-info:focus,
a.badge-outline-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(93, 63, 255, 0.2);
}

.badge-outline-soft-info {
    color: #5d3fff;
    border: 1px solid rgba(93, 63, 255, 0.2);
}

a.badge-outline-soft-info:hover,
a.badge-outline-soft-info:focus {
    color: #ffffff;
    background-color: rgba(93, 63, 255, 0.2);
}

a.badge-outline-soft-info:focus,
a.badge-outline-soft-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(93, 63, 255, 0.05);
}

.badge-soft-warning {
    color: #f7b731;
    background-color: rgba(247, 183, 49, 0.2);
}

a.badge-soft-warning:hover,
a.badge-soft-warning:focus {
    color: #f7b731;
    background-color: rgba(236, 163, 9, 0.2);
}

a.badge-soft-warning:focus,
a.badge-soft-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(247, 183, 49, 0.05);
}

.badge-outline-warning {
    color: #f7b731;
    border: 1px solid #f7b731;
}

a.badge-outline-warning:hover,
a.badge-outline-warning:focus {
    color: #000000;
    background-color: #f7b731;
}

a.badge-outline-warning:focus,
a.badge-outline-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(247, 183, 49, 0.2);
}

.badge-outline-soft-warning {
    color: #f7b731;
    border: 1px solid rgba(247, 183, 49, 0.2);
}

a.badge-outline-soft-warning:hover,
a.badge-outline-soft-warning:focus {
    color: #000000;
    background-color: rgba(247, 183, 49, 0.2);
}

a.badge-outline-soft-warning:focus,
a.badge-outline-soft-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(247, 183, 49, 0.05);
}

.badge-soft-danger {
    color: #fe3f3e;
    background-color: rgba(254, 63, 62, 0.2);
}

a.badge-soft-danger:hover,
a.badge-soft-danger:focus {
    color: #fe3f3e;
    background-color: rgba(254, 13, 11, 0.2);
}

a.badge-soft-danger:focus,
a.badge-soft-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(254, 63, 62, 0.05);
}

.badge-outline-danger {
    color: #fe3f3e;
    border: 1px solid #fe3f3e;
}

a.badge-outline-danger:hover,
a.badge-outline-danger:focus {
    color: #ffffff;
    background-color: #fe3f3e;
}

a.badge-outline-danger:focus,
a.badge-outline-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(254, 63, 62, 0.2);
}

.badge-outline-soft-danger {
    color: #fe3f3e;
    border: 1px solid rgba(254, 63, 62, 0.2);
}

a.badge-outline-soft-danger:hover,
a.badge-outline-soft-danger:focus {
    color: #ffffff;
    background-color: rgba(254, 63, 62, 0.2);
}

a.badge-outline-soft-danger:focus,
a.badge-outline-soft-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(254, 63, 62, 0.05);
}

.badge-soft-light {
    color: #f8f9fa;
    background-color: rgba(248, 249, 250, 0.2);
}

a.badge-soft-light:hover,
a.badge-soft-light:focus {
    color: #f8f9fa;
    background-color: rgba(218, 224, 229, 0.2);
}

a.badge-soft-light:focus,
a.badge-soft-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.05);
}

.badge-outline-light {
    color: #f8f9fa;
    border: 1px solid #f8f9fa;
}

a.badge-outline-light:hover,
a.badge-outline-light:focus {
    color: #000000;
    background-color: #f8f9fa;
}

a.badge-outline-light:focus,
a.badge-outline-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.2);
}

.badge-outline-soft-light {
    color: #f8f9fa;
    border: 1px solid rgba(248, 249, 250, 0.2);
}

a.badge-outline-soft-light:hover,
a.badge-outline-soft-light:focus {
    color: #000000;
    background-color: rgba(248, 249, 250, 0.2);
}

a.badge-outline-soft-light:focus,
a.badge-outline-soft-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.05);
}

.badge-soft-dark {
    color: #131b24;
    background-color: rgba(19, 27, 36, 0.2);
}

a.badge-soft-dark:hover,
a.badge-soft-dark:focus {
    color: #131b24;
    background-color: rgba(1, 2, 3, 0.2);
}

a.badge-soft-dark:focus,
a.badge-soft-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(19, 27, 36, 0.05);
}

.badge-outline-dark {
    color: #131b24;
    border: 1px solid #131b24;
}

a.badge-outline-dark:hover,
a.badge-outline-dark:focus {
    color: #ffffff;
    background-color: #131b24;
}

a.badge-outline-dark:focus,
a.badge-outline-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(19, 27, 36, 0.2);
}

.badge-outline-soft-dark {
    color: #131b24;
    border: 1px solid rgba(19, 27, 36, 0.2);
}

a.badge-outline-soft-dark:hover,
a.badge-outline-soft-dark:focus {
    color: #ffffff;
    background-color: rgba(19, 27, 36, 0.2);
}

a.badge-outline-soft-dark:focus,
a.badge-outline-soft-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(19, 27, 36, 0.05);
}

.badge-soft-black {
    color: #000000;
    background-color: rgba(0, 0, 0, 0.2);
}

a.badge-soft-black:hover,
a.badge-soft-black:focus {
    color: #000000;
    background-color: rgba(0, 0, 0, 0.2);
}

a.badge-soft-black:focus,
a.badge-soft-black.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.05);
}

.badge-outline-black {
    color: #000000;
    border: 1px solid #000000;
}

a.badge-outline-black:hover,
a.badge-outline-black:focus {
    color: #ffffff;
    background-color: #000000;
}

a.badge-outline-black:focus,
a.badge-outline-black.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.2);
}

.badge-outline-soft-black {
    color: #000000;
    border: 1px solid rgba(0, 0, 0, 0.2);
}

a.badge-outline-soft-black:hover,
a.badge-outline-soft-black:focus {
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.2);
}

a.badge-outline-soft-black:focus,
a.badge-outline-soft-black.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.05);
}

.badge-soft-white {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.2);
}

a.badge-soft-white:hover,
a.badge-soft-white:focus {
    color: #ffffff;
    background-color: rgba(230, 230, 230, 0.2);
}

a.badge-soft-white:focus,
a.badge-soft-white.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.05);
}

.badge-outline-white {
    color: #ffffff;
    border: 1px solid #ffffff;
}

a.badge-outline-white:hover,
a.badge-outline-white:focus {
    color: #000000;
    background-color: #ffffff;
}

a.badge-outline-white:focus,
a.badge-outline-white.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.2);
}

.badge-outline-soft-white {
    color: #ffffff;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

a.badge-outline-soft-white:hover,
a.badge-outline-soft-white:focus {
    color: #000000;
    background-color: rgba(255, 255, 255, 0.2);
}

a.badge-outline-soft-white:focus,
a.badge-outline-soft-white.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.05);
}

.badge.badge-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    overflow: hidden;
    padding: 0;
    display: inline-block;
}

button:focus {
    outline: none;
}

.btn-rounded {
    border-radius: 24px;
}

.btn {
    padding: 15px 28px;
    font-weight: 700;
    line-height: 1;
}

.btn-soft-primary {
    color: rgba(6, 132, 254, 0.6);
    background-color: rgba(6, 132, 254, 0.2);
    border-color: transparent;
}

.btn-soft-primary:hover {
    color: rgba(1, 113, 221, 0.6);
    background-color: rgba(1, 113, 221, 0.2);
    border-color: rgba(0, 0, 0, 0);
}

.btn-soft-primary:focus,
.btn-soft-primary.focus {
    color: rgba(1, 113, 221, 0.6);
    background-color: rgba(1, 113, 221, 0.2);
    border-color: rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-soft-primary.disabled,
.btn-soft-primary:disabled {
    color: rgba(6, 132, 254, 0.6);
    background-color: rgba(6, 132, 254, 0.2);
    border-color: transparent;
}

.btn-soft-primary:not(:disabled):not(.disabled):active,
.btn-soft-primary:not(:disabled):not(.disabled).active,
.show>.btn-soft-primary.dropdown-toggle {
    color: rgba(1, 106, 208, 0.6);
    background-color: rgba(1, 106, 208, 0.2);
    border-color: rgba(0, 0, 0, 0);
}

.btn-soft-primary:not(:disabled):not(.disabled):active:focus,
.btn-soft-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-soft-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-soft-secondary {
    color: rgba(0, 0, 0, 0.6);
    background-color: rgba(245, 246, 248, 0.2);
    border-color: transparent;
}

.btn-soft-secondary:hover {
    color: rgba(0, 0, 0, 0.6);
    background-color: rgba(223, 226, 232, 0.2);
    border-color: rgba(0, 0, 0, 0);
}

.btn-soft-secondary:focus,
.btn-soft-secondary.focus {
    color: rgba(0, 0, 0, 0.6);
    background-color: rgba(223, 226, 232, 0.2);
    border-color: rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-soft-secondary.disabled,
.btn-soft-secondary:disabled {
    color: rgba(0, 0, 0, 0.6);
    background-color: rgba(245, 246, 248, 0.2);
    border-color: transparent;
}

.btn-soft-secondary:not(:disabled):not(.disabled):active,
.btn-soft-secondary:not(:disabled):not(.disabled).active,
.show>.btn-soft-secondary.dropdown-toggle {
    color: rgba(0, 0, 0, 0.6);
    background-color: rgba(215, 219, 227, 0.2);
    border-color: rgba(0, 0, 0, 0);
}

.btn-soft-secondary:not(:disabled):not(.disabled):active:focus,
.btn-soft-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-soft-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-soft-success {
    color: rgba(53, 172, 128, 0.6);
    background-color: rgba(53, 172, 128, 0.2);
    border-color: transparent;
}

.btn-soft-success:hover {
    color: rgba(44, 143, 106, 0.6);
    background-color: rgba(44, 143, 106, 0.2);
    border-color: rgba(0, 0, 0, 0);
}

.btn-soft-success:focus,
.btn-soft-success.focus {
    color: rgba(44, 143, 106, 0.6);
    background-color: rgba(44, 143, 106, 0.2);
    border-color: rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-soft-success.disabled,
.btn-soft-success:disabled {
    color: rgba(53, 172, 128, 0.6);
    background-color: rgba(53, 172, 128, 0.2);
    border-color: transparent;
}

.btn-soft-success:not(:disabled):not(.disabled):active,
.btn-soft-success:not(:disabled):not(.disabled).active,
.show>.btn-soft-success.dropdown-toggle {
    color: rgba(41, 133, 99, 0.6);
    background-color: rgba(41, 133, 99, 0.2);
    border-color: rgba(0, 0, 0, 0);
}

.btn-soft-success:not(:disabled):not(.disabled):active:focus,
.btn-soft-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-soft-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-soft-info {
    color: rgba(93, 63, 255, 0.6);
    background-color: rgba(93, 63, 255, 0.2);
    border-color: transparent;
}

.btn-soft-info:hover {
    color: rgba(61, 25, 255, 0.6);
    background-color: rgba(61, 25, 255, 0.2);
    border-color: rgba(0, 0, 0, 0);
}

.btn-soft-info:focus,
.btn-soft-info.focus {
    color: rgba(61, 25, 255, 0.6);
    background-color: rgba(61, 25, 255, 0.2);
    border-color: rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-soft-info.disabled,
.btn-soft-info:disabled {
    color: rgba(93, 63, 255, 0.6);
    background-color: rgba(93, 63, 255, 0.2);
    border-color: transparent;
}

.btn-soft-info:not(:disabled):not(.disabled):active,
.btn-soft-info:not(:disabled):not(.disabled).active,
.show>.btn-soft-info.dropdown-toggle {
    color: rgba(50, 12, 255, 0.6);
    background-color: rgba(50, 12, 255, 0.2);
    border-color: rgba(0, 0, 0, 0);
}

.btn-soft-info:not(:disabled):not(.disabled):active:focus,
.btn-soft-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-soft-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-soft-warning {
    color: rgba(247, 183, 49, 0.6);
    background-color: rgba(247, 183, 49, 0.2);
    border-color: transparent;
}

.btn-soft-warning:hover {
    color: rgba(246, 170, 12, 0.6);
    background-color: rgba(246, 170, 12, 0.2);
    border-color: rgba(0, 0, 0, 0);
}

.btn-soft-warning:focus,
.btn-soft-warning.focus {
    color: rgba(246, 170, 12, 0.6);
    background-color: rgba(246, 170, 12, 0.2);
    border-color: rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-soft-warning.disabled,
.btn-soft-warning:disabled {
    color: rgba(247, 183, 49, 0.6);
    background-color: rgba(247, 183, 49, 0.2);
    border-color: transparent;
}

.btn-soft-warning:not(:disabled):not(.disabled):active,
.btn-soft-warning:not(:disabled):not(.disabled).active,
.show>.btn-soft-warning.dropdown-toggle {
    color: rgba(236, 163, 9, 0.6);
    background-color: rgba(236, 163, 9, 0.2);
    border-color: rgba(0, 0, 0, 0);
}

.btn-soft-warning:not(:disabled):not(.disabled):active:focus,
.btn-soft-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-soft-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-soft-danger {
    color: rgba(254, 63, 62, 0.6);
    background-color: rgba(254, 63, 62, 0.2);
    border-color: transparent;
}

.btn-soft-danger:hover {
    color: rgba(254, 25, 24, 0.6);
    background-color: rgba(254, 25, 24, 0.2);
    border-color: rgba(0, 0, 0, 0);
}

.btn-soft-danger:focus,
.btn-soft-danger.focus {
    color: rgba(254, 25, 24, 0.6);
    background-color: rgba(254, 25, 24, 0.2);
    border-color: rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-soft-danger.disabled,
.btn-soft-danger:disabled {
    color: rgba(254, 63, 62, 0.6);
    background-color: rgba(254, 63, 62, 0.2);
    border-color: transparent;
}

.btn-soft-danger:not(:disabled):not(.disabled):active,
.btn-soft-danger:not(:disabled):not(.disabled).active,
.show>.btn-soft-danger.dropdown-toggle {
    color: rgba(254, 13, 11, 0.6);
    background-color: rgba(254, 13, 11, 0.2);
    border-color: rgba(0, 0, 0, 0);
}

.btn-soft-danger:not(:disabled):not(.disabled):active:focus,
.btn-soft-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-soft-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-soft-light {
    color: rgba(248, 249, 250, 0.6);
    background-color: rgba(248, 249, 250, 0.2);
    border-color: transparent;
}

.btn-soft-light:hover {
    color: rgba(226, 230, 234, 0.6);
    background-color: rgba(226, 230, 234, 0.2);
    border-color: rgba(0, 0, 0, 0);
}

.btn-soft-light:focus,
.btn-soft-light.focus {
    color: rgba(226, 230, 234, 0.6);
    background-color: rgba(226, 230, 234, 0.2);
    border-color: rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-soft-light.disabled,
.btn-soft-light:disabled {
    color: rgba(248, 249, 250, 0.6);
    background-color: rgba(248, 249, 250, 0.2);
    border-color: transparent;
}

.btn-soft-light:not(:disabled):not(.disabled):active,
.btn-soft-light:not(:disabled):not(.disabled).active,
.show>.btn-soft-light.dropdown-toggle {
    color: rgba(218, 224, 229, 0.6);
    background-color: rgba(218, 224, 229, 0.2);
    border-color: rgba(0, 0, 0, 0);
}

.btn-soft-light:not(:disabled):not(.disabled):active:focus,
.btn-soft-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-soft-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-soft-dark {
    color: rgba(19, 27, 36, 0.6);
    background-color: rgba(19, 27, 36, 0.2);
    border-color: transparent;
}

.btn-soft-dark:hover {
    color: rgba(6, 8, 11, 0.6);
    background-color: rgba(6, 8, 11, 0.2);
    border-color: rgba(0, 0, 0, 0);
}

.btn-soft-dark:focus,
.btn-soft-dark.focus {
    color: rgba(6, 8, 11, 0.6);
    background-color: rgba(6, 8, 11, 0.2);
    border-color: rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-soft-dark.disabled,
.btn-soft-dark:disabled {
    color: rgba(19, 27, 36, 0.6);
    background-color: rgba(19, 27, 36, 0.2);
    border-color: transparent;
}

.btn-soft-dark:not(:disabled):not(.disabled):active,
.btn-soft-dark:not(:disabled):not(.disabled).active,
.show>.btn-soft-dark.dropdown-toggle {
    color: rgba(1, 2, 3, 0.6);
    background-color: rgba(1, 2, 3, 0.2);
    border-color: rgba(0, 0, 0, 0);
}

.btn-soft-dark:not(:disabled):not(.disabled):active:focus,
.btn-soft-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-soft-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-soft-black {
    color: rgba(0, 0, 0, 0.6);
    background-color: rgba(0, 0, 0, 0.2);
    border-color: transparent;
}

.btn-soft-black:hover {
    color: rgba(0, 0, 0, 0.6);
    background-color: rgba(0, 0, 0, 0.2);
    border-color: rgba(0, 0, 0, 0);
}

.btn-soft-black:focus,
.btn-soft-black.focus {
    color: rgba(0, 0, 0, 0.6);
    background-color: rgba(0, 0, 0, 0.2);
    border-color: rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-soft-black.disabled,
.btn-soft-black:disabled {
    color: rgba(0, 0, 0, 0.6);
    background-color: rgba(0, 0, 0, 0.2);
    border-color: transparent;
}

.btn-soft-black:not(:disabled):not(.disabled):active,
.btn-soft-black:not(:disabled):not(.disabled).active,
.show>.btn-soft-black.dropdown-toggle {
    color: rgba(0, 0, 0, 0.6);
    background-color: rgba(0, 0, 0, 0.2);
    border-color: rgba(0, 0, 0, 0);
}

.btn-soft-black:not(:disabled):not(.disabled):active:focus,
.btn-soft-black:not(:disabled):not(.disabled).active:focus,
.show>.btn-soft-black.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-soft-white {
    color: rgba(255, 255, 255, 0.6);
    background-color: rgba(255, 255, 255, 0.2);
    border-color: transparent;
}

.btn-soft-white:hover {
    color: rgba(236, 236, 236, 0.6);
    background-color: rgba(236, 236, 236, 0.2);
    border-color: rgba(0, 0, 0, 0);
}

.btn-soft-white:focus,
.btn-soft-white.focus {
    color: rgba(236, 236, 236, 0.6);
    background-color: rgba(236, 236, 236, 0.2);
    border-color: rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-soft-white.disabled,
.btn-soft-white:disabled {
    color: rgba(255, 255, 255, 0.6);
    background-color: rgba(255, 255, 255, 0.2);
    border-color: transparent;
}

.btn-soft-white:not(:disabled):not(.disabled):active,
.btn-soft-white:not(:disabled):not(.disabled).active,
.show>.btn-soft-white.dropdown-toggle {
    color: rgba(230, 230, 230, 0.6);
    background-color: rgba(230, 230, 230, 0.2);
    border-color: rgba(0, 0, 0, 0);
}

.btn-soft-white:not(:disabled):not(.disabled):active:focus,
.btn-soft-white:not(:disabled):not(.disabled).active:focus,
.show>.btn-soft-white.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-primary {
    color: rgba(6, 132, 254, 0.6);
    border-color: rgba(6, 132, 254, 0.6);
}

.btn-outline-primary:hover {
    color: #ffffff;
    background-color: rgba(6, 132, 254, 0.6);
    border-color: rgba(6, 132, 254, 0.6);
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(6, 132, 254, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: rgba(6, 132, 254, 0.6);
    background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
    color: #ffffff;
    background-color: rgba(6, 132, 254, 0.6);
    border-color: rgba(6, 132, 254, 0.6);
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(6, 132, 254, 0.5);
}

.btn-outline-secondary {
    color: rgba(245, 246, 248, 0.6);
    border-color: rgba(245, 246, 248, 0.6);
    color: rgba(0, 0, 0, 0.6);
}

.btn-outline-secondary:hover {
    color: #000000;
    background-color: rgba(245, 246, 248, 0.6);
    border-color: rgba(245, 246, 248, 0.6);
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(245, 246, 248, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
    color: rgba(245, 246, 248, 0.6);
    background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show>.btn-outline-secondary.dropdown-toggle {
    color: #000000;
    background-color: rgba(245, 246, 248, 0.6);
    border-color: rgba(245, 246, 248, 0.6);
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(245, 246, 248, 0.5);
}

.btn-outline-success {
    color: rgba(53, 172, 128, 0.6);
    border-color: rgba(53, 172, 128, 0.6);
}

.btn-outline-success:hover {
    color: #ffffff;
    background-color: rgba(53, 172, 128, 0.6);
    border-color: rgba(53, 172, 128, 0.6);
}

.btn-outline-success:focus,
.btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(53, 172, 128, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
    color: rgba(53, 172, 128, 0.6);
    background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show>.btn-outline-success.dropdown-toggle {
    color: #ffffff;
    background-color: rgba(53, 172, 128, 0.6);
    border-color: rgba(53, 172, 128, 0.6);
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(53, 172, 128, 0.5);
}

.btn-outline-info {
    color: rgba(93, 63, 255, 0.6);
    border-color: rgba(93, 63, 255, 0.6);
}

.btn-outline-info:hover {
    color: #ffffff;
    background-color: rgba(93, 63, 255, 0.6);
    border-color: rgba(93, 63, 255, 0.6);
}

.btn-outline-info:focus,
.btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(93, 63, 255, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
    color: rgba(93, 63, 255, 0.6);
    background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show>.btn-outline-info.dropdown-toggle {
    color: #ffffff;
    background-color: rgba(93, 63, 255, 0.6);
    border-color: rgba(93, 63, 255, 0.6);
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(93, 63, 255, 0.5);
}

.btn-outline-warning {
    color: rgba(247, 183, 49, 0.6);
    border-color: rgba(247, 183, 49, 0.6);
}

.btn-outline-warning:hover {
    color: #000000;
    background-color: rgba(247, 183, 49, 0.6);
    border-color: rgba(247, 183, 49, 0.6);
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(247, 183, 49, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    color: rgba(247, 183, 49, 0.6);
    background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show>.btn-outline-warning.dropdown-toggle {
    color: #000000;
    background-color: rgba(247, 183, 49, 0.6);
    border-color: rgba(247, 183, 49, 0.6);
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(247, 183, 49, 0.5);
}

.btn-outline-danger {
    color: rgba(254, 63, 62, 0.6);
    border-color: rgba(254, 63, 62, 0.6);
}

.btn-outline-danger:hover {
    color: #ffffff;
    background-color: rgba(254, 63, 62, 0.6);
    border-color: rgba(254, 63, 62, 0.6);
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(254, 63, 62, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    color: rgba(254, 63, 62, 0.6);
    background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show>.btn-outline-danger.dropdown-toggle {
    color: #ffffff;
    background-color: rgba(254, 63, 62, 0.6);
    border-color: rgba(254, 63, 62, 0.6);
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(254, 63, 62, 0.5);
}

.btn-outline-light {
    color: rgba(248, 249, 250, 0.6);
    border-color: rgba(248, 249, 250, 0.6);
}

.btn-outline-light:hover {
    color: #000000;
    background-color: rgba(248, 249, 250, 0.6);
    border-color: rgba(248, 249, 250, 0.6);
}

.btn-outline-light:focus,
.btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
    color: rgba(248, 249, 250, 0.6);
    background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show>.btn-outline-light.dropdown-toggle {
    color: #000000;
    background-color: rgba(248, 249, 250, 0.6);
    border-color: rgba(248, 249, 250, 0.6);
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
    color: rgba(19, 27, 36, 0.6);
    border-color: rgba(19, 27, 36, 0.6);
}

.btn-outline-dark:hover {
    color: #ffffff;
    background-color: rgba(19, 27, 36, 0.6);
    border-color: rgba(19, 27, 36, 0.6);
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(19, 27, 36, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
    color: rgba(19, 27, 36, 0.6);
    background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show>.btn-outline-dark.dropdown-toggle {
    color: #ffffff;
    background-color: rgba(19, 27, 36, 0.6);
    border-color: rgba(19, 27, 36, 0.6);
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(19, 27, 36, 0.5);
}

.btn-outline-black {
    color: rgba(0, 0, 0, 0.6);
    border-color: rgba(0, 0, 0, 0.6);
}

.btn-outline-black:hover {
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.6);
    border-color: rgba(0, 0, 0, 0.6);
}

.btn-outline-black:focus,
.btn-outline-black.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-black.disabled,
.btn-outline-black:disabled {
    color: rgba(0, 0, 0, 0.6);
    background-color: transparent;
}

.btn-outline-black:not(:disabled):not(.disabled):active,
.btn-outline-black:not(:disabled):not(.disabled).active,
.show>.btn-outline-black.dropdown-toggle {
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.6);
    border-color: rgba(0, 0, 0, 0.6);
}

.btn-outline-black:not(:disabled):not(.disabled):active:focus,
.btn-outline-black:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-black.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-white {
    color: rgba(255, 255, 255, 0.6);
    border-color: rgba(255, 255, 255, 0.6);
}

.btn-outline-white:hover {
    color: #000000;
    background-color: rgba(255, 255, 255, 0.6);
    border-color: rgba(255, 255, 255, 0.6);
}

.btn-outline-white:focus,
.btn-outline-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-white.disabled,
.btn-outline-white:disabled {
    color: rgba(255, 255, 255, 0.6);
    background-color: transparent;
}

.btn-outline-white:not(:disabled):not(.disabled):active,
.btn-outline-white:not(:disabled):not(.disabled).active,
.show>.btn-outline-white.dropdown-toggle {
    color: #000000;
    background-color: rgba(255, 255, 255, 0.6);
    border-color: rgba(255, 255, 255, 0.6);
}

.btn-outline-white:not(:disabled):not(.disabled):active:focus,
.btn-outline-white:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-white.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.form-card {
    padding: 40px 65px;
}

@media (max-width: 767px) {
    .form-card {
        padding: 20px 30px;
    }
}

.card-shadow {
    box-shadow: 0 2px 30px 0 rgba(207, 207, 207, 0.5);
}

.carousel-indicators.carousel-indicaors-dot li {
    width: 6px;
    height: 6px;
    border-radius: 50%;
}

.carousel-indicators.carousel-indicaors-dot li.active {
    width: 11px;
    height: 11px;
    border-top-width: 7.5px;
    border-bottom-width: 7.5px;
}

@media (max-width: 767px) {
    .testimonial-carpusel {
        text-align: center;
    }
}

@media (min-width: 768px) {
    .testimonial-carpusel .carousel-inner {
        padding-left: 15%;
        padding-right: 15%;
    }
}

.testimonial-carpusel .carousel-control-prev,
.testimonial-carpusel .carousel-control-next {
    color: #000000;
}

@media (max-width: 767px) {
    .testimonial-carpusel .carousel-control-prev,
    .testimonial-carpusel .carousel-control-next {
        position: static;
        display: inline-block;
        margin-top: 15px;
        margin-bottom: 15px;
    }
}

.dropdown-toggle {
    display: flex;
    align-items: center;
    height: 48px;
}

.dropdown-toggle:not(.dropdown-toggle-no-text)::after {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23000000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    display: inline-block;
    width: 16px;
    height: 16px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: 5px;
}

.navbar-dark .dropdown-toggle:not(.dropdown-toggle-no-text)::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}

.dropdown-menu {
    border: 0;
}

.lead-footer {
    padding-top: 40px;
    padding-bottom: 40px;
}

.lead-footer P {
    line-height: 1.5;
}

.lead-footer-dark {
    background: #131b24;
    color: #ffffff;
}

.lead-footer-dark .lead-social-menu a {
    color: inherit;
    display: inline-block;
    line-height: 1;
}

.lead-footer-dark .lead-social-menu a:not(:last-child) {
    margin-right: 16px;
}

.lead-footer-dark .lead-social-menu a .feather {
    height: 20px;
    width: auto;
}

.lead-footer-dark .footer-nav .nav-link {
    color: inherit;
}

.footer-brand-wrapper {
    margin-bottom: 32px;
}

.footer-text {
    line-height: 1.69;
    margin-bottom: 0;
}

.lead-main-footer {
    padding-top: 100px;
    padding-bottom: 130px;
}

.form-control-rounded {
    border-radius: calc(calc(1.33em + 2rem + 2px) / 2);
}

.was-validated .form-control:valid,
.form-control.is-valid {
    color: #35ac80;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    color: #fe3f3e;
}

.input-group {
    border: 1px solid #e9ecf1;
    border-radius: 0.25rem;
}

.input-group .form-control {
    border: 0;
}

.input-group .form-control:not(:first-child) {
    padding-left: 5px;
}

.input-group .form-control:not(:last-child) {
    padding-right: 5px;
}

.input-group-text {
    background-color: transparent;
    border: 0;
}

.input-group-text svg,
.input-group-text img {
    height: 12px;
}

.input-group-rounded {
    border-radius: calc(calc(1.33em + 2rem + 2px) / 2) !important;
}

.input-group-rounded> :first-child {
    border-top-left-radius: calc(calc(1.33em + 2rem + 2px) / 2);
    border-bottom-left-radius: calc(calc(1.33em + 2rem + 2px) / 2);
}

.input-group-rounded> :last-child {
    border-top-right-radius: calc(calc(1.33em + 2rem + 2px) / 2);
    border-bottom-right-radius: calc(calc(1.33em + 2rem + 2px) / 2);
}

.custom-checkbox,
.custom-radio {
    padding-left: 2.875rem;
}

.custom-checkbox .custom-control-label,
.custom-radio .custom-control-label {
    line-height: 1.625rem;
    cursor: pointer;
}

.custom-checkbox .custom-control-label::before,
.custom-radio .custom-control-label::before {
    width: 1.625rem;
    height: 1.625rem;
    left: -2.875rem;
    top: 0;
    border: 1px solid #d9dfe7;
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
}

.custom-checkbox .custom-control-label::after,
.custom-radio .custom-control-label::after {
    width: 1.625rem;
    height: 1.625rem;
    left: -2.875rem;
    top: 0;
    transition: all 0.3s ease-in-out;
}

.custom-checkbox .custom-control-input:disabled~.custom-control-label,
.custom-radio .custom-control-input:disabled~.custom-control-label {
    cursor: default;
}

.custom-checkbox .custom-control-input:disabled~.custom-control-label::before,
.custom-radio .custom-control-input:disabled~.custom-control-label::before {
    opacity: 0.22;
    background-color: #bdbdbd;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before,
.custom-radio .custom-control-input:checked~.custom-control-label::before {
    border: 0;
    opacity: 1;
    background-color: #0684fe;
}

.custom-checkbox .custom-control-input:disabled:checked::before,
.custom-radio .custom-control-input:disabled:checked::before {
    opacity: 0.49;
    background-color: #0684fe;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
}

.custom-radio .custom-control-label::after {
    border: 0;
    opacity: 0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='%23ffffff' stroke='%230684fe' stroke-width='0' stroke-linecap='round' stroke-linejoin='round' class='feather feather-circle'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
    opacity: 1;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='%23ffffff' stroke='%230684fe' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-circle'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::after {
    border: 0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='%23ffffff' stroke='%230684fe' stroke-width='0' stroke-linecap='round' stroke-linejoin='round' class='feather feather-circle'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3C/svg%3E");
}

.custom-switch {
    padding-left: 46px;
}

.custom-switch .custom-control-label {
    line-height: 1.36;
    cursor: pointer;
}

.custom-switch .custom-control-label::before {
    height: 16px;
    width: 35px;
    top: 3px;
    left: -46px;
    opacity: 1;
    border: 1px solid #d9dfe7;
}

.custom-switch .custom-control-label::after {
    height: 12px;
    width: 12px;
    top: 5px;
    left: -44px;
    background-color: #d1d6dc;
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
    transform: translateX(19px);
}

.custom-switch .custom-control-input:disabled~.custom-control-label::before {
    background-color: #efefef;
    border: 1px solid #d1d6dc;
}

.custom-switch .custom-control-input:disabled::checked~.custom-control-label::before {
    opacity: 0.35;
}

.form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-grow: 1;
    flex-shrink: 1;
    max-width: 240px;
}

.form-inline .form-group .form-control {
    width: 100%;
}

.nav-vertical {
    flex-direction: column;
}

.nav-vertical .nav-link {
    padding-left: 0;
    padding-right: 0;
}

.footer-nav .nav-link {
    color: #4e5d78;
    line-height: 1.69;
    padding-top: 0;
    padding-bottom: 0;
}

.lead-social-menu {
    white-space: nowrap;
}

.lead-social-menu a {
    color: inherit;
    display: inline-block;
    line-height: 1;
}

.lead-social-menu a:not(:last-child) {
    margin-right: 16px;
}

.lead-social-menu a .feather {
    height: 20px;
    width: auto;
}

.navbar-light.bg-white {
    box-shadow: 0 2px 30px 0 rgba(207, 207, 207, 0.5);
}

.navbar-light .navbar-toggler {
    color: #000000;
}

.navbar-light .navbar-nav .nav-link {
    color: #000000;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
    color: #000000;
}

.navbar-light.navbar-lg {
    padding-top: 40px;
    padding-bottom: 40px;
}

.navbar-dark .navbar-toggler {
    color: #ffffff;
}

.navbar-dark .navbar-nav .nav-link {
    color: #ffffff;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
    color: #ffffff;
}

.navbar-dark.navbar-lg {
    padding-top: 40px;
    padding-bottom: 40px;
}

.navbar-dark .navbar-nav .dropdown-menu .dropdown-item .nav-link {
    color: inherit;
}

.navbar-toggler {
    border: 0;
}

.nav-item .btn {
    margin-left: 1rem;
    margin-right: 1rem;
}

.navbar .navbar-nav .nav-link {
    font-size: 1rem;
    line-height: 1.5;
    padding: 0.75rem 0.937rem;
}

.navbar .navbar-nav .dropdown-item {
    font-size: 14px;
}

.navbar .navbar-nav .dropdown-item .nav-link {
    font-size: 14px;
}

.pagination .page-item {
    margin-right: 5px;
}

.pagination .page-item:last-child {
    margin-right: 0;
}

.page-link {
    border-radius: 0.25rem;
    border: 1px solid #0684fe;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.33;
    color: #0684fe;
}

.page-link .feather {
    height: 12px;
    width: 12px;
}

.pagination-filled .page-link {
    background-color: #0684fe;
    color: #ffffff;
}

.pagination-filled .page-link:hover {
    background-color: #016ad0;
    border: 1px solid #016ad0;
}

.pagination-filled .page-link.active {
    background-color: transparent;
    color: #0684fe;
}

.pagination-rounded .page-link {
    border-radius: 10rem;
}

.pagination-rounded .page-link:first-child,
.pagination-rounded .page-link:last-child {
    border-radius: 10rem;
}

.nav-pills.pricing-nav-pills-outline {
    justify-content: center;
}

.nav-pills.pricing-nav-pills-outline .nav-link {
    background-color: #ffffff;
    border: 1px solid #ececec;
    padding: 17px 26px;
    border-radius: 0;
}

.nav-pills.pricing-nav-pills-outline .nav-link:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.nav-pills.pricing-nav-pills-outline .nav-link:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.nav-pills.pricing-nav-pills-outline .nav-item :first-child:last-child {
    border-radius: 0;
}

.nav-pills.pricing-nav-pills-outline .nav-item:first-child .nav-link {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.nav-pills.pricing-nav-pills-outline .nav-item:last-child .nav-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.nav-pills.pricing-nav-pills-outline .show>.nav-link,
.nav-pills.pricing-nav-pills-outline .nav-link.active {
    background-color: #ffffff;
    border-color: #35ac80;
    color: #35ac80;
}

.nav-pills.pricing-nav-pills-boxed {
    justify-content: center;
}

.nav-pills.pricing-nav-pills-boxed .nav-link {
    border-style: solid;
    border-color: #f7f8fb;
    border-width: 6px 0;
    background-color: #f7f8fb;
    padding: 17px 26px;
    border-radius: 0;
}

.nav-pills.pricing-nav-pills-boxed .nav-link:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.nav-pills.pricing-nav-pills-boxed .nav-link:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.nav-pills.pricing-nav-pills-boxed .nav-item :first-child:last-child {
    border-radius: 0;
}

.nav-pills.pricing-nav-pills-boxed .nav-item:first-child .nav-link {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-left-width: 10px;
}

.nav-pills.pricing-nav-pills-boxed .nav-item:last-child .nav-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-right-width: 10px;
}

.nav-pills.pricing-nav-pills-boxed .show>.nav-link,
.nav-pills.pricing-nav-pills-boxed .nav-link.active {
    background-color: #ffffff;
    color: #000000;
}

.nav-pills.pricing-nav-pills-switch {
    justify-content: center;
}

.nav-pills.pricing-nav-pills-switch .nav-link {
    background-color: #ffffff;
    border: 1px solid #ededed;
    padding: 17px 30px;
    border-radius: 0;
}

.nav-pills.pricing-nav-pills-switch .nav-link:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.nav-pills.pricing-nav-pills-switch .nav-link:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.nav-pills.pricing-nav-pills-switch .nav-link:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.nav-pills.pricing-nav-pills-switch .nav-link:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.nav-pills.pricing-nav-pills-switch .nav-item :first-child:last-child {
    border-radius: 0;
}

.nav-pills.pricing-nav-pills-switch .nav-item:first-child .nav-link {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.nav-pills.pricing-nav-pills-switch .nav-item:last-child .nav-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.nav-pills.pricing-nav-pills-switch .show>.nav-link,
.nav-pills.pricing-nav-pills-switch .nav-link.active {
    background-color: #ededed;
    color: #000000;
}

.pricing-card-stack {
    padding-top: 75px;
}

.pricing-card-stack .featured {
    z-index: 2;
}

@media (min-width: 991px) {
    .pricing-card-stack .featured {
        transform: scale(1.25);
    }
    .pricing-card-stack .featured .card-body {
        transform: scale(calc(1 / 1.25));
    }
}

.tab-pane.active {
    -webkit-animation: slide-down 0.5s ease-out;
    animation: slide-down 0.5s ease-out;
}

@-webkit-keyframes slide-down {
    0% {
        opacity: 0;
        transform: translateY(15%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slide-down {
    0% {
        opacity: 0;
        transform: translateY(15%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.nav-stacked {
    flex-direction: column;
}

.nav-pills-stacked {
    flex-direction: column;
}

.nav-pills-stacked .nav-link {
    margin-bottom: 13px;
}

.nav-pills-stacked .nav-link p {
    color: #4e5d78;
}

.nav-pills-stacked .nav-link+.nav-link:last-child {
    margin-bottom: 0;
}

.nav-pills-stacked .nav-item:last-child .nav-link {
    margin-bottom: 0;
}

.nav-pills .nav-link {
    background-color: #f5f6f8;
    padding: 20px 30px;
    color: #000000;
}

@media (max-width: 575px) {
    .nav-pills .nav-link {
        padding: 15px;
    }
}

.nav-pills .nav-link.disabled {
    color: rgba(0, 0, 0, 0.6);
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: #0684fe;
}

.nav-pills .nav-link.active p,
.nav-pills .show>.nav-link p {
    color: inherit;
}

.nav-tabs .nav-link {
    padding: 16px 24px;
    color: #4e5d78;
    font-weight: 400;
    border-top-width: 2px;
}

.nav-tabs .nav-link:hover {
    border-color: transparent;
    border-bottom-color: #0684fe;
    transition: border 0.3 ease-in-out;
}

.nav-tabs .nav-link.active,
.nav-tabs .show>.nav-link {
    color: #000000;
    font-weight: 600;
    border-top-color: #0684fe;
}

.nav-tabs .nav-link.active:hover,
.nav-tabs .show>.nav-link:hover {
    border-color: #0684fe #e9ecf1 transparent;
}

.nav-pills-primary .nav-link.active,
.nav-pills-primary .show>.nav-link {
    color: #ffffff;
    background-color: #0684fe;
}

.nav-pills-secondary .nav-link.active,
.nav-pills-secondary .show>.nav-link {
    color: #000000;
    background-color: #f5f6f8;
}

.nav-pills-success .nav-link.active,
.nav-pills-success .show>.nav-link {
    color: #ffffff;
    background-color: #35ac80;
}

.nav-pills-info .nav-link.active,
.nav-pills-info .show>.nav-link {
    color: #ffffff;
    background-color: #5d3fff;
}

.nav-pills-warning .nav-link.active,
.nav-pills-warning .show>.nav-link {
    color: #000000;
    background-color: #f7b731;
}

.nav-pills-danger .nav-link.active,
.nav-pills-danger .show>.nav-link {
    color: #ffffff;
    background-color: #fe3f3e;
}

.nav-pills-light .nav-link.active,
.nav-pills-light .show>.nav-link {
    color: #000000;
    background-color: #f8f9fa;
}

.nav-pills-dark .nav-link.active,
.nav-pills-dark .show>.nav-link {
    color: #ffffff;
    background-color: #131b24;
}

.nav-pills-black .nav-link.active,
.nav-pills-black .show>.nav-link {
    color: #ffffff;
    background-color: #000000;
}

.nav-pills-white .nav-link.active,
.nav-pills-white .show>.nav-link {
    color: #000000;
    background-color: #ffffff;
}

.nav-tabs-collapse {
    flex-direction: column;
    border-bottom: none;
    border-left: 1px solid #e9ecf1;
}

.nav-tabs-collapse .nav-item {
    margin-bottom: 0;
    margin-left: -1px;
}

.nav-tabs-collapse .nav-link {
    border-top-width: 1px;
    border-right-width: 2px;
}

.nav-tabs-collapse .nav-link:hover {
    border-bottom-color: transparent;
    border-left-color: #0684fe;
}

.nav-tabs-collapse .nav-link.active,
.nav-tabs-collapse .show>.nav-link {
    border-color: #e9ecf1 #0684fe #e9ecf1 #ffffff;
}

.nav-tabs-collapse .nav-link.active:hover,
.nav-tabs-collapse .show>.nav-link:hover {
    border-color: #e9ecf1 #0684fe #e9ecf1 transparent;
}

.nav-tabs-collapse.nav-tabs-primary .nav-link:hover {
    border-bottom-color: transparent;
    border-left-color: #0684fe;
}

.nav-tabs-collapse.nav-tabs-primary .nav-link.active,
.nav-tabs-collapse.nav-tabs-primary .show>.nav-link {
    border-color: #e9ecf1 #0684fe #e9ecf1 #ffffff;
}

.nav-tabs-collapse.nav-tabs-primary .nav-link.active:hover,
.nav-tabs-collapse.nav-tabs-primary .show>.nav-link:hover {
    border-color: #e9ecf1 #0684fe #e9ecf1 transparent;
}

.nav-tabs-collapse.nav-tabs-secondary .nav-link:hover {
    border-bottom-color: transparent;
    border-left-color: #f5f6f8;
}

.nav-tabs-collapse.nav-tabs-secondary .nav-link.active,
.nav-tabs-collapse.nav-tabs-secondary .show>.nav-link {
    border-color: #e9ecf1 #f5f6f8 #e9ecf1 #ffffff;
}

.nav-tabs-collapse.nav-tabs-secondary .nav-link.active:hover,
.nav-tabs-collapse.nav-tabs-secondary .show>.nav-link:hover {
    border-color: #e9ecf1 #f5f6f8 #e9ecf1 transparent;
}

.nav-tabs-collapse.nav-tabs-success .nav-link:hover {
    border-bottom-color: transparent;
    border-left-color: #35ac80;
}

.nav-tabs-collapse.nav-tabs-success .nav-link.active,
.nav-tabs-collapse.nav-tabs-success .show>.nav-link {
    border-color: #e9ecf1 #35ac80 #e9ecf1 #ffffff;
}

.nav-tabs-collapse.nav-tabs-success .nav-link.active:hover,
.nav-tabs-collapse.nav-tabs-success .show>.nav-link:hover {
    border-color: #e9ecf1 #35ac80 #e9ecf1 transparent;
}

.nav-tabs-collapse.nav-tabs-info .nav-link:hover {
    border-bottom-color: transparent;
    border-left-color: #5d3fff;
}

.nav-tabs-collapse.nav-tabs-info .nav-link.active,
.nav-tabs-collapse.nav-tabs-info .show>.nav-link {
    border-color: #e9ecf1 #5d3fff #e9ecf1 #ffffff;
}

.nav-tabs-collapse.nav-tabs-info .nav-link.active:hover,
.nav-tabs-collapse.nav-tabs-info .show>.nav-link:hover {
    border-color: #e9ecf1 #5d3fff #e9ecf1 transparent;
}

.nav-tabs-collapse.nav-tabs-warning .nav-link:hover {
    border-bottom-color: transparent;
    border-left-color: #f7b731;
}

.nav-tabs-collapse.nav-tabs-warning .nav-link.active,
.nav-tabs-collapse.nav-tabs-warning .show>.nav-link {
    border-color: #e9ecf1 #f7b731 #e9ecf1 #ffffff;
}

.nav-tabs-collapse.nav-tabs-warning .nav-link.active:hover,
.nav-tabs-collapse.nav-tabs-warning .show>.nav-link:hover {
    border-color: #e9ecf1 #f7b731 #e9ecf1 transparent;
}

.nav-tabs-collapse.nav-tabs-danger .nav-link:hover {
    border-bottom-color: transparent;
    border-left-color: #fe3f3e;
}

.nav-tabs-collapse.nav-tabs-danger .nav-link.active,
.nav-tabs-collapse.nav-tabs-danger .show>.nav-link {
    border-color: #e9ecf1 #fe3f3e #e9ecf1 #ffffff;
}

.nav-tabs-collapse.nav-tabs-danger .nav-link.active:hover,
.nav-tabs-collapse.nav-tabs-danger .show>.nav-link:hover {
    border-color: #e9ecf1 #fe3f3e #e9ecf1 transparent;
}

.nav-tabs-collapse.nav-tabs-light .nav-link:hover {
    border-bottom-color: transparent;
    border-left-color: #f8f9fa;
}

.nav-tabs-collapse.nav-tabs-light .nav-link.active,
.nav-tabs-collapse.nav-tabs-light .show>.nav-link {
    border-color: #e9ecf1 #f8f9fa #e9ecf1 #ffffff;
}

.nav-tabs-collapse.nav-tabs-light .nav-link.active:hover,
.nav-tabs-collapse.nav-tabs-light .show>.nav-link:hover {
    border-color: #e9ecf1 #f8f9fa #e9ecf1 transparent;
}

.nav-tabs-collapse.nav-tabs-dark .nav-link:hover {
    border-bottom-color: transparent;
    border-left-color: #131b24;
}

.nav-tabs-collapse.nav-tabs-dark .nav-link.active,
.nav-tabs-collapse.nav-tabs-dark .show>.nav-link {
    border-color: #e9ecf1 #131b24 #e9ecf1 #ffffff;
}

.nav-tabs-collapse.nav-tabs-dark .nav-link.active:hover,
.nav-tabs-collapse.nav-tabs-dark .show>.nav-link:hover {
    border-color: #e9ecf1 #131b24 #e9ecf1 transparent;
}

.nav-tabs-collapse.nav-tabs-black .nav-link:hover {
    border-bottom-color: transparent;
    border-left-color: #000000;
}

.nav-tabs-collapse.nav-tabs-black .nav-link.active,
.nav-tabs-collapse.nav-tabs-black .show>.nav-link {
    border-color: #e9ecf1 #000000 #e9ecf1 #ffffff;
}

.nav-tabs-collapse.nav-tabs-black .nav-link.active:hover,
.nav-tabs-collapse.nav-tabs-black .show>.nav-link:hover {
    border-color: #e9ecf1 #000000 #e9ecf1 transparent;
}

.nav-tabs-collapse.nav-tabs-white .nav-link:hover {
    border-bottom-color: transparent;
    border-left-color: #ffffff;
}

.nav-tabs-collapse.nav-tabs-white .nav-link.active,
.nav-tabs-collapse.nav-tabs-white .show>.nav-link {
    border-color: #e9ecf1 #ffffff #e9ecf1 #ffffff;
}

.nav-tabs-collapse.nav-tabs-white .nav-link.active:hover,
.nav-tabs-collapse.nav-tabs-white .show>.nav-link:hover {
    border-color: #e9ecf1 #ffffff #e9ecf1 transparent;
}

@media (max-width: 575.98px) {
    .nav-tabs-collapse-xs {
        flex-direction: column;
        border-bottom: none;
        border-left: 1px solid #e9ecf1;
    }
    .nav-tabs-collapse-xs .nav-item {
        margin-bottom: 0;
        margin-left: -1px;
    }
    .nav-tabs-collapse-xs .nav-link {
        border-top-width: 1px;
        border-right-width: 2px;
    }
    .nav-tabs-collapse-xs .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #0684fe;
    }
    .nav-tabs-collapse-xs .nav-link.active,
    .nav-tabs-collapse-xs .show>.nav-link {
        border-color: #e9ecf1 #0684fe #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-xs .nav-link.active:hover,
    .nav-tabs-collapse-xs .show>.nav-link:hover {
        border-color: #e9ecf1 #0684fe #e9ecf1 transparent;
    }
    .nav-tabs-collapse-xs.nav-tabs-primary .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #0684fe;
    }
    .nav-tabs-collapse-xs.nav-tabs-primary .nav-link.active,
    .nav-tabs-collapse-xs.nav-tabs-primary .show>.nav-link {
        border-color: #e9ecf1 #0684fe #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-xs.nav-tabs-primary .nav-link.active:hover,
    .nav-tabs-collapse-xs.nav-tabs-primary .show>.nav-link:hover {
        border-color: #e9ecf1 #0684fe #e9ecf1 transparent;
    }
    .nav-tabs-collapse-xs.nav-tabs-secondary .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #f5f6f8;
    }
    .nav-tabs-collapse-xs.nav-tabs-secondary .nav-link.active,
    .nav-tabs-collapse-xs.nav-tabs-secondary .show>.nav-link {
        border-color: #e9ecf1 #f5f6f8 #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-xs.nav-tabs-secondary .nav-link.active:hover,
    .nav-tabs-collapse-xs.nav-tabs-secondary .show>.nav-link:hover {
        border-color: #e9ecf1 #f5f6f8 #e9ecf1 transparent;
    }
    .nav-tabs-collapse-xs.nav-tabs-success .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #35ac80;
    }
    .nav-tabs-collapse-xs.nav-tabs-success .nav-link.active,
    .nav-tabs-collapse-xs.nav-tabs-success .show>.nav-link {
        border-color: #e9ecf1 #35ac80 #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-xs.nav-tabs-success .nav-link.active:hover,
    .nav-tabs-collapse-xs.nav-tabs-success .show>.nav-link:hover {
        border-color: #e9ecf1 #35ac80 #e9ecf1 transparent;
    }
    .nav-tabs-collapse-xs.nav-tabs-info .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #5d3fff;
    }
    .nav-tabs-collapse-xs.nav-tabs-info .nav-link.active,
    .nav-tabs-collapse-xs.nav-tabs-info .show>.nav-link {
        border-color: #e9ecf1 #5d3fff #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-xs.nav-tabs-info .nav-link.active:hover,
    .nav-tabs-collapse-xs.nav-tabs-info .show>.nav-link:hover {
        border-color: #e9ecf1 #5d3fff #e9ecf1 transparent;
    }
    .nav-tabs-collapse-xs.nav-tabs-warning .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #f7b731;
    }
    .nav-tabs-collapse-xs.nav-tabs-warning .nav-link.active,
    .nav-tabs-collapse-xs.nav-tabs-warning .show>.nav-link {
        border-color: #e9ecf1 #f7b731 #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-xs.nav-tabs-warning .nav-link.active:hover,
    .nav-tabs-collapse-xs.nav-tabs-warning .show>.nav-link:hover {
        border-color: #e9ecf1 #f7b731 #e9ecf1 transparent;
    }
    .nav-tabs-collapse-xs.nav-tabs-danger .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #fe3f3e;
    }
    .nav-tabs-collapse-xs.nav-tabs-danger .nav-link.active,
    .nav-tabs-collapse-xs.nav-tabs-danger .show>.nav-link {
        border-color: #e9ecf1 #fe3f3e #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-xs.nav-tabs-danger .nav-link.active:hover,
    .nav-tabs-collapse-xs.nav-tabs-danger .show>.nav-link:hover {
        border-color: #e9ecf1 #fe3f3e #e9ecf1 transparent;
    }
    .nav-tabs-collapse-xs.nav-tabs-light .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #f8f9fa;
    }
    .nav-tabs-collapse-xs.nav-tabs-light .nav-link.active,
    .nav-tabs-collapse-xs.nav-tabs-light .show>.nav-link {
        border-color: #e9ecf1 #f8f9fa #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-xs.nav-tabs-light .nav-link.active:hover,
    .nav-tabs-collapse-xs.nav-tabs-light .show>.nav-link:hover {
        border-color: #e9ecf1 #f8f9fa #e9ecf1 transparent;
    }
    .nav-tabs-collapse-xs.nav-tabs-dark .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #131b24;
    }
    .nav-tabs-collapse-xs.nav-tabs-dark .nav-link.active,
    .nav-tabs-collapse-xs.nav-tabs-dark .show>.nav-link {
        border-color: #e9ecf1 #131b24 #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-xs.nav-tabs-dark .nav-link.active:hover,
    .nav-tabs-collapse-xs.nav-tabs-dark .show>.nav-link:hover {
        border-color: #e9ecf1 #131b24 #e9ecf1 transparent;
    }
    .nav-tabs-collapse-xs.nav-tabs-black .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #000000;
    }
    .nav-tabs-collapse-xs.nav-tabs-black .nav-link.active,
    .nav-tabs-collapse-xs.nav-tabs-black .show>.nav-link {
        border-color: #e9ecf1 #000000 #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-xs.nav-tabs-black .nav-link.active:hover,
    .nav-tabs-collapse-xs.nav-tabs-black .show>.nav-link:hover {
        border-color: #e9ecf1 #000000 #e9ecf1 transparent;
    }
    .nav-tabs-collapse-xs.nav-tabs-white .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #ffffff;
    }
    .nav-tabs-collapse-xs.nav-tabs-white .nav-link.active,
    .nav-tabs-collapse-xs.nav-tabs-white .show>.nav-link {
        border-color: #e9ecf1 #ffffff #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-xs.nav-tabs-white .nav-link.active:hover,
    .nav-tabs-collapse-xs.nav-tabs-white .show>.nav-link:hover {
        border-color: #e9ecf1 #ffffff #e9ecf1 transparent;
    }
}

@media (max-width: 767.98px) {
    .nav-tabs-collapse-sm {
        flex-direction: column;
        border-bottom: none;
        border-left: 1px solid #e9ecf1;
    }
    .nav-tabs-collapse-sm .nav-item {
        margin-bottom: 0;
        margin-left: -1px;
    }
    .nav-tabs-collapse-sm .nav-link {
        border-top-width: 1px;
        border-right-width: 2px;
    }
    .nav-tabs-collapse-sm .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #0684fe;
    }
    .nav-tabs-collapse-sm .nav-link.active,
    .nav-tabs-collapse-sm .show>.nav-link {
        border-color: #e9ecf1 #0684fe #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-sm .nav-link.active:hover,
    .nav-tabs-collapse-sm .show>.nav-link:hover {
        border-color: #e9ecf1 #0684fe #e9ecf1 transparent;
    }
    .nav-tabs-collapse-sm.nav-tabs-primary .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #0684fe;
    }
    .nav-tabs-collapse-sm.nav-tabs-primary .nav-link.active,
    .nav-tabs-collapse-sm.nav-tabs-primary .show>.nav-link {
        border-color: #e9ecf1 #0684fe #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-sm.nav-tabs-primary .nav-link.active:hover,
    .nav-tabs-collapse-sm.nav-tabs-primary .show>.nav-link:hover {
        border-color: #e9ecf1 #0684fe #e9ecf1 transparent;
    }
    .nav-tabs-collapse-sm.nav-tabs-secondary .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #f5f6f8;
    }
    .nav-tabs-collapse-sm.nav-tabs-secondary .nav-link.active,
    .nav-tabs-collapse-sm.nav-tabs-secondary .show>.nav-link {
        border-color: #e9ecf1 #f5f6f8 #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-sm.nav-tabs-secondary .nav-link.active:hover,
    .nav-tabs-collapse-sm.nav-tabs-secondary .show>.nav-link:hover {
        border-color: #e9ecf1 #f5f6f8 #e9ecf1 transparent;
    }
    .nav-tabs-collapse-sm.nav-tabs-success .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #35ac80;
    }
    .nav-tabs-collapse-sm.nav-tabs-success .nav-link.active,
    .nav-tabs-collapse-sm.nav-tabs-success .show>.nav-link {
        border-color: #e9ecf1 #35ac80 #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-sm.nav-tabs-success .nav-link.active:hover,
    .nav-tabs-collapse-sm.nav-tabs-success .show>.nav-link:hover {
        border-color: #e9ecf1 #35ac80 #e9ecf1 transparent;
    }
    .nav-tabs-collapse-sm.nav-tabs-info .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #5d3fff;
    }
    .nav-tabs-collapse-sm.nav-tabs-info .nav-link.active,
    .nav-tabs-collapse-sm.nav-tabs-info .show>.nav-link {
        border-color: #e9ecf1 #5d3fff #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-sm.nav-tabs-info .nav-link.active:hover,
    .nav-tabs-collapse-sm.nav-tabs-info .show>.nav-link:hover {
        border-color: #e9ecf1 #5d3fff #e9ecf1 transparent;
    }
    .nav-tabs-collapse-sm.nav-tabs-warning .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #f7b731;
    }
    .nav-tabs-collapse-sm.nav-tabs-warning .nav-link.active,
    .nav-tabs-collapse-sm.nav-tabs-warning .show>.nav-link {
        border-color: #e9ecf1 #f7b731 #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-sm.nav-tabs-warning .nav-link.active:hover,
    .nav-tabs-collapse-sm.nav-tabs-warning .show>.nav-link:hover {
        border-color: #e9ecf1 #f7b731 #e9ecf1 transparent;
    }
    .nav-tabs-collapse-sm.nav-tabs-danger .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #fe3f3e;
    }
    .nav-tabs-collapse-sm.nav-tabs-danger .nav-link.active,
    .nav-tabs-collapse-sm.nav-tabs-danger .show>.nav-link {
        border-color: #e9ecf1 #fe3f3e #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-sm.nav-tabs-danger .nav-link.active:hover,
    .nav-tabs-collapse-sm.nav-tabs-danger .show>.nav-link:hover {
        border-color: #e9ecf1 #fe3f3e #e9ecf1 transparent;
    }
    .nav-tabs-collapse-sm.nav-tabs-light .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #f8f9fa;
    }
    .nav-tabs-collapse-sm.nav-tabs-light .nav-link.active,
    .nav-tabs-collapse-sm.nav-tabs-light .show>.nav-link {
        border-color: #e9ecf1 #f8f9fa #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-sm.nav-tabs-light .nav-link.active:hover,
    .nav-tabs-collapse-sm.nav-tabs-light .show>.nav-link:hover {
        border-color: #e9ecf1 #f8f9fa #e9ecf1 transparent;
    }
    .nav-tabs-collapse-sm.nav-tabs-dark .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #131b24;
    }
    .nav-tabs-collapse-sm.nav-tabs-dark .nav-link.active,
    .nav-tabs-collapse-sm.nav-tabs-dark .show>.nav-link {
        border-color: #e9ecf1 #131b24 #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-sm.nav-tabs-dark .nav-link.active:hover,
    .nav-tabs-collapse-sm.nav-tabs-dark .show>.nav-link:hover {
        border-color: #e9ecf1 #131b24 #e9ecf1 transparent;
    }
    .nav-tabs-collapse-sm.nav-tabs-black .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #000000;
    }
    .nav-tabs-collapse-sm.nav-tabs-black .nav-link.active,
    .nav-tabs-collapse-sm.nav-tabs-black .show>.nav-link {
        border-color: #e9ecf1 #000000 #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-sm.nav-tabs-black .nav-link.active:hover,
    .nav-tabs-collapse-sm.nav-tabs-black .show>.nav-link:hover {
        border-color: #e9ecf1 #000000 #e9ecf1 transparent;
    }
    .nav-tabs-collapse-sm.nav-tabs-white .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #ffffff;
    }
    .nav-tabs-collapse-sm.nav-tabs-white .nav-link.active,
    .nav-tabs-collapse-sm.nav-tabs-white .show>.nav-link {
        border-color: #e9ecf1 #ffffff #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-sm.nav-tabs-white .nav-link.active:hover,
    .nav-tabs-collapse-sm.nav-tabs-white .show>.nav-link:hover {
        border-color: #e9ecf1 #ffffff #e9ecf1 transparent;
    }
}

@media (max-width: 991.98px) {
    .nav-tabs-collapse-md {
        flex-direction: column;
        border-bottom: none;
        border-left: 1px solid #e9ecf1;
    }
    .nav-tabs-collapse-md .nav-item {
        margin-bottom: 0;
        margin-left: -1px;
    }
    .nav-tabs-collapse-md .nav-link {
        border-top-width: 1px;
        border-right-width: 2px;
    }
    .nav-tabs-collapse-md .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #0684fe;
    }
    .nav-tabs-collapse-md .nav-link.active,
    .nav-tabs-collapse-md .show>.nav-link {
        border-color: #e9ecf1 #0684fe #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-md .nav-link.active:hover,
    .nav-tabs-collapse-md .show>.nav-link:hover {
        border-color: #e9ecf1 #0684fe #e9ecf1 transparent;
    }
    .nav-tabs-collapse-md.nav-tabs-primary .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #0684fe;
    }
    .nav-tabs-collapse-md.nav-tabs-primary .nav-link.active,
    .nav-tabs-collapse-md.nav-tabs-primary .show>.nav-link {
        border-color: #e9ecf1 #0684fe #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-md.nav-tabs-primary .nav-link.active:hover,
    .nav-tabs-collapse-md.nav-tabs-primary .show>.nav-link:hover {
        border-color: #e9ecf1 #0684fe #e9ecf1 transparent;
    }
    .nav-tabs-collapse-md.nav-tabs-secondary .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #f5f6f8;
    }
    .nav-tabs-collapse-md.nav-tabs-secondary .nav-link.active,
    .nav-tabs-collapse-md.nav-tabs-secondary .show>.nav-link {
        border-color: #e9ecf1 #f5f6f8 #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-md.nav-tabs-secondary .nav-link.active:hover,
    .nav-tabs-collapse-md.nav-tabs-secondary .show>.nav-link:hover {
        border-color: #e9ecf1 #f5f6f8 #e9ecf1 transparent;
    }
    .nav-tabs-collapse-md.nav-tabs-success .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #35ac80;
    }
    .nav-tabs-collapse-md.nav-tabs-success .nav-link.active,
    .nav-tabs-collapse-md.nav-tabs-success .show>.nav-link {
        border-color: #e9ecf1 #35ac80 #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-md.nav-tabs-success .nav-link.active:hover,
    .nav-tabs-collapse-md.nav-tabs-success .show>.nav-link:hover {
        border-color: #e9ecf1 #35ac80 #e9ecf1 transparent;
    }
    .nav-tabs-collapse-md.nav-tabs-info .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #5d3fff;
    }
    .nav-tabs-collapse-md.nav-tabs-info .nav-link.active,
    .nav-tabs-collapse-md.nav-tabs-info .show>.nav-link {
        border-color: #e9ecf1 #5d3fff #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-md.nav-tabs-info .nav-link.active:hover,
    .nav-tabs-collapse-md.nav-tabs-info .show>.nav-link:hover {
        border-color: #e9ecf1 #5d3fff #e9ecf1 transparent;
    }
    .nav-tabs-collapse-md.nav-tabs-warning .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #f7b731;
    }
    .nav-tabs-collapse-md.nav-tabs-warning .nav-link.active,
    .nav-tabs-collapse-md.nav-tabs-warning .show>.nav-link {
        border-color: #e9ecf1 #f7b731 #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-md.nav-tabs-warning .nav-link.active:hover,
    .nav-tabs-collapse-md.nav-tabs-warning .show>.nav-link:hover {
        border-color: #e9ecf1 #f7b731 #e9ecf1 transparent;
    }
    .nav-tabs-collapse-md.nav-tabs-danger .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #fe3f3e;
    }
    .nav-tabs-collapse-md.nav-tabs-danger .nav-link.active,
    .nav-tabs-collapse-md.nav-tabs-danger .show>.nav-link {
        border-color: #e9ecf1 #fe3f3e #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-md.nav-tabs-danger .nav-link.active:hover,
    .nav-tabs-collapse-md.nav-tabs-danger .show>.nav-link:hover {
        border-color: #e9ecf1 #fe3f3e #e9ecf1 transparent;
    }
    .nav-tabs-collapse-md.nav-tabs-light .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #f8f9fa;
    }
    .nav-tabs-collapse-md.nav-tabs-light .nav-link.active,
    .nav-tabs-collapse-md.nav-tabs-light .show>.nav-link {
        border-color: #e9ecf1 #f8f9fa #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-md.nav-tabs-light .nav-link.active:hover,
    .nav-tabs-collapse-md.nav-tabs-light .show>.nav-link:hover {
        border-color: #e9ecf1 #f8f9fa #e9ecf1 transparent;
    }
    .nav-tabs-collapse-md.nav-tabs-dark .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #131b24;
    }
    .nav-tabs-collapse-md.nav-tabs-dark .nav-link.active,
    .nav-tabs-collapse-md.nav-tabs-dark .show>.nav-link {
        border-color: #e9ecf1 #131b24 #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-md.nav-tabs-dark .nav-link.active:hover,
    .nav-tabs-collapse-md.nav-tabs-dark .show>.nav-link:hover {
        border-color: #e9ecf1 #131b24 #e9ecf1 transparent;
    }
    .nav-tabs-collapse-md.nav-tabs-black .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #000000;
    }
    .nav-tabs-collapse-md.nav-tabs-black .nav-link.active,
    .nav-tabs-collapse-md.nav-tabs-black .show>.nav-link {
        border-color: #e9ecf1 #000000 #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-md.nav-tabs-black .nav-link.active:hover,
    .nav-tabs-collapse-md.nav-tabs-black .show>.nav-link:hover {
        border-color: #e9ecf1 #000000 #e9ecf1 transparent;
    }
    .nav-tabs-collapse-md.nav-tabs-white .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #ffffff;
    }
    .nav-tabs-collapse-md.nav-tabs-white .nav-link.active,
    .nav-tabs-collapse-md.nav-tabs-white .show>.nav-link {
        border-color: #e9ecf1 #ffffff #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-md.nav-tabs-white .nav-link.active:hover,
    .nav-tabs-collapse-md.nav-tabs-white .show>.nav-link:hover {
        border-color: #e9ecf1 #ffffff #e9ecf1 transparent;
    }
}

@media (max-width: 1199.98px) {
    .nav-tabs-collapse-lg {
        flex-direction: column;
        border-bottom: none;
        border-left: 1px solid #e9ecf1;
    }
    .nav-tabs-collapse-lg .nav-item {
        margin-bottom: 0;
        margin-left: -1px;
    }
    .nav-tabs-collapse-lg .nav-link {
        border-top-width: 1px;
        border-right-width: 2px;
    }
    .nav-tabs-collapse-lg .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #0684fe;
    }
    .nav-tabs-collapse-lg .nav-link.active,
    .nav-tabs-collapse-lg .show>.nav-link {
        border-color: #e9ecf1 #0684fe #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-lg .nav-link.active:hover,
    .nav-tabs-collapse-lg .show>.nav-link:hover {
        border-color: #e9ecf1 #0684fe #e9ecf1 transparent;
    }
    .nav-tabs-collapse-lg.nav-tabs-primary .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #0684fe;
    }
    .nav-tabs-collapse-lg.nav-tabs-primary .nav-link.active,
    .nav-tabs-collapse-lg.nav-tabs-primary .show>.nav-link {
        border-color: #e9ecf1 #0684fe #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-lg.nav-tabs-primary .nav-link.active:hover,
    .nav-tabs-collapse-lg.nav-tabs-primary .show>.nav-link:hover {
        border-color: #e9ecf1 #0684fe #e9ecf1 transparent;
    }
    .nav-tabs-collapse-lg.nav-tabs-secondary .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #f5f6f8;
    }
    .nav-tabs-collapse-lg.nav-tabs-secondary .nav-link.active,
    .nav-tabs-collapse-lg.nav-tabs-secondary .show>.nav-link {
        border-color: #e9ecf1 #f5f6f8 #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-lg.nav-tabs-secondary .nav-link.active:hover,
    .nav-tabs-collapse-lg.nav-tabs-secondary .show>.nav-link:hover {
        border-color: #e9ecf1 #f5f6f8 #e9ecf1 transparent;
    }
    .nav-tabs-collapse-lg.nav-tabs-success .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #35ac80;
    }
    .nav-tabs-collapse-lg.nav-tabs-success .nav-link.active,
    .nav-tabs-collapse-lg.nav-tabs-success .show>.nav-link {
        border-color: #e9ecf1 #35ac80 #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-lg.nav-tabs-success .nav-link.active:hover,
    .nav-tabs-collapse-lg.nav-tabs-success .show>.nav-link:hover {
        border-color: #e9ecf1 #35ac80 #e9ecf1 transparent;
    }
    .nav-tabs-collapse-lg.nav-tabs-info .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #5d3fff;
    }
    .nav-tabs-collapse-lg.nav-tabs-info .nav-link.active,
    .nav-tabs-collapse-lg.nav-tabs-info .show>.nav-link {
        border-color: #e9ecf1 #5d3fff #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-lg.nav-tabs-info .nav-link.active:hover,
    .nav-tabs-collapse-lg.nav-tabs-info .show>.nav-link:hover {
        border-color: #e9ecf1 #5d3fff #e9ecf1 transparent;
    }
    .nav-tabs-collapse-lg.nav-tabs-warning .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #f7b731;
    }
    .nav-tabs-collapse-lg.nav-tabs-warning .nav-link.active,
    .nav-tabs-collapse-lg.nav-tabs-warning .show>.nav-link {
        border-color: #e9ecf1 #f7b731 #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-lg.nav-tabs-warning .nav-link.active:hover,
    .nav-tabs-collapse-lg.nav-tabs-warning .show>.nav-link:hover {
        border-color: #e9ecf1 #f7b731 #e9ecf1 transparent;
    }
    .nav-tabs-collapse-lg.nav-tabs-danger .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #fe3f3e;
    }
    .nav-tabs-collapse-lg.nav-tabs-danger .nav-link.active,
    .nav-tabs-collapse-lg.nav-tabs-danger .show>.nav-link {
        border-color: #e9ecf1 #fe3f3e #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-lg.nav-tabs-danger .nav-link.active:hover,
    .nav-tabs-collapse-lg.nav-tabs-danger .show>.nav-link:hover {
        border-color: #e9ecf1 #fe3f3e #e9ecf1 transparent;
    }
    .nav-tabs-collapse-lg.nav-tabs-light .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #f8f9fa;
    }
    .nav-tabs-collapse-lg.nav-tabs-light .nav-link.active,
    .nav-tabs-collapse-lg.nav-tabs-light .show>.nav-link {
        border-color: #e9ecf1 #f8f9fa #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-lg.nav-tabs-light .nav-link.active:hover,
    .nav-tabs-collapse-lg.nav-tabs-light .show>.nav-link:hover {
        border-color: #e9ecf1 #f8f9fa #e9ecf1 transparent;
    }
    .nav-tabs-collapse-lg.nav-tabs-dark .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #131b24;
    }
    .nav-tabs-collapse-lg.nav-tabs-dark .nav-link.active,
    .nav-tabs-collapse-lg.nav-tabs-dark .show>.nav-link {
        border-color: #e9ecf1 #131b24 #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-lg.nav-tabs-dark .nav-link.active:hover,
    .nav-tabs-collapse-lg.nav-tabs-dark .show>.nav-link:hover {
        border-color: #e9ecf1 #131b24 #e9ecf1 transparent;
    }
    .nav-tabs-collapse-lg.nav-tabs-black .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #000000;
    }
    .nav-tabs-collapse-lg.nav-tabs-black .nav-link.active,
    .nav-tabs-collapse-lg.nav-tabs-black .show>.nav-link {
        border-color: #e9ecf1 #000000 #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-lg.nav-tabs-black .nav-link.active:hover,
    .nav-tabs-collapse-lg.nav-tabs-black .show>.nav-link:hover {
        border-color: #e9ecf1 #000000 #e9ecf1 transparent;
    }
    .nav-tabs-collapse-lg.nav-tabs-white .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #ffffff;
    }
    .nav-tabs-collapse-lg.nav-tabs-white .nav-link.active,
    .nav-tabs-collapse-lg.nav-tabs-white .show>.nav-link {
        border-color: #e9ecf1 #ffffff #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-lg.nav-tabs-white .nav-link.active:hover,
    .nav-tabs-collapse-lg.nav-tabs-white .show>.nav-link:hover {
        border-color: #e9ecf1 #ffffff #e9ecf1 transparent;
    }
}

.nav-tabs-collapse-xl {
    flex-direction: column;
    border-bottom: none;
    border-left: 1px solid #e9ecf1;
}

.nav-tabs-collapse-xl .nav-item {
    margin-bottom: 0;
    margin-left: -1px;
}

.nav-tabs-collapse-xl .nav-link {
    border-top-width: 1px;
    border-right-width: 2px;
}

.nav-tabs-collapse-xl .nav-link:hover {
    border-bottom-color: transparent;
    border-left-color: #0684fe;
}

.nav-tabs-collapse-xl .nav-link.active,
.nav-tabs-collapse-xl .show>.nav-link {
    border-color: #e9ecf1 #0684fe #e9ecf1 #ffffff;
}

.nav-tabs-collapse-xl .nav-link.active:hover,
.nav-tabs-collapse-xl .show>.nav-link:hover {
    border-color: #e9ecf1 #0684fe #e9ecf1 transparent;
}

.nav-tabs-collapse-xl.nav-tabs-primary .nav-link:hover {
    border-bottom-color: transparent;
    border-left-color: #0684fe;
}

.nav-tabs-collapse-xl.nav-tabs-primary .nav-link.active,
.nav-tabs-collapse-xl.nav-tabs-primary .show>.nav-link {
    border-color: #e9ecf1 #0684fe #e9ecf1 #ffffff;
}

.nav-tabs-collapse-xl.nav-tabs-primary .nav-link.active:hover,
.nav-tabs-collapse-xl.nav-tabs-primary .show>.nav-link:hover {
    border-color: #e9ecf1 #0684fe #e9ecf1 transparent;
}

.nav-tabs-collapse-xl.nav-tabs-secondary .nav-link:hover {
    border-bottom-color: transparent;
    border-left-color: #f5f6f8;
}

.nav-tabs-collapse-xl.nav-tabs-secondary .nav-link.active,
.nav-tabs-collapse-xl.nav-tabs-secondary .show>.nav-link {
    border-color: #e9ecf1 #f5f6f8 #e9ecf1 #ffffff;
}

.nav-tabs-collapse-xl.nav-tabs-secondary .nav-link.active:hover,
.nav-tabs-collapse-xl.nav-tabs-secondary .show>.nav-link:hover {
    border-color: #e9ecf1 #f5f6f8 #e9ecf1 transparent;
}

.nav-tabs-collapse-xl.nav-tabs-success .nav-link:hover {
    border-bottom-color: transparent;
    border-left-color: #35ac80;
}

.nav-tabs-collapse-xl.nav-tabs-success .nav-link.active,
.nav-tabs-collapse-xl.nav-tabs-success .show>.nav-link {
    border-color: #e9ecf1 #35ac80 #e9ecf1 #ffffff;
}

.nav-tabs-collapse-xl.nav-tabs-success .nav-link.active:hover,
.nav-tabs-collapse-xl.nav-tabs-success .show>.nav-link:hover {
    border-color: #e9ecf1 #35ac80 #e9ecf1 transparent;
}

.nav-tabs-collapse-xl.nav-tabs-info .nav-link:hover {
    border-bottom-color: transparent;
    border-left-color: #5d3fff;
}

.nav-tabs-collapse-xl.nav-tabs-info .nav-link.active,
.nav-tabs-collapse-xl.nav-tabs-info .show>.nav-link {
    border-color: #e9ecf1 #5d3fff #e9ecf1 #ffffff;
}

.nav-tabs-collapse-xl.nav-tabs-info .nav-link.active:hover,
.nav-tabs-collapse-xl.nav-tabs-info .show>.nav-link:hover {
    border-color: #e9ecf1 #5d3fff #e9ecf1 transparent;
}

.nav-tabs-collapse-xl.nav-tabs-warning .nav-link:hover {
    border-bottom-color: transparent;
    border-left-color: #f7b731;
}

.nav-tabs-collapse-xl.nav-tabs-warning .nav-link.active,
.nav-tabs-collapse-xl.nav-tabs-warning .show>.nav-link {
    border-color: #e9ecf1 #f7b731 #e9ecf1 #ffffff;
}

.nav-tabs-collapse-xl.nav-tabs-warning .nav-link.active:hover,
.nav-tabs-collapse-xl.nav-tabs-warning .show>.nav-link:hover {
    border-color: #e9ecf1 #f7b731 #e9ecf1 transparent;
}

.nav-tabs-collapse-xl.nav-tabs-danger .nav-link:hover {
    border-bottom-color: transparent;
    border-left-color: #fe3f3e;
}

.nav-tabs-collapse-xl.nav-tabs-danger .nav-link.active,
.nav-tabs-collapse-xl.nav-tabs-danger .show>.nav-link {
    border-color: #e9ecf1 #fe3f3e #e9ecf1 #ffffff;
}

.nav-tabs-collapse-xl.nav-tabs-danger .nav-link.active:hover,
.nav-tabs-collapse-xl.nav-tabs-danger .show>.nav-link:hover {
    border-color: #e9ecf1 #fe3f3e #e9ecf1 transparent;
}

.nav-tabs-collapse-xl.nav-tabs-light .nav-link:hover {
    border-bottom-color: transparent;
    border-left-color: #f8f9fa;
}

.nav-tabs-collapse-xl.nav-tabs-light .nav-link.active,
.nav-tabs-collapse-xl.nav-tabs-light .show>.nav-link {
    border-color: #e9ecf1 #f8f9fa #e9ecf1 #ffffff;
}

.nav-tabs-collapse-xl.nav-tabs-light .nav-link.active:hover,
.nav-tabs-collapse-xl.nav-tabs-light .show>.nav-link:hover {
    border-color: #e9ecf1 #f8f9fa #e9ecf1 transparent;
}

.nav-tabs-collapse-xl.nav-tabs-dark .nav-link:hover {
    border-bottom-color: transparent;
    border-left-color: #131b24;
}

.nav-tabs-collapse-xl.nav-tabs-dark .nav-link.active,
.nav-tabs-collapse-xl.nav-tabs-dark .show>.nav-link {
    border-color: #e9ecf1 #131b24 #e9ecf1 #ffffff;
}

.nav-tabs-collapse-xl.nav-tabs-dark .nav-link.active:hover,
.nav-tabs-collapse-xl.nav-tabs-dark .show>.nav-link:hover {
    border-color: #e9ecf1 #131b24 #e9ecf1 transparent;
}

.nav-tabs-collapse-xl.nav-tabs-black .nav-link:hover {
    border-bottom-color: transparent;
    border-left-color: #000000;
}

.nav-tabs-collapse-xl.nav-tabs-black .nav-link.active,
.nav-tabs-collapse-xl.nav-tabs-black .show>.nav-link {
    border-color: #e9ecf1 #000000 #e9ecf1 #ffffff;
}

.nav-tabs-collapse-xl.nav-tabs-black .nav-link.active:hover,
.nav-tabs-collapse-xl.nav-tabs-black .show>.nav-link:hover {
    border-color: #e9ecf1 #000000 #e9ecf1 transparent;
}

.nav-tabs-collapse-xl.nav-tabs-white .nav-link:hover {
    border-bottom-color: transparent;
    border-left-color: #ffffff;
}

.nav-tabs-collapse-xl.nav-tabs-white .nav-link.active,
.nav-tabs-collapse-xl.nav-tabs-white .show>.nav-link {
    border-color: #e9ecf1 #ffffff #e9ecf1 #ffffff;
}

.nav-tabs-collapse-xl.nav-tabs-white .nav-link.active:hover,
.nav-tabs-collapse-xl.nav-tabs-white .show>.nav-link:hover {
    border-color: #e9ecf1 #ffffff #e9ecf1 transparent;
}

@media (max-width: 575px) {
    .nav-tabs-collapse-xs {
        flex-direction: column;
        border-bottom: none;
        border-left: 1px solid #e9ecf1;
    }
    .nav-tabs-collapse-xs .nav-item {
        margin-bottom: 0;
        margin-left: -1px;
    }
    .nav-tabs-collapse-xs .nav-link {
        border-top-width: 1px;
        border-right-width: 2px;
    }
    .nav-tabs-collapse-xs .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #0684fe;
    }
    .nav-tabs-collapse-xs .nav-link.active,
    .nav-tabs-collapse-xs .show>.nav-link {
        border-color: #e9ecf1 #0684fe #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-xs .nav-link.active:hover,
    .nav-tabs-collapse-xs .show>.nav-link:hover {
        border-color: #e9ecf1 #0684fe #e9ecf1 transparent;
    }
    .nav-tabs-collapse-xs.nav-tabs-primary .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #0684fe;
    }
    .nav-tabs-collapse-xs.nav-tabs-primary .nav-link.active,
    .nav-tabs-collapse-xs.nav-tabs-primary .show>.nav-link {
        border-color: #e9ecf1 #0684fe #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-xs.nav-tabs-primary .nav-link.active:hover,
    .nav-tabs-collapse-xs.nav-tabs-primary .show>.nav-link:hover {
        border-color: #e9ecf1 #0684fe #e9ecf1 transparent;
    }
    .nav-tabs-collapse-xs.nav-tabs-secondary .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #f5f6f8;
    }
    .nav-tabs-collapse-xs.nav-tabs-secondary .nav-link.active,
    .nav-tabs-collapse-xs.nav-tabs-secondary .show>.nav-link {
        border-color: #e9ecf1 #f5f6f8 #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-xs.nav-tabs-secondary .nav-link.active:hover,
    .nav-tabs-collapse-xs.nav-tabs-secondary .show>.nav-link:hover {
        border-color: #e9ecf1 #f5f6f8 #e9ecf1 transparent;
    }
    .nav-tabs-collapse-xs.nav-tabs-success .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #35ac80;
    }
    .nav-tabs-collapse-xs.nav-tabs-success .nav-link.active,
    .nav-tabs-collapse-xs.nav-tabs-success .show>.nav-link {
        border-color: #e9ecf1 #35ac80 #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-xs.nav-tabs-success .nav-link.active:hover,
    .nav-tabs-collapse-xs.nav-tabs-success .show>.nav-link:hover {
        border-color: #e9ecf1 #35ac80 #e9ecf1 transparent;
    }
    .nav-tabs-collapse-xs.nav-tabs-info .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #5d3fff;
    }
    .nav-tabs-collapse-xs.nav-tabs-info .nav-link.active,
    .nav-tabs-collapse-xs.nav-tabs-info .show>.nav-link {
        border-color: #e9ecf1 #5d3fff #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-xs.nav-tabs-info .nav-link.active:hover,
    .nav-tabs-collapse-xs.nav-tabs-info .show>.nav-link:hover {
        border-color: #e9ecf1 #5d3fff #e9ecf1 transparent;
    }
    .nav-tabs-collapse-xs.nav-tabs-warning .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #f7b731;
    }
    .nav-tabs-collapse-xs.nav-tabs-warning .nav-link.active,
    .nav-tabs-collapse-xs.nav-tabs-warning .show>.nav-link {
        border-color: #e9ecf1 #f7b731 #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-xs.nav-tabs-warning .nav-link.active:hover,
    .nav-tabs-collapse-xs.nav-tabs-warning .show>.nav-link:hover {
        border-color: #e9ecf1 #f7b731 #e9ecf1 transparent;
    }
    .nav-tabs-collapse-xs.nav-tabs-danger .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #fe3f3e;
    }
    .nav-tabs-collapse-xs.nav-tabs-danger .nav-link.active,
    .nav-tabs-collapse-xs.nav-tabs-danger .show>.nav-link {
        border-color: #e9ecf1 #fe3f3e #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-xs.nav-tabs-danger .nav-link.active:hover,
    .nav-tabs-collapse-xs.nav-tabs-danger .show>.nav-link:hover {
        border-color: #e9ecf1 #fe3f3e #e9ecf1 transparent;
    }
    .nav-tabs-collapse-xs.nav-tabs-light .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #f8f9fa;
    }
    .nav-tabs-collapse-xs.nav-tabs-light .nav-link.active,
    .nav-tabs-collapse-xs.nav-tabs-light .show>.nav-link {
        border-color: #e9ecf1 #f8f9fa #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-xs.nav-tabs-light .nav-link.active:hover,
    .nav-tabs-collapse-xs.nav-tabs-light .show>.nav-link:hover {
        border-color: #e9ecf1 #f8f9fa #e9ecf1 transparent;
    }
    .nav-tabs-collapse-xs.nav-tabs-dark .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #131b24;
    }
    .nav-tabs-collapse-xs.nav-tabs-dark .nav-link.active,
    .nav-tabs-collapse-xs.nav-tabs-dark .show>.nav-link {
        border-color: #e9ecf1 #131b24 #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-xs.nav-tabs-dark .nav-link.active:hover,
    .nav-tabs-collapse-xs.nav-tabs-dark .show>.nav-link:hover {
        border-color: #e9ecf1 #131b24 #e9ecf1 transparent;
    }
    .nav-tabs-collapse-xs.nav-tabs-black .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #000000;
    }
    .nav-tabs-collapse-xs.nav-tabs-black .nav-link.active,
    .nav-tabs-collapse-xs.nav-tabs-black .show>.nav-link {
        border-color: #e9ecf1 #000000 #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-xs.nav-tabs-black .nav-link.active:hover,
    .nav-tabs-collapse-xs.nav-tabs-black .show>.nav-link:hover {
        border-color: #e9ecf1 #000000 #e9ecf1 transparent;
    }
    .nav-tabs-collapse-xs.nav-tabs-white .nav-link:hover {
        border-bottom-color: transparent;
        border-left-color: #ffffff;
    }
    .nav-tabs-collapse-xs.nav-tabs-white .nav-link.active,
    .nav-tabs-collapse-xs.nav-tabs-white .show>.nav-link {
        border-color: #e9ecf1 #ffffff #e9ecf1 #ffffff;
    }
    .nav-tabs-collapse-xs.nav-tabs-white .nav-link.active:hover,
    .nav-tabs-collapse-xs.nav-tabs-white .show>.nav-link:hover {
        border-color: #e9ecf1 #ffffff #e9ecf1 transparent;
    }
}

.nav-tabs-primary .nav-link:hover {
    border-color: transparent;
    border-bottom-color: #0684fe;
}

.nav-tabs-primary .nav-link.active,
.nav-tabs-primary .show>.nav-link {
    border-top-color: #0684fe;
}

.nav-tabs-primary .nav-link.active:hover,
.nav-tabs-primary .show>.nav-link:hover {
    border-color: #0684fe #e9ecf1 transparent;
}

.nav-tabs-secondary .nav-link:hover {
    border-color: transparent;
    border-bottom-color: #f5f6f8;
}

.nav-tabs-secondary .nav-link.active,
.nav-tabs-secondary .show>.nav-link {
    border-top-color: #f5f6f8;
}

.nav-tabs-secondary .nav-link.active:hover,
.nav-tabs-secondary .show>.nav-link:hover {
    border-color: #f5f6f8 #e9ecf1 transparent;
}

.nav-tabs-success .nav-link:hover {
    border-color: transparent;
    border-bottom-color: #35ac80;
}

.nav-tabs-success .nav-link.active,
.nav-tabs-success .show>.nav-link {
    border-top-color: #35ac80;
}

.nav-tabs-success .nav-link.active:hover,
.nav-tabs-success .show>.nav-link:hover {
    border-color: #35ac80 #e9ecf1 transparent;
}

.nav-tabs-info .nav-link:hover {
    border-color: transparent;
    border-bottom-color: #5d3fff;
}

.nav-tabs-info .nav-link.active,
.nav-tabs-info .show>.nav-link {
    border-top-color: #5d3fff;
}

.nav-tabs-info .nav-link.active:hover,
.nav-tabs-info .show>.nav-link:hover {
    border-color: #5d3fff #e9ecf1 transparent;
}

.nav-tabs-warning .nav-link:hover {
    border-color: transparent;
    border-bottom-color: #f7b731;
}

.nav-tabs-warning .nav-link.active,
.nav-tabs-warning .show>.nav-link {
    border-top-color: #f7b731;
}

.nav-tabs-warning .nav-link.active:hover,
.nav-tabs-warning .show>.nav-link:hover {
    border-color: #f7b731 #e9ecf1 transparent;
}

.nav-tabs-danger .nav-link:hover {
    border-color: transparent;
    border-bottom-color: #fe3f3e;
}

.nav-tabs-danger .nav-link.active,
.nav-tabs-danger .show>.nav-link {
    border-top-color: #fe3f3e;
}

.nav-tabs-danger .nav-link.active:hover,
.nav-tabs-danger .show>.nav-link:hover {
    border-color: #fe3f3e #e9ecf1 transparent;
}

.nav-tabs-light .nav-link:hover {
    border-color: transparent;
    border-bottom-color: #f8f9fa;
}

.nav-tabs-light .nav-link.active,
.nav-tabs-light .show>.nav-link {
    border-top-color: #f8f9fa;
}

.nav-tabs-light .nav-link.active:hover,
.nav-tabs-light .show>.nav-link:hover {
    border-color: #f8f9fa #e9ecf1 transparent;
}

.nav-tabs-dark .nav-link:hover {
    border-color: transparent;
    border-bottom-color: #131b24;
}

.nav-tabs-dark .nav-link.active,
.nav-tabs-dark .show>.nav-link {
    border-top-color: #131b24;
}

.nav-tabs-dark .nav-link.active:hover,
.nav-tabs-dark .show>.nav-link:hover {
    border-color: #131b24 #e9ecf1 transparent;
}

.nav-tabs-black .nav-link:hover {
    border-color: transparent;
    border-bottom-color: #000000;
}

.nav-tabs-black .nav-link.active,
.nav-tabs-black .show>.nav-link {
    border-top-color: #000000;
}

.nav-tabs-black .nav-link.active:hover,
.nav-tabs-black .show>.nav-link:hover {
    border-color: #000000 #e9ecf1 transparent;
}

.nav-tabs-white .nav-link:hover {
    border-color: transparent;
    border-bottom-color: #ffffff;
}

.nav-tabs-white .nav-link.active,
.nav-tabs-white .show>.nav-link {
    border-top-color: #ffffff;
}

.nav-tabs-white .nav-link.active:hover,
.nav-tabs-white .show>.nav-link:hover {
    border-color: #ffffff #e9ecf1 transparent;
}

.nav-pills-card .nav-link {
    background-color: #ffffff;
}

@media (min-width: 768px) {
    .nav-pills-card .nav-link {
        min-width: 125px;
        text-align: center;
        margin-bottom: 15px;
    }
}

@media (min-width: 992px) {
    .nav-pills-card .nav-link {
        min-width: 140px;
        text-align: center;
    }
}

.nav-pills-card .nav-link.active,
.nav-pills-card .show>.nav-link {
    background-color: #ffffff;
    color: inherit;
}

.nav-pills-with-icon .nav-link.active .nav-pill-icon,
.nav-pills-with-icon .show>.nav-link .nav-pill-icon {
    color: #0684fe;
}

.nav-pills-with-icon.nav-pills-primary .nav-link.active .nav-pill-icon,
.nav-pills-with-icon.nav-pills-primary .show>.nav-link .nav-pill-icon {
    color: #0684fe;
}

.nav-pills-with-icon.nav-pills-secondary .nav-link.active .nav-pill-icon,
.nav-pills-with-icon.nav-pills-secondary .show>.nav-link .nav-pill-icon {
    color: #f5f6f8;
}

.nav-pills-with-icon.nav-pills-success .nav-link.active .nav-pill-icon,
.nav-pills-with-icon.nav-pills-success .show>.nav-link .nav-pill-icon {
    color: #35ac80;
}

.nav-pills-with-icon.nav-pills-info .nav-link.active .nav-pill-icon,
.nav-pills-with-icon.nav-pills-info .show>.nav-link .nav-pill-icon {
    color: #5d3fff;
}

.nav-pills-with-icon.nav-pills-warning .nav-link.active .nav-pill-icon,
.nav-pills-with-icon.nav-pills-warning .show>.nav-link .nav-pill-icon {
    color: #f7b731;
}

.nav-pills-with-icon.nav-pills-danger .nav-link.active .nav-pill-icon,
.nav-pills-with-icon.nav-pills-danger .show>.nav-link .nav-pill-icon {
    color: #fe3f3e;
}

.nav-pills-with-icon.nav-pills-light .nav-link.active .nav-pill-icon,
.nav-pills-with-icon.nav-pills-light .show>.nav-link .nav-pill-icon {
    color: #f8f9fa;
}

.nav-pills-with-icon.nav-pills-dark .nav-link.active .nav-pill-icon,
.nav-pills-with-icon.nav-pills-dark .show>.nav-link .nav-pill-icon {
    color: #131b24;
}

.nav-pills-with-icon.nav-pills-black .nav-link.active .nav-pill-icon,
.nav-pills-with-icon.nav-pills-black .show>.nav-link .nav-pill-icon {
    color: #000000;
}

.nav-pills-with-icon.nav-pills-white .nav-link.active .nav-pill-icon,
.nav-pills-with-icon.nav-pills-white .show>.nav-link .nav-pill-icon {
    color: #ffffff;
}

.nav-pills-stripped .nav-link {
    background-color: transparent;
    padding: 12px 0;
}

.nav-pills-stripped .nav-link.active,
.nav-pills-stripped .show>.nav-link {
    background-color: transparent;
    color: inherit;
}

.landing-sass-header-content {
    border-bottom-left-radius: 400px;
    padding-bottom: 260px;
    margin-bottom: -260px;
}

.landing-sass-header-img-wrapper {
    margin-bottom: 85px;
}

.lead-landing-section {
    padding-top: 105px;
    padding-bottom: 110px;
}

.landing-sass-testimonial-carousel .carousel-control-next,
.landing-sass-testimonial-carousel .carousel-control-prev {
    position: static;
    width: auto;
}

.landing-sass-testimonial-carousel .carousel-control-prev {
    margin-right: 16px;
}

.landing-business-media-list .list-item-number {
    display: inline-flex;
    width: 34px;
    height: 34px;
    align-items: center;
    justify-content: center;
    background-color: #5d3fff;
    border-radius: 0.25rem;
    color: #ffffff;
    font-size: 12px;
    font-weight: 700;
    line-height: 1.33;
}

.media-icon {
    display: inline-flex;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #e9ecf1;
    color: #5d3fff;
    font-size: 16px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.media-icon img {
    width: 16px;
    height: 16px;
}

.landing-app-header {
    background-color: #1565d8;
    /*
    background-image: url("../../demo/assets/images/app-landing-page/bg.svg");
    */
    background-repeat: no-repeat;
    background-position: 75% 50%;
}

.landing-app-header-content {
    position: relative;
    overflow: hidden;
    padding-top: 110px;
}

.landing-app-header-content::after {
    background: #ffffff;
    bottom: 0;
    content: '';
    display: block;
    height: 50%;
    left: 0;
    position: absolute;
    right: 0;
    transform: skewY(-5.7deg) translateY(100%);
    transform-origin: left top;
}

.lead-search-form-collapsible {
    display: inline-block;
    position: relative;
    height: 48px;
    width: 48px;
}

.lead-search-form-collapsible .collapsible-form-control {
    position: absolute;
    right: 0;
    top: 0;
    border: 0;
    padding-right: 48px;
    min-height: 48px;
    width: 48px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23000000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-zoom-out'%3E%3Ccircle cx='11' cy='11' r='8'%3E%3C/circle%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'%3E%3C/line%3E%3Cline x1='8' y1='11' x2='14' y2='11'%3E%3C/line%3E%3C/svg%3E");
    background-position: right 15px center;
    background-repeat: no-repeat;
    background-size: 16px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    border-radius: 24px;
}

.lead-search-form-collapsible .collapsible-form-control:focus {
    background-color: #f3f3f3;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23747474' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-zoom-out'%3E%3Ccircle cx='11' cy='11' r='8'%3E%3C/circle%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'%3E%3C/line%3E%3Cline x1='8' y1='11' x2='14' y2='11'%3E%3C/line%3E%3C/svg%3E");
    outline: none;
    border-radius: 24px;
    width: 150px;
    padding: 10px 40px 10px 15px;
    cursor: text;
}

.rounded-xl {
    border-radius: 0.5rem !important;
}

.text-underline {
    text-decoration: underline;
}

.feature-icon {
    display: inline-flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
}

.feature-icon-lg {
    width: 60px;
    height: 60px;
}

.border-top {
    border-top: 1px solid #e9ecf1;
}

.border-right {
    border-right: 1px solid #e9ecf1;
}

.border-bottom {
    border-bottom: 1px solid #e9ecf1;
}

.border-left {
    border-left: 1px solid #e9ecf1;
}

.border-top-0 {
    border-top: 0 !important;
}

.border-right-0 {
    border-right: 0 !important;
}

.border-bottom-0 {
    border-bottom: 0 !important;
}

.border-left-0 {
    border-left: 0 !important;
}

.border-x {
    border-left: 1px solid #e9ecf1;
    border-right: 1px solid #e9ecf1;
}

.border-y {
    border-top: 1px solid #e9ecf1;
    border-bottom: 1px solid #e9ecf1;
}

@media (min-width: 576px) {
    .border-sm-top {
        border-top: 1px solid #e9ecf1;
    }
    .border-sm-right {
        border-right: 1px solid #e9ecf1;
    }
    .border-sm-bottom {
        border-bottom: 1px solid #e9ecf1;
    }
    .border-sm-left {
        border-left: 1px solid #e9ecf1;
    }
    .border-sm-top-0 {
        border-top: 0 !important;
    }
    .border-sm-right-0 {
        border-right: 0 !important;
    }
    .border-sm-bottom-0 {
        border-bottom: 0 !important;
    }
    .border-sm-left-0 {
        border-left: 0 !important;
    }
    .border-sm-x {
        border-left: 1px solid #e9ecf1;
        border-right: 1px solid #e9ecf1;
    }
    .border-sm-y {
        border-top: 1px solid #e9ecf1;
        border-bottom: 1px solid #e9ecf1;
    }
}

@media (min-width: 768px) {
    .border-md-top {
        border-top: 1px solid #e9ecf1;
    }
    .border-md-right {
        border-right: 1px solid #e9ecf1;
    }
    .border-md-bottom {
        border-bottom: 1px solid #e9ecf1;
    }
    .border-md-left {
        border-left: 1px solid #e9ecf1;
    }
    .border-md-top-0 {
        border-top: 0 !important;
    }
    .border-md-right-0 {
        border-right: 0 !important;
    }
    .border-md-bottom-0 {
        border-bottom: 0 !important;
    }
    .border-md-left-0 {
        border-left: 0 !important;
    }
    .border-md-x {
        border-left: 1px solid #e9ecf1;
        border-right: 1px solid #e9ecf1;
    }
    .border-md-y {
        border-top: 1px solid #e9ecf1;
        border-bottom: 1px solid #e9ecf1;
    }
}

@media (min-width: 992px) {
    .border-lg-top {
        border-top: 1px solid #e9ecf1;
    }
    .border-lg-right {
        border-right: 1px solid #e9ecf1;
    }
    .border-lg-bottom {
        border-bottom: 1px solid #e9ecf1;
    }
    .border-lg-left {
        border-left: 1px solid #e9ecf1;
    }
    .border-lg-top-0 {
        border-top: 0 !important;
    }
    .border-lg-right-0 {
        border-right: 0 !important;
    }
    .border-lg-bottom-0 {
        border-bottom: 0 !important;
    }
    .border-lg-left-0 {
        border-left: 0 !important;
    }
    .border-lg-x {
        border-left: 1px solid #e9ecf1;
        border-right: 1px solid #e9ecf1;
    }
    .border-lg-y {
        border-top: 1px solid #e9ecf1;
        border-bottom: 1px solid #e9ecf1;
    }
}

@media (min-width: 1200px) {
    .border-xl-top {
        border-top: 1px solid #e9ecf1;
    }
    .border-xl-right {
        border-right: 1px solid #e9ecf1;
    }
    .border-xl-bottom {
        border-bottom: 1px solid #e9ecf1;
    }
    .border-xl-left {
        border-left: 1px solid #e9ecf1;
    }
    .border-xl-top-0 {
        border-top: 0 !important;
    }
    .border-xl-right-0 {
        border-right: 0 !important;
    }
    .border-xl-bottom-0 {
        border-bottom: 0 !important;
    }
    .border-xl-left-0 {
        border-left: 0 !important;
    }
    .border-xl-x {
        border-left: 1px solid #e9ecf1;
        border-right: 1px solid #e9ecf1;
    }
    .border-xl-y {
        border-top: 1px solid #e9ecf1;
        border-bottom: 1px solid #e9ecf1;
    }
}

.card-title-number {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #0684fe;
    line-height: 1;
    font-size: 1rem;
    color: #ffffff;
}

.gallery-h-img {
    height: 223px;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.gallery-v-img {
    width: 100%;
}

.gallery-square-img-wrapper {
    display: block;
    width: 100%;
    padding-top: 100%;
    position: relative;
    overflow: hidden;
}

.gallery-square-img-wrapper>img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    -o-object-fit: cover;
    object-fit: cover;
}

.bg-gradient {
    background-image: linear-gradient(to top, #ffffff, #f8fcff);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.collpase-toggle-plus {
    display: inline-block;
    padding-right: 18px;
    background-size: 18px;
    background-repeat: no-repeat;
    background-position: right center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-plus-square'%3E%3Crect x='3' y='3' width='18' height='18' rx='2' ry='2'%3E%3C/rect%3E%3Cline x1='12' y1='8' x2='12' y2='16'%3E%3C/line%3E%3Cline x1='8' y1='12' x2='16' y2='12'%3E%3C/line%3E%3C/svg%3E");
}

.collpase-toggle-plus[aria-expanded="true"] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-minus-square'%3E%3Crect x='3' y='3' width='18' height='18' rx='2' ry='2'%3E%3C/rect%3E%3Cline x1='8' y1='12' x2='16' y2='12'%3E%3C/line%3E%3C/svg%3E");
}

.collpase-toggle-arrow {
    display: inline-block;
    padding-right: 18px;
    position: relative;
}

.collpase-toggle-arrow::after {
    content: "";
    position: absolute;
    display: inline-block;
    right: 0;
    top: 0;
    bottom: 0;
    width: 18px;
    background-size: 18px;
    background-repeat: no-repeat;
    background-position: right center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
    transition: all 0.3s ease-in-out;
}

.collpase-toggle-arrow[aria-expanded="true"]::after {
    transform: rotate(90deg);
}